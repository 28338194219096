export const template_default = `
<div class="_tr-widget">
  <div class="_tr-grid-template">
    <div class="_tr-container">
      <div class="_tr-heading">
        <h2><%= title %></h2>
      </div>
      <div class="_tr-grid-item">
        <% for (let i=0; i < items.length; i++) { %>
        <div class="_tr-item-widget _tr-item-event">
          <div class="_tr-box-img">
            <a href="<%= items[i]['url'] %>" title="<%= items[i]['title'] %>"
              ><img
                src="<%= items[i]['image'] %>"
                title="<%= items[i]['title'] %>"
            /></a>
          </div>
          <div class="_tr-box-caption">
            <h3>
              <a href="<%= items[i]['url'] %>"><%= items[i]['title'] %></a>
            </h3>
            <a href="<%= items[i]['categories']['url'] %>" class="_tr-entry-tag"
              ><%= items[i]['categories']['name'] %></a
            >
          </div>
        </div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;

export const template_three_grid = `
<div class="_tr-widget">
  <div class="_tr-grid-template">
    <div class="_tr-container">
      <div class="_tr-heading">
        <h2><%= title %></h2>
      </div>
      <div class="_tr-grid-item _tr-three-grid">
        <% for (let i=0; i < items.length; i++) { %>
        <div class="_tr-item-widget _tr-item-event">
          <div class="_tr-box-img">
            <a href="<%= items[i]['url'] %>" title="<%= items[i]['title'] %>"
              ><img
                src="<%= items[i]['image'] %>"
                title="<%= items[i]['title'] %>"
            /></a>
          </div>
          <div class="_tr-box-caption">
            <h3>
              <a href="<%= items[i]['url'] %>"><%= items[i]['title'] %></a>
            </h3>
            <a href="<%= items[i]['categories']['url'] %>" class="_tr-entry-tag"
              ><%= items[i]['categories']['name'] %></a
            >
          </div>
        </div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;

export const template_most_viewed = `
<div class="_tr-widget">
  <div class="_tr-grid-template">
    <div class="_tr-container">
      <div class="_tr-heading">
        <h2><%= title %></h2>
      </div>
      <div class="_tr-grid-item">
        <% for (let i=0; i < items.length; i++) { %>
        <div class="_tr-item-widget _tr-item-event">
          <div class="_tr-box-img">
            <a href="<%= items[i]['url'] %>" title="<%= items[i]['title'] %>"
              ><img
                src="<%= items[i]['image'] %>"
                title="<%= items[i]['title'] %>"
            /></a>
            <span class="_tr-label-mostview">Most View</span>
          </div>
          <div class="_tr-box-caption">
            <h3>
              <a href="<%= items[i]['url'] %>"><%= items[i]['title'] %></a>
            </h3>
            <a href="<%= items[i]['tag_url'] %>" class="_tr-entry-tag"
              ><%= items[i]['tags'][0] %></a
            >
          </div>
        </div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;

export const template_two_grid = `
<div class="_tr-widget">
  <div class="_tr-grid-template">
    <div class="_tr-container">
      <div class="_tr-heading">
        <h2><%= title %></h2>
      </div>
      <div class="_tr-grid-item _tr-two-grid">
        <% for (let i=0; i < items.length; i++) { %>
        <div class="_tr-item-widget _tr-item-event">
          <div class="_tr-box-img">
            <a href="<%= items[i]['url'] %>" title="<%= items[i]['title'] %>"
              ><img
                src="<%= items[i]['image'] %>"
                title="<%= items[i]['title'] %>"
            /></a>
          </div>
          <div class="_tr-box-caption">
            <h3>
              <a href="<%= items[i]['url'] %>"><%= items[i]['title'] %></a>
            </h3>
            <a href="<%= items[i]['tag_url'] %>" class="_tr-entry-tag"
              ><%= items[i]['tags'][0] %></a
            >
          </div>
        </div>
        <% } %>
      </div>
    </div>
  </div>
</div>
`;

export const template_breaking_news = `
<div class="_tr-widget">
  <div class="_tr-breaking-news">
    <h2><%= title %></h2>
    <div class="_tr-box-breaking-news">
      <% for (let i=0; i < items.length; i++) { %>
      <div class="_tr-item-breaking-news _tr-item-event">
        <div class="_tr-box-caption">
          <h3><a href="<%= items[i]['url'] %>"><%= items[i]['title'] %></a></h3>
          <p class="_tr-entry-category">
            <%= items[i]['categories']['name'] %>
          </p>
          <p class="_tr-entry-date"><%= items[i]['date'] %></p>
        </div>
        <div class="_tr-box-img">
          <a href="<%= items[i]['url'] %>"
            ><img src="<%= items[i]['image'] %>"
          /></a>
        </div>
      </div>
      <% } %>
    </div>
  </div>
</div>
`;

export const style_default = `
/* Grid Template */
._tr-widget ._tr-item-widget {
    border-radius: 3px;
    border: 1px solid #F0F0F0;
}
._tr-widget ._tr-item-widget h3 a {
    -webkit-line-clamp: 2;
    text-decoration: none;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    overflow: hidden;
}
._tr-widget ._tr-item-widget ._tr-box-caption {
    padding: 15px;
}
._tr-widget ._tr-item-widget ._tr-entry-tag {
    display: inline-flex;
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
    text-decoration: none;
    color: #FFF;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #00B400;
}
._tr-widget ._tr-item-widget ._tr-label-mostview {
    position: absolute;
    bottom: 10px;
    left: 15px;
    display: inline-flex;
    font-size: 12px;
    line-height: 1;
    margin-top: 10px;
    text-decoration: none;
    color: #FFF;
    padding: 6px 10px;
    border-radius: 18px;
    background: rgb(0,180,0);
    background: -moz-linear-gradient(275deg, rgba(0,180,0,1) 0%, rgba(0,136,0,1) 50%, rgba(0,90,0,1) 100%);
    background: -webkit-linear-gradient(275deg, rgba(0,180,0,1) 0%, rgba(0,136,0,1) 50%, rgba(0,90,0,1) 100%);
    background: linear-gradient(275deg, rgba(0,180,0,1) 0%, rgba(0,136,0,1) 50%, rgba(0,90,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00b400",endColorstr="#005a00",GradientType=1);
}

._tr-widget ._tr-item-widget ._tr-box-img a{
    display: grid;
    position: relative;
    padding-top: 56.25%;
}
._tr-widget ._tr-item-widget ._tr-box-img a img{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    object-fit: cover;
}
`;

export const style_breaking_news = `
/* Breaking */
._tr-widget ._tr-breaking-news {
    max-width: 340px;
    margin: 20px auto;
    padding: 0 15px;
}
._tr-widget ._tr-breaking-news h2,
._tr-widget ._tr-breaking-news h3,
._tr-widget ._tr-breaking-news p {
    margin: 0;
    padding: 0;
}
._tr-widget ._tr-breaking-news h2 {
    font-family: <%= cloudsemibold %>;
    color: rgb(255, 255, 255);
    font-size: 25px;
    margin: 0px 0px 5px;
    padding: 8px 10px 8px;
    border-radius: 3px;
    background-color: rgb(0, 180, 0);
}
._tr-widget ._tr-box-breaking-news {
    padding: 15px;
    border-radius: 3px;
    background-color: #000;
}
._tr-widget ._tr-item-breaking-news {
    display: grid;
    column-gap: 15px;
    margin-bottom: 20px;
    grid-template-columns: 1fr 80px;
}
._tr-widget ._tr-item-breaking-news:last-of-type {
    margin-bottom: 0;
}
._tr-widget ._tr-item-breaking-news ._tr-box-img {
    position: relative;
    height: 80px;
    overflow: hidden;
}
._tr-widget ._tr-item-breaking-news ._tr-box-img img {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    height: 100%;
    object-fit: cover;
    transform: translate(-50%, -50%);
}
._tr-widget ._tr-item-breaking-news ._tr-box-caption {
    display: grid;
    column-gap: 15px;
    align-content: space-between;
    grid-template-columns: 1fr 1f
}
._tr-widget ._tr-item-breaking-news ._tr-box-caption h3 {
    grid-column: span 2 / auto;
    color: #FFF;
}
._tr-widget ._tr-item-breaking-news ._tr-box-caption h3 a {
    color: #FFF;
    font-family: <%= cloudregular %>;
    font-weight: normal;
    font-size: 16px;
    max-height: 40px;
    line-height: 21px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    display: -webkit-box !important;
}
._tr-widget ._tr-item-breaking-news._tr-box-caption h3 a:hover {
    color: rgb(0, 180, 38);
}
._tr-widget ._tr-item-breaking-news ._tr-entry-category {
    font-family: <%= cloudsemibold %>;
    font-size: 14px;
    color: rgb(0, 180, 38);
}
._tr-widget ._tr-item-breaking-news ._tr-entry-date {
    font-family: <%= cloudregular %>;
    font-size: 12px;
    color: rgb(98, 98, 98);
    text-align: right;
}

._tr-widget ._tr-item-widget ._tr-box-img a{
    display: grid;
    position: relative;
    padding-top: 56.25%;
}
._tr-widget ._tr-item-widget ._tr-box-img a img{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 101%;
    height: 101%;
    object-fit: cover;
}

@media (max-width: 767.98px) { 

  /* Breaking */
  ._tr-widget ._tr-breaking-news {
      width: 100%;
      max-width: 100%;
  }
  ._tr-widget ._tr-breaking-news h2 {
      font-size: 30px;
      text-align: center;
      padding: 8px 10px;
      margin: 0 -15px 10px;
      border-radius: 0;
  }
}
`;
