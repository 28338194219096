import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Input, Form, Row, Col, Divider, Select, Alert } from "antd";
// import { roleType } from '@Types/role_type'
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";
import { rules } from "../../helper/role_permission";
import Swal from 'sweetalert2'

const { Option } = Select;

const Axios = axios.create({
  withCredentials: true,
});

const Cache = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const [ widgetId , setWidgetId] = useState([])
  const [ widget_found , setWidgetFound] = useState(false)



  const [url , setStateUrl] = useState("")
 
  const layout = {
    labelCol: { span: 6 },
    // labelAlign:'left'
  };

  useEffect(() => {
    if (!checkPermission(userRole, "user_edit")) {
      window.location.href = "/user";
    }
   
  }, []);

  const setUrl = (value) => {
    setStateUrl(value);
  };

  const searchWidgetInUrl = async() => {
    const { data : {data} } = await Axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tools/searchWidgetByUrl`, {
        url: url,
    });

    setWidgetId(data.data)
    setWidgetFound(data.is_found)
  }

  const clearCacheById = async () => {
    
    const { data : data} = await Axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tools/clearCacheById`, {
        id: widgetId,
    });

    if(data.data){
      Swal.fire(
        'Deleted Cache !',
        'ทำการลบแคชเรียบร้อยแล้ว!',
        'success'
      )
    }

  }

  const checkRadisIsClear = async () => {
    await Axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tools/checkRadisIsClear`);
  }

  const redisClear = async () => {
    await Axios.post(`${process.env.REACT_APP_API_URL}/v1.0/tools/redisClear`);
  }

  return (
    <Fragment>
      <Form>
        <Row justify="space-between">
          <Col>
            {<h2>Clear Cache</h2> }
          </Col>
          <Col>
            <Form.Item>
              {/* <Button type="primary" htmlType="submit">
                Save
              </Button> */}
            </Form.Item>
          </Col>
        </Row>

        {/* Row3 */}
        <Row gutter={8}>
          <Col span={8}>
            <Input
              name="id"
              placeholder="กรอก url ข่าวที่ต้องการ clear cache"
              value={url || ""}
              onChange={(ev) => {
                setUrl(ev.target.value)
              }}
            />
          </Col>
          <Col span={8}>
            <Button onClick={() => searchWidgetInUrl()} type="primary">
                Search Widget
            </Button>
          </Col>
        </Row>

        <br/>

        <Row gutter={8}>
          <Col span={8}>
            {<h3>Widget ที่เจอ</h3> }
           </Col>
        </Row>

         <br/>

        <Row gutter={8}>
          <Col span={8}>
            {
            widget_found 
            ?
            widgetId.length > 0 && widgetId.map((items) => (
                <>
                <br/>
                <a href={`/widgets/${items}`}>tr-{items}</a>
                </>
            ))
            :
                <text>Not Found</text>
            }
            
          
           </Col>
        </Row>

        <br/>
        {
            widget_found 
            ?
            <Row gutter={8}>
                <Col span={4}>
                  <Button onClick={() => clearCacheById()}  type="danger">
                    Clear Cache
                  </Button>
                </Col>
                {/* <Col span={4}>
                  <Button onClick={() => checkRadisIsClear()}  type="primary">
                    Check Redis
                  </Button>
                </Col>
                <Col span={4}>
                  <Button onClick={() => redisClear()}  type="success">
                    Redis Clear
                  </Button>
                </Col> */}
        </Row> 
        
            :
            <></>
        }
        

        
      </Form>
    </Fragment>
  );
};
export default Cache;
