import { TinyArea } from "@ant-design/charts";

const MiniChart = ({ data }) => {
  const _data = (data.data || []).map((item) => {
    return +(item.ctr_widget || 0);
  });
  const config = {
    autoFit: true,
    data: _data,
    smooth: true,
  };

  return <TinyArea {...config} />;
};

export default MiniChart;
