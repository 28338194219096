import { Table } from "antd";
import { useState } from "react";

const WebhookLogDetail = ({ result, isError }) => {
  const [minimal, setMinimal] = useState(false);

  if (isError) {
    return <>{result[0].message}</>;
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
  ];

  const total_insert = result.filter(
    (item) => item.result === "created"
  ).length;
  const total_update = result.filter(
    (item) => item.result === "updated"
  ).length;

  return (
    <>
      <a onClick={() => setMinimal(!minimal)}>
        Update: {total_update}, created: {total_insert}
      </a>
      {minimal && <Table dataSource={result} columns={columns} />}
    </>
  );
};

export default WebhookLogDetail;
