import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DatabaseOutlined,
  FileTextOutlined,
  DeleteOutlined,
  LoadingOutlined,
  PlaySquareOutlined,
  SelectOutlined,
} from "@ant-design/icons";
import _get from "lodash/get";
import useAppContext from "../../../modules/context/AppContext";
import { Table, Row, Col, Button, Modal, Tag, Tooltip } from "antd";
import PipelineLog from "./logs";

const PiplineIndex = () => {
  const [pipeline, setPipeline] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);
  const [isRunningPipeline, setIsRunningPipeline] = useState({});
  const { activeSite } = useAppContext();

  useEffect(() => {
    getPipeline();
  }, []);

  const getPipeline = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/list`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    setPipeline(data.data);
  };

  const runPipeline = async (id) => {
    setIsRunningPipeline({ ...isRunningPipeline, [id]: true });
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/run/${id}`,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    setIsRunningPipeline({ ...isRunningPipeline, [id]: false });
    if (data.status === 200) {
      const result_array = _get(data, "data.data", []) || [];
      Modal.info({
        title: data.message,
        content: result_array.join(", "),
      });
    } else {
      Modal.error({
        content: data.message,
      });
    }
  };

  const deletePipeline = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/delete/${id}`,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    if (data.status === 200) {
      Modal.success({
        content: data.message,
      });

      getPipeline();
    } else if (data.status === 400) {
      Modal.info({
        title: "Pipeline is using in widget",
        content: (
          <>
            {data.data.map((item) => (
              <div key={item.id}>
                <a href={`/widgets/${item.id}`} target="_blank">
                  {item.name}{" "}
                  <sub>
                    <SelectOutlined />
                  </sub>
                </a>
              </div>
            ))}
          </>
        ),
      });
    } else {
      Modal.error({
        content: data.message,
      });
    }
  };

  const getPipelineLog = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/get-log/${id}`,
      { withCredentials: true }
    );
    if (data.status === 200) {
      Modal.info({
        title: "Pipeline Log",
        content: <PipelineLog logs={data.data} />,
        width: 1000,
      });
    } else {
      Modal.info({
        title: "Pipeline Log",
        content: <div>No log found</div>,
      });
    }
  };

  const getPipelineData = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/get-data/${id}`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    if (data.status === 200) {
      const items = _get(data, "data.data", []);
      const last_updated = _get(data, "data.last_updated", null);
      Modal.info({
        title: "Pipeline Output",
        content: (
          <>
            <div>{items.join(", ")}</div>

            <div style={{ fontWeight: "bold", marginTop: 5 }}>
              Last Updated: {last_updated && last_updated.toLocaleString()}
            </div>
          </>
        ),
      });
    } else {
      Modal.info({
        title: "Pipeline Output",
        content: <div>No data found</div>,
      });
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, value) => {
        return (
          <>
            <Link to={`/pipeline/${value.id}`}>{text}</Link>{" "}
            {value.passed !== true && <Tag color="red">Error</Tag>}
            {value.next_run && (
              <Tag>next: {moment(value.next_run).fromNow()}</Tag>
            )}
          </>
        );
      },
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Field Type",
      dataIndex: "field_type",
      key: "field_type",
    },
    {
      title: "Action",
      key: "id",
      render: (text, value) => {
        return (
          <>
            <Tooltip title="Data">
              <Button
                onClick={() => getPipelineData(value.id)}
                style={{ marginRight: 5 }}
              >
                <DatabaseOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Logs">
              <Button
                onClick={(e) => getPipelineLog(value.id)}
                style={{ marginRight: 5 }}
              >
                <FileTextOutlined />
              </Button>
            </Tooltip>

            <Tooltip title="Run">
              <Button
                onClick={(e) => runPipeline(value.id)}
                style={{ marginRight: 5 }}
                disabled={isRunningPipeline[value.id]}
              >
                {isRunningPipeline[value.id] ? (
                  <LoadingOutlined />
                ) : (
                  <PlaySquareOutlined />
                )}
              </Button>
            </Tooltip>

            <Tooltip title="Delete">
              <Button
                onClick={() => {
                  Modal.confirm({
                    title: "Are you sure?",
                    content: `Do you want to delete "${value.name}" pipeline?`,
                    onOk: () => deletePipeline(value.id),
                  });
                }}
                style={{ marginRight: 5 }}
              >
                <DeleteOutlined />
              </Button>
            </Tooltip>
          </>
        );
      },
    },
  ];
  return (
    <>
      <Row justify="end">
        <Col>
          <Button type="primary">
            <Link to={`/pipeline/new`}> + New</Link>
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={pipeline} />
    </>
  );
};

export default PiplineIndex;
