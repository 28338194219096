import { Col, Row, Slider, Tooltip, Typography, Select } from "antd";
import { useContext, useEffect, useState } from "react";
import AceEditor from "react-ace";
import Draggable, { DraggableCore } from "react-draggable"; // Both at the same time
import Axios from "axios";
import ScriptTag from "./RenderScript";
import { WidgetSettingsContext } from "./context";
import {
  template_default,
  template_three_grid,
  template_most_viewed,
  template_two_grid,
  template_breaking_news,
  style_default,
  style_breaking_news,
} from "./template";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-ejs";
import "ace-builds/src-noconflict/theme-github";

import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";

const { Title } = Typography;

const DesignTab = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const { settingData, handleChangeConfig } = useContext(WidgetSettingsContext);
  const [refresh_number, setRefreshNumber] = useState(0);
  const [widgetDesign, setWidgetDesign] = useState([]);
  const [activeDrags, setActiveDrags] = useState(0);
  const [previewWidth, setPreviewWidth] = useState(1000);
  const html_templates = {
    template_default,
    template_three_grid,
    template_most_viewed,
    template_two_grid,
    template_breaking_news,
  };
  const style_templates = { style_default, style_breaking_news };

  useEffect(() => {
    if (!checkPermission(userRole, "widget_action")) {
      window.location.href = "/widgets";
    }
    rerenderTemplate();
  }, [settingData.template, settingData.style]);

  useEffect(() => {
    getWidgetDesign();
  }, []);

  const rerenderTemplate = () => {
    if (window["onTemplateChange_" + settingData.id]) {
      window["onTemplateChange_" + settingData.id](
        settingData.template,
        settingData.style
      );
    }
  };

  const getWidgetDesign = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/get_design`,
      {
        withCredentials: true,
      }
    );
    setWidgetDesign(data.widgets);
  };

  const onCopyTemplate = async (type, id) => {
    const widget = widgetDesign.find((item) => item.id === id);
    if (type === "template") {
      handleChangeConfig("template", widget.template);
    }
    if (type === "style") {
      handleChangeConfig("style", widget.style);
    }
  };

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };
  const onDrop = (e) => {
    setActiveDrags(activeDrags - 1);
    if (e.target.classList.contains("drop-target")) {
      alert("Dropped!");
      e.target.classList.remove("hovered");
    }
  };
  return (
    <Row>
      <Col span={12}>
        <Row>
          <Col span={12}>
            <Title level={4}>
              Javascript{" "}
              <Tooltip
                title={`{
              title, 
              items 
                { 
                  title, 
                  url, 
                  sub_title, 
                  image, 
                  thumbnail, 
                  description, 
                  sub_description, 
                  date, 
                  premiumType, 
                  tags, 
                  contentType,
                  site,
                  categories { 
                    name, 
                    url 
                  },
                  sub_categories { 
                    name, 
                    url 
                  } 
                } 
              }`}
              >
                ?
              </Tooltip>
            </Title>
          </Col>
          <Col span={12}>
            <Select
              placeholder="apply HTML template"
              style={{ float: "right", width: 200, marginRight: 21 }}
              onChange={(value) => {
                if (
                  [
                    "template_default",
                    "template_two_grid",
                    "template_three_grid",
                    "template_most_viewed",
                    "template_breaking_news",
                  ].includes(value)
                ) {
                  handleChangeConfig("template", html_templates[value]);
                } else {
                  onCopyTemplate("template", value);
                }
              }}
            >
              <Select.OptGroup label="HTML Templates">
                <Select.Option value="template_default">Default</Select.Option>
                <Select.Option value="template_two_grid">2 Grids</Select.Option>
                <Select.Option value="template_three_grid">
                  3 Grids
                </Select.Option>
                <Select.Option value="template_most_viewed">
                  Most View
                </Select.Option>
                <Select.Option value="template_breaking_news">
                  Breaking News
                </Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="Copy from widgets">
                {widgetDesign.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            </Select>
          </Col>
        </Row>

        <AceEditor
          mode="ejs"
          theme="github"
          width="100%"
          name="code-js"
          value={settingData.template}
          onChange={(value) => {
            handleChangeConfig("template", value);
          }}
          onBlur={(e, b, value) => {
            // handleChangeConfig("template", value);
          }}
          setOptions={{
            useSoftTabs: true,
          }}
        />
      </Col>
      <Col span={12}>
        <Row>
          <Col span={12}>
            <Title level={4}>CSS</Title>
          </Col>
          <Col span={12}>
            <Select
              placeholder="apply css template"
              style={{ float: "right", width: 200, marginRight: 21 }}
              onChange={(value) => {
                if (["style_default", "style_breaking_news"].includes(value)) {
                  handleChangeConfig("style", style_templates[value]);
                } else {
                  onCopyTemplate("style", value);
                }
              }}
            >
              <Select.OptGroup label="Style Templates">
                <Select.Option value="style_default">Default</Select.Option>
                <Select.Option value="style_breaking_news">
                  Breaking News
                </Select.Option>
              </Select.OptGroup>
              <Select.OptGroup label="Copy from widgets">
                {widgetDesign.map((item) => {
                  return (
                    <Select.Option value={item.id} key={item.id}>
                      {item.name}
                    </Select.Option>
                  );
                })}
              </Select.OptGroup>
            </Select>
          </Col>
        </Row>
        <AceEditor
          mode="css"
          theme="github"
          width="100%"
          name="code-css"
          value={settingData.style}
          onChange={(value) => {
            handleChangeConfig("style", value);
          }}
          setOptions={{
            useSoftTabs: true,
          }}
        />
      </Col>

      <Col span={24} style={{ marginTop: 50 }}>
        <Title level={4}>
          Preview{" "}
          <Slider
            tipFormatter={(val) => val + "px"}
            onAfterChange={(val) => {
              setPreviewWidth(val);
            }}
            defaultValue={previewWidth}
            min={100}
            max={1200}
            style={{ width: "calc(100% - 300px)", float: "right" }}
          />
        </Title>
        <Draggable
          onStart={(e) => {
            let elems = document.getElementsByClassName("react-draggable");
            for (let i = 0; i < elems.length; i++) {
              elems[i].style.zIndex = 1;
              e.currentTarget.style.zIndex = 2;
            }
          }}
          offsetParent={document.getElementById("root")}
        >
          <div
            className="react-draggable"
            style={{
              width: previewWidth,
              zIndex: 10,
            }}
          >
            <div
              style={{
                border: "1px dashed #ddd",
                borderBottom: "0px",
                textAlign: "center",
                fontSize: 12,
                height: "20px",
                marginTop: 30,
                cursor: "move",
              }}
            >
              {previewWidth}px
            </div>
            <ScriptTag
              url={`${process.env.REACT_APP_API_URL.replace(
                /http(s)?:/,
                ""
              )}/articles/settings/${settingData.id}.js?test=1`}
              refresh={refresh_number}
            />
            <div id={`tr-${settingData.id}`}></div>
          </div>
        </Draggable>
      </Col>
    </Row>
  );
};

export default DesignTab;
