import { useEffect, useState } from "react";
import { Skeleton, Typography, Card, Empty, Table, Row, Col } from "antd";
import Axios from "axios";
import useAppContext from "../../modules/context/AppContext";
const { Title } = Typography;

const WidgetClickInfo = ({
  id,
  from = null,
  to = null,
  title,
  content_id = null,
  devices,
  is_more = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 62000);
    return () => clearInterval(interval);
  }, [from, to, content_id, devices]);

  const getData = async () => {
    setLoading(true);

    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getImpInfo/${id}`,
      { from, to, content_id, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );
    setData(data);
    setLoading(false);
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (value, record) => <img src={value} style={{ height: "50px" }} />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return <a href={record.canonical}>{text}</a>;
      },
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  if (loading) {
    if (!id) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <Title level={4}>{title}</Title>
      {data.map((widget) => (
        <>
          <Title level={5}>{widget.name}</Title>
          <Table dataSource={widget.data} columns={columns} rowKey={"id"} />
        </>
      ))}
    </Card>
  );
};

export default WidgetClickInfo;
