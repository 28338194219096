import { useContext, useEffect, useState } from "react";
import {
  Form,
  Select,
  Switch,
  Checkbox,
  Slider,
  Row,
  Col,
  Typography,
  Divider,
  Radio,
  Tag,
  Input,
  Button,
  Alert,
} from "antd";
import _set from "lodash/set";
import _get from "lodash/get";
import _sumBy from "lodash/sumBy";
import _uniqBy from "lodash/uniqBy";
import Axios from "axios";
import styled from "styled-components";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { WidgetSettingsContext } from "./context";
import useApi from "../../modules/hooks/useApi";

import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";

const { Option } = Select;
const CheckboxGroup = Checkbox.Group;
const { Title } = Typography;
const ABTesting = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const { handleChangeConfig, settingData } = useContext(WidgetSettingsContext);
  const [widgets, setWidgetList] = useState([]);
  const [warningBox, setWarningBox] = useState({ show: false, text: null });
  const [ABWidgets, setABWidgets] = useState(
    (settingData.ab || []).length === 0
      ? [{ id: settingData.id, percent: 50 }]
      : settingData.ab
  );

  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/list?all=true`,
      {
        withCredentials: true,
      }
    );
    setWidgetList(data.widgets);
  };

  useEffect(() => {
    getWidgetList();
  }, []);

  useEffect(() => {
    if(!checkPermission(userRole, "widget_action")){
      window.location.href='/widgets'
    }
    handleChangeConfig("ab", ABWidgets);
    checkWarning();
  }, [ABWidgets]);

  const selectWidget = (value, i) => {
    const widget = widgets.find((widget) => widget.id === value);
    ABWidgets[i] = { id: widget.id, percent: ABWidgets[i].percent };
    setABWidgets([...ABWidgets]);
  };

  const addOrRemoveWidget = (action, i) => {
    if (action === "add") {
      setABWidgets([...ABWidgets, { id: ABWidgets[i].id, percent: 50 }]);
    } else if ("remove") {
      const widget = ABWidgets.slice(0, i).concat(ABWidgets.slice(i + 1));
      setABWidgets(widget);
    }
  };

  const checkWarning = () => {
    // console.log(_sumBy(ABWidgets, "percent"));
    if (_sumBy(ABWidgets, "percent") !== 100) {
      setWarningBox({ show: true, text: "กรุณากำหนดร้อยละของรายการที่เลือก" });
      return;
    }

    const totalWidget = ABWidgets.length;
    const editTotal = _uniqBy(ABWidgets, "id").length;
    if (totalWidget !== editTotal) {
      setWarningBox({
        show: true,
        text: "กรุณากำหนดรายการที่เลือกเพียงครั้งเดียว",
      });
      return;
    }
    setWarningBox({ show: false, text: null });
  };

  const renderABWidget = () => {
    const abWidget = [];
    for (let i = 0; i < (ABWidgets || []).length; i++) {
      abWidget.push(
        <Form.Item
          name={"widget " + (i + 1)}
          label={"Widget " + (i + 1)}
          key={i}
        >
          <span>
            <Select
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().includes(input.toLowerCase())
              }
              style={{ width: 300, margin: "0 8px" }}
              onChange={(val) => selectWidget(val, i)}
              value={ABWidgets[i].id}
              disabled={i === 0}
            >
              {(widgets || []).map((widget) => (
                <Select.Option value={widget.id} key={widget.id}>
                  {widget.name}
                </Select.Option>
              ))}
            </Select>
            <Input
              type="number"
              addonAfter="%"
              style={{ width: 100 }}
              value={ABWidgets[i].percent}
              onChange={(val) => {
                ABWidgets[i] = { ...ABWidgets[i], percent: +val.target.value };
                setABWidgets([...ABWidgets]);
              }}
            />
            {i !== 0 && (
              <Button
                type="link"
                onClick={() => addOrRemoveWidget("remove", i)}
              >
                -
              </Button>
            )}
            <Button type="link" onClick={() => addOrRemoveWidget("add", i)}>
              +
            </Button>
          </span>
        </Form.Item>
      );
    }
    return abWidget;
  };

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        {renderABWidget()}
        {warningBox.show === true && (
          <Alert type={"error"} message={warningBox.text} showIcon={true} />
        )}
      </Form>
    </>
  );
};

export default ABTesting;
