const Axios = require("axios");
const { useState, useEffect } = require("react");

const useApi = (
  method,
  api,
  body = null,
  options = { withCredentials: true }
) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, [method, api, body]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const { data } = await Axios({
        method,
        url: process.env.REACT_APP_API_URL + api,
        data: body,
        ...options,
      });
      setData(data);
    } catch (e) {
      setError(e);
    }
    setLoading(false);
  };

  return { data, error, loading };
};

export default useApi;
