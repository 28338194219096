import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
const AppContext = createContext({});

export const AppContextProvider = ({ children }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [sites, setSites] = useState([]);
  const [activeSite, setActiveSite] = useState("");
  const handleChangeSite = (site) => {
    localStorage.setItem("activeSite", site);
    setActiveSite(site);
    window.location.href = window.location.href;
    // หน้า widget ดีดไปหน้า home
    // navigate("/");
  };
  const fetchSite = async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/site/list`,
      {
        withCredentials: true,
      }
    );
    if (res.data.status === 200) {
      setSites(res.data.data);
      setActiveSite(
        localStorage.getItem("activeSite") ?? res.data.data?.[0]?.value
      );
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchSite();
    return () => {};
  }, []);

  const store = {
    sites,
    handleChangeSite,
    activeSite,
    fetchSite,
  };
  return (
    <AppContext.Provider value={store}>
      {!isLoading && children}
    </AppContext.Provider>
  );
};

const useAppContext = () => {
  return useContext(AppContext);
};
export default useAppContext;
