import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import {
  Table,
  Button,
  Row,
  Col,
  Modal,
  Tag,
  Menu,
  Dropdown,
  Badge,
  Tooltip,
  Pagination,
  Select,
  Input,
} from "antd";
import {
  ControlOutlined,
  AppstoreOutlined,
  CopyOutlined,
  LineChartOutlined,
  BranchesOutlined,
  MoreOutlined,
  DeleteOutlined,
  SnippetsOutlined,
  FireOutlined,
  PoweroffOutlined,
} from "@ant-design/icons";
import moment from "moment";
import _get from "lodash/get";
import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";
import MiniChart from "../../Components/Charts/MiniChart";
import Swal from "sweetalert2";
import useAppContext from "../../modules/context/AppContext";

const WidgetList = ({}) => {
  const { user } = useAuthUser();
  const [page, setPage] = useState();
  const [totalPage, setTotalPage] = useState();
  const [search, setSearch] = useState("");
  const [options, setOptions] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [deleteWidgetModal, setDeleteWidgetModal] = useState(false);
  const [deleteWidgetData, setDeleteWidgetData] = useState({});
  const [widgetTags, setWidgetTags] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [miniCharts, setMiniCharts] = useState({});
  const { activeSite } = useAppContext();
  useEffect(() => {
    getWidgetList();
  }, [page, selectedTags, activeSite]);

  useEffect(() => {
    getWidgetTags();
  }, []);

  useEffect(() => {
    getMiniChartData();
  }, [widgets]);

  const getWidgetTags = async () => {
    const {
      data: { data },
    } = await Axios.get(`${process.env.REACT_APP_API_URL}/v1.0/widget/tags`, {
      withCredentials: true,
    });
    setWidgetTags(data);
  };

  const onSearchKeyword = async (e) => {
    setSearch(e.target.value);
  };

  const getMiniChartData = async () => {
    const ids = [];
    widgets.forEach((widget) => {
      if (!miniCharts[widget.id]) {
        ids.push(widget.id);
      }
    });

    if (ids.length > 0) {
      const {
        data: { data },
      } = await Axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0/stats/getWidgetImpByDate/${ids[0]}`,
        {
          from: moment().subtract(30, "days").endOf("day"),
          to: moment().endOf("day"),
          compare_ids: ids,
          on_site: activeSite,
        },
        {
          withCredentials: true,
        }
      );

      const _miniCharts = {};
      data.forEach((item) => {
        _miniCharts[item.id] = item;
      });
      setMiniCharts({ ...miniCharts, ..._miniCharts });
    }
  };

  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(`${process.env.REACT_APP_API_URL}/v1.0/widget/list`, {
      withCredentials: true,
      params: { name: search, page, tags: selectedTags, on_site: activeSite },
    });
    if (data.total <= 10) {
      setPage(1);
    }
    setTotalPage(data.total);
    setWidgets(data.widgets);
  };

  const duplicateWidget = async (id) => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/duplicate/${id}`,
      {
        withCredentials: true,
      }
    );
    setWidgets([data, ...widgets]);
  };

  const deleteWidget = async (id) => {
    const {
      data: { data },
    } = await Axios.delete(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/delete/${id}`,
      {
        withCredentials: true,
      }
    );
    if (data.success) {
      setWidgets(widgets.filter((widget) => widget._id !== id));
    }
    setDeleteWidgetModal(false);
  };

  const clearCache = async (id) => {
    const { data: data } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/tools/clearCacheById`,
      {
        id: id,
      },
      {
        withCredentials: true,
      }
    );

    if (data.data) {
      Swal.fire("Deleted Cache !", "ทำการลบแคชเรียบร้อยแล้ว!", "success");
    }
  };

  const confirmDeleteWidget = (id) => {
    setDeleteWidgetModal(true);
    setDeleteWidgetData(widgets.find((widget) => widget._id === id));
  };

  const isLive = (id) => {
    const data = _get(miniCharts, [id, "data"], []);
    if (data.length > 0) {
      return data[data.length - 1].imp_widget > 0;
    } else {
      return false;
    }
  };

  const columns = [
    {
      title: "CTR ",
      dataIndex: "id",
      key: "id",
      render: (id) => (
        <div style={{ width: 100, height: 40, margin: "auto" }}>
          <MiniChart data={miniCharts[id] || {}} />
        </div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, obj) => (
        <Link to={`/widgets/${obj.id}`}>
          <Badge color={isLive(obj.id) ? "#87d068" : "#f50"} text="" />
          <a>{text}</a>
          {(obj.ab || []).length > 1 && (
            <Tag color="#1890ff" style={{ marginLeft: 10 }}>
              AB
            </Tag>
          )}
          {obj.enable === false && (
            <Tag color="#f50" style={{ marginLeft: 10 }}>
              <PoweroffOutlined />
            </Tag>
          )}
        </Link>
      ),
    },
    {
      title: "Type",
      dataIndex: "widget_tags",
      key: "widget_tags",
      render: (text, obj) =>
        (obj.widget_tags || []).map((tag) => <Tag color="#87d068">{tag}</Tag>),
    },
    {
      title: "Update",
      dataIndex: "updated_at",
      key: "updated_at",
    },
    {
      title: "Action",
      key: "action",
      render: (text, obj) => (
        <>
          {checkPermission(user.role_name, "widget_action") && (
            <Tooltip title="Settings">
              <Button
                onClick={(e) => e.preventDefault()}
                style={{ marginRight: 5 }}
              >
                <Link to={`/widgets/${obj.id}`}>
                  <ControlOutlined />
                </Link>
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Stats">
            <Button
              onClick={(e) => e.preventDefault()}
              style={{ marginRight: 5 }}
            >
              <Link to={`/widgets/${obj.id}#tab-stats`}>
                <LineChartOutlined />
              </Link>
            </Button>
          </Tooltip>
          {checkPermission(user.role_name, "widget_create") && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item key="design">
                    <Link to={`/widgets/${obj.id}#tab-design`}>
                      <AppstoreOutlined /> Design
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="code">
                    <Link to={`/widgets/${obj.id}#tab-code`}>
                      <CopyOutlined /> Copy code
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="ab">
                    <Link to={`/widgets/${obj.id}#tab-ab`}>
                      <BranchesOutlined /> A/B Testing
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="dup" onClick={() => duplicateWidget(obj.id)}>
                    <SnippetsOutlined /> Duplicate
                  </Menu.Item>
                  <Menu.Item key="cache" onClick={() => clearCache(obj.id)}>
                    <FireOutlined /> เคลียร์ Cache
                  </Menu.Item>
                  <Menu.Divider />
                  <Menu.Item key="4">
                    <Button
                      type="text"
                      danger
                      onClick={() => confirmDeleteWidget(obj.id)}
                      disabled={obj.isLive}
                    >
                      <DeleteOutlined /> Delete
                    </Button>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
            >
              <Tooltip title="อื่นๆ">
                <Button onClick={(e) => e.preventDefault()}>
                  <MoreOutlined />
                </Button>
              </Tooltip>
            </Dropdown>
          )}
        </>
      ),
    },
  ];
  return (
    <div style={{ margin: "10px auto" }}>
      <h2>Widgets</h2>

      <Row>
        <Col span={24}>
          <Input
            value={search}
            options={options}
            style={{
              width: 200,
            }}
            onChange={onSearchKeyword}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                getWidgetList();
              }
            }}
            placeholder="search here"
          />
          <Select
            mode="tags"
            style={{ width: 200, marginLeft: 10 }}
            onChange={(value) => {
              setSelectedTags(value);
            }}
            placeholder="filter by type"
          >
            {widgetTags.map((tag) => (
              <Select.Option value={tag.name}>{tag.name}</Select.Option>
            ))}
          </Select>
          <Button type="primary" style={{ float: "right", marginBottom: 10 }}>
            {checkPermission(user.role_name, "widget_create") && (
              <Link to="/widgets/new">เพิ่ม</Link>
            )}
          </Button>
        </Col>
      </Row>
      <Table dataSource={widgets} columns={columns} pagination={false} />
      <Row justify="end" align="middle" style={{ margin: "16px 0" }}>
        <Col>
          <Pagination
            current={page || 1}
            total={totalPage}
            defaultPageSize={10}
            onChange={(pageSelet) => {
              setPage(pageSelet);
            }}
          />
        </Col>
      </Row>
      <Modal
        title={`ต้องการลบ ${deleteWidgetData.name} นี้หรือไม่`}
        visible={deleteWidgetModal}
        onOk={() => deleteWidget(deleteWidgetData._id)}
        onCancel={() => setDeleteWidgetModal(false)}
      ></Modal>
    </div>
  );
};

export default WidgetList;
