import styled from "styled-components";

const LogoDiv = styled.div`
  background-color: #fff;
`;

const Logo = () => {
  return <LogoDiv className="logo"></LogoDiv>;
};

export default Logo;
