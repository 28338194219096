import { useEffect, useState } from "react";
import { Skeleton, Typography, Table, Row, Col, Tag, Button } from "antd";
import Axios from "axios";
import _keyBy from "lodash/keyBy";
import _get from "lodash/get";
import { Link } from "react-router-dom";
import { CSVLink } from "react-csv";
import moment from "moment";
import useAppContext from "../../modules/context/AppContext";
const { Title } = Typography;

const WidgetsTable = ({
  from,
  to,
  widgets,
  compare_ids = [],
  devices = [],
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const widgetsKey = _keyBy(widgets, "id");
  const [dataExport, setDataExport] = useState([]);
  const { activeSite } = useAppContext();
  const headers = [
    { label: "Widget", key: "widgets" },
    { label: "Type", key: "type" },
    { label: "Widget Imp", key: "imp_widget" },
    { label: "Widget Ctr", key: "ctr_widget" },
    { label: "Content Click", key: "click" },
  ];
  useEffect(() => {
    getData();
  }, [from, to, compare_ids, devices]);

  useEffect(() => {
    getDataExport();
  }, [widgets, data]);

  const getData = async () => {
    if (!from || !to) {
      return;
    }

    if (compare_ids.length <= 0) {
      return;
    }

    if (widgets.length <= 0) {
      return;
    }

    setLoading(true);
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/widgetPerformance`,
      { from, to, compare_ids, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );

    const _data = filterWidget(data);
    setData(_data);
    setLoading(false);
  };

  const filterWidget = (data) => {
    return data.map((group) => {
      return {
        name: group.name,
        data: group.data.filter((widget) => {
          return !!widgetsKey[widget.key];
        }),
      };
    });
  };

  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const getDataExport = () => {
    if (data && data.length > 0 && widgets.length > 0) {
      var _formatExportData = [];
      data.forEach((widget) => {
        _formatExportData[widget.name] = widget.data.map((item) => {
          const name = _get(widgetsKey, [String(item.key), "name"], "");
          const ab =
            _get(widgetsKey, [String(item.key), "ab"], []).length > 1
              ? "AB"
              : "";
          return {
            ...item,
            imp_widget: numberWithCommas(item.imp_widget),
            ctr_widget: numberWithCommas(item.ctr_widget),
            click: numberWithCommas(item.click),
            widgets: name + ab,
            type: _get(widgetsKey, [String(item.key), "widget_tags"], []).map(
              (tag) => {
                return tag;
              }
            ),
          };
        });
      });
      setDataExport(_formatExportData);
    }
  };

  const column = [
    {
      title: "Widget",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Link to={`/widgets/${value}#tab-stats`}>
          {_get(widgetsKey, [value, "name"], "")}
          {_get(widgetsKey, [value, "ab"], []).length > 1 && (
            <Tag color="#f50" style={{ marginLeft: 10 }}>
              AB
            </Tag>
          )}
        </Link>
      ),
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Type",
      dataIndex: "key",
      key: "widget_tags",
      render: (value, record) =>
        _get(widgetsKey, [record.key, "widget_tags"], []).map((tag) => (
          <Tag color="#87d068">{tag}</Tag>
        )),
    },
    {
      title: "Widget Imp",
      dataIndex: "imp_widget",
      key: "imp_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.imp_widget - b.imp_widget,
    },
    {
      title: "Widget CTR",
      dataIndex: "ctr_widget",
      key: "ctr_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.ctr_widget - b.ctr_widget,
    },
    {
      title: "Content Click",
      dataIndex: "click",
      key: "click",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.click - b.click,
    },
  ];

  if (loading) {
    return <Skeleton active />;
  }

  const filename = () => {
    var startMoment = moment(from);
    var endMoment = moment(to);
    return (
      startMoment.format("DD-MM-YYYY") +
        " - " +
        endMoment.format("DD-MM-YYYY") || ""
    );
  };

  return (
    <>
      {data.map((table) => (
        <>
          <Row justify="end" style={{ marginTop: "8px" }}>
            <Col>
              <CSVLink
                data={dataExport[table.name] ? dataExport[table.name] : []}
                headers={headers}
                filename={`${table.name}_${filename()}`}
                target="_blank"
              >
                <Button>export</Button>
              </CSVLink>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Title level={4}>{table.name}</Title>
              <Table dataSource={table.data} columns={column} />
            </Col>
          </Row>
        </>
      ))}
    </>
  );
};

export default WidgetsTable;
