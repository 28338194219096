import React, { useEffect, useState, useContext, useRef } from "react";
import {
  Col,
  Row,
  Tabs,
  Typography,
  Form,
  Input,
  Switch,
  Button,
  Skeleton,
  Radio,
  Divider,
  Slider,
  Alert,
  Select,
} from "antd";
import _set from "lodash/set";
import _get from "lodash/get";

import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import Axios from "axios";
import styled from "styled-components";
import WidgetForm from "./Form";
import ContentList from "./ContentList";
import { WidgetSettingsContext } from "./context";
import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";
import useAppContext from "../../modules/context/AppContext";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const type = "DraggableTabNode";
const { TabPane } = Tabs;
const { Title } = Typography;
const { Option } = Select;
const TabPaneContainer = styled.div``;

const SettingsTab = ({}) => {
  const { settingData, setSettingData, saveWidgetInfo } = useContext(
    WidgetSettingsContext
  );
  const { activeSite } = useAppContext();
  const [loading, setLoading] = useState(true);
  const [onSaving, setOnSaving] = useState(false);
  // const [ai , setAiValue] = useState();
  const [age, setAiAge] = useState([0, 7]);
  const [limit, setAiLimit] = useState([1, 4]);
  const [show_limit, setAiShowLimit] = useState(4);
  const [channel, setChannel] = useState([]);
  const [warningChannel, setWarningChannel] = useState([]);
  const [role_active, setRoleActive] = useState("0");
  const [widgetTags, setWidgetTags] = useState([]);
  const [pipelineData, setPipelineData] = useState([]);

  const { id } = useParams();

  const { user } = useAuthUser();
  const userRole = user.role_name;
  const permission = !checkPermission(userRole, "widget_action");

  useEffect(() => {
    getWidgetTags();
    getPipeline();
  }, []);

  useEffect(() => {
    getWidgetInfo();
  }, [id]);

  const getWidgetTags = async () => {
    const {
      data: { data },
    } = await Axios.get(`${process.env.REACT_APP_API_URL}/v1.0/widget/tags`, {
      withCredentials: true,
    });
    setWidgetTags(data);
  };

  const saveWidgetTags = async (value) => {
    await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/tags`,
      {
        tags: value,
      },
      {
        withCredentials: true,
      }
    );
  };

  const validateWarning = () => {
    const channelTotalItem = {};
    let hasChannel = false;
    settingData.settings.forEach((setting) => {
      _get(setting, "channel.site", []).forEach((site) => {
        if (site === "query") {
          _get(setting, "channel.query", []).forEach((query) => {
            if (!channelTotalItem[query]) channelTotalItem[query] = 0;
            channelTotalItem[query] += setting.show_limit;
          });
        } else {
          if (!channelTotalItem[site]) channelTotalItem[site] = 0;
          channelTotalItem[site] += setting.show_limit;
        }
        hasChannel = true;
      });
    });

    if (!hasChannel) {
      setWarningChannel([]);
    } else {
      const warning = [];
      for (let key in channelTotalItem) {
        if (channelTotalItem[key] < settingData.show_content_limit) {
          warning.push(key);
        }
      }
      if (warning.length > 0) {
        setWarningChannel(warning);
      }
    }
  };

  const getWidgetInfo = async () => {
    if (id !== "new") {
      const {
        data: { data },
      } = await Axios.get(
        `${process.env.REACT_APP_API_URL}/v1.0/widget/settings/${id}`,
        {
          withCredentials: true,
        }
      );
      setSettingData(data);
    }
    setLoading(false);
  };

  const getPipeline = async () => {
    const { data } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/list`,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    setPipelineData(data.data);
  };

  const handleChange = (key, value) => {
    setSettingData({ ...settingData, [key]: value });
    validateWarning();
  };

  const doSaveWidgetInfo = async () => {
    setOnSaving(true);
    await saveWidgetInfo();
    clearCacheById();
    setOnSaving(false);
  };

  const onEditTab = (index, action) => {
    const newSettings = [...settingData.settings];

    if (action === "add") {
      newSettings.push({});
    } else if (action === "remove") {
      newSettings.splice(index, 1);
    }
    setSettingData({
      ...settingData,
      settings: newSettings,
    });
  };

  const handleAI = (key, value, i) => {
    const newSettings = [...settingData.settings];
    newSettings[i][key] = value.target.value;
    setSettingData({
      ...settingData,
      settings: newSettings,
    });
  };

  const onFieldsChange = (field, value, i) => {
    if (field === "age") {
      setAiAge(value);
    } else if (field === "limit") {
      setAiLimit(value);
    } else if (field === "show_limit") {
      setAiShowLimit(value);
    }
    const _data = { ...settingData };
    _data.settings[i][field] = value;
    setSettingData(_data);
  };

  const onFieldContentLimitChange = (key, value) => {
    setSettingData({ ...settingData, [key]: value });
  };

  const aiAge = (age) => {
    if (age) {
      setAiAge(age);
    } else {
      setAiAge([0, 7]);
    }
    return age;
  };

  const aiLimit = (limit) => {
    if (limit) {
      setAiLimit(limit);
    } else {
      setAiLimit([1, 4]);
    }
    return limit;
  };

  const aiShowLimit = (show_limit) => {
    if (show_limit) {
      setAiShowLimit(show_limit);
    } else {
      setAiShowLimit(4);
    }
    return show_limit;
  };

  const contentShowLimit = (content_show_limit) => {
    if (!content_show_limit) {
      setSettingData({ ...settingData, show_content_limit: 4 });
    }
    return content_show_limit;
  };

  const clearCacheById = async () => {
    const { data: data } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/tools/clearCacheById`,
      {
        id,
      }
    );
  };

  const RenderAiTab = (settings, i) => {
    if (!settings.settings.age) {
      settings.settings.age = [0, 7];
      settings.settings.limit = [1, 4];
      settings.settings.stop_limit = [4];
    }

    return (
      <>
        <Divider dashed orientation="left">
          <Title level={4}>ตั้งค่าทั่วไป</Title>
        </Divider>
        <Form.Item label="ระบุประเภท content">
          <Select
            disabled={permission}
            mode="tags"
            style={{ width: "100%" }}
            onChange={(value) => {
              onFieldsChange("contentType", value, settings.index);
            }}
            value={settings.settings.contentType || []}
          >
            <Option key="content" value="content">
              Content
            </Option>
            <Option key="video" value="video">
              Video
            </Option>
          </Select>
        </Form.Item>

        <Form.Item label="Age">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                range
                min={0}
                max={365}
                defaultValue={aiAge(settings.settings.age)}
                onAfterChange={(value) => {
                  onFieldsChange("age", value, settings.index);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>
                {age[0]}-{age[1]} วัน
              </span>
            </Col>
          </Row>
        </Form.Item>

        <Form.Item label="จำนวนข่าวที่ค้นหา">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                range
                min={1}
                max={100}
                defaultValue={aiLimit(settings.settings.limit)}
                onAfterChange={(value) => {
                  onFieldsChange("limit", value, settings.index);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>
                {limit[0]}-{limit[1]}
              </span>
            </Col>
          </Row>
        </Form.Item>
        <Form.Item label="จำนวนข่าวที่แสดงจริง">
          <Row>
            <Col span={16}>
              <Slider
                disabled={permission}
                min={1}
                max={20}
                defaultValue={aiShowLimit(settings.settings.show_limit)}
                onAfterChange={(value) => {
                  onFieldsChange("show_limit", value, settings.index);
                }}
              />
            </Col>
            <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
              <span>{show_limit}</span>
            </Col>
          </Row>
        </Form.Item>
      </>
    );
  };

  const RenderAiBehTab = (settings, i) => {
    return (
      <Form.Item label="จำนวนข่าวที่แสดงจริง">
        <Row>
          <Col span={16}>
            <Slider
              disabled={permission}
              min={1}
              max={20}
              defaultValue={aiShowLimit(settings.settings.show_limit)}
              onAfterChange={(value) => {
                onFieldsChange("show_limit", value, settings.index);
              }}
            />
          </Col>
          <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
            <span>{show_limit}</span>
          </Col>
        </Row>
      </Form.Item>
    );
  };

  const DraggableTabNode = ({ index, children, moveNode }) => {
    const ref = useRef(null);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: (monitor) => {
        const { index: dragIndex } = monitor.getItem() || {};

        if (dragIndex === index) {
          return {};
        }

        return {
          isOver: monitor.isOver(),
          dropClassName: "dropping",
        };
      },
      drop: (item) => {
        moveNode(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: {
        index,
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    return (
      <div
        ref={ref}
        style={{
          marginRight: 24,
        }}
        className={isOver ? dropClassName : ""}
      >
        {children}
      </div>
    );
  };

  const swapArrayElements = (arr, indexA, indexB) => {
    var temp = arr[indexA];
    arr[indexA] = arr[indexB];
    arr[indexB] = temp;
  };

  const DraggableTabs = (props) => {
    const { children } = props;
    const [order, setOrder] = useState([]);

    const moveTabNode = (dragKey, hoverKey) => {
      const newOrder = order.slice();
      React.Children.forEach(children, (c) => {
        if (c.key && newOrder.indexOf(c.key) === -1) {
          newOrder.push(c.key);
        }
      });
      const dragIndex = newOrder.indexOf(dragKey);
      const hoverIndex = newOrder.indexOf(hoverKey);
      newOrder.splice(dragIndex, 1);
      newOrder.splice(hoverIndex, 0, dragKey);
      setOrder(newOrder);

      swapArrayElements(settingData.settings, dragKey, hoverKey);
    };

    const renderTabBar = (tabBarProps, DefaultTabBar) => (
      <DefaultTabBar {...tabBarProps}>
        {(node) => (
          <DraggableTabNode
            key={node.key}
            index={node.key}
            moveNode={moveTabNode}
          >
            {node}
          </DraggableTabNode>
        )}
      </DefaultTabBar>
    );

    const tabs = [];
    React.Children.forEach(children, (c) => {
      tabs.push(c);
    });
    const orderTabs = tabs.slice().sort((a, b) => {
      const orderA = order.indexOf(a.key);
      const orderB = order.indexOf(b.key);

      if (orderA !== -1 && orderB !== -1) {
        return orderA - orderB;
      }

      if (orderA !== -1) {
        return -1;
      }

      if (orderB !== -1) {
        return 1;
      }

      const ia = tabs.indexOf(a);
      const ib = tabs.indexOf(b);
      return ia - ib;
    });
    return (
      <DndProvider backend={HTML5Backend}>
        <Tabs
          renderTabBar={renderTabBar}
          activeKey={role_active}
          type="editable-card"
          onEdit={(targetKey, action) => {
            if (!permission) {
              onEditTab(targetKey, action);
            }
          }}
          onChange={(activeKey) => onChangeRoleTab(activeKey)}
          {...props}
        >
          {orderTabs}
        </Tabs>
      </DndProvider>
    );
  };

  const onChangeRoleTab = (activeKey) => {
    setRoleActive(activeKey);
  };

  const renderTab = (setting) => {
    setting.map((items) => {
      if (!items.engine) {
        items.engine = "es";
      }
    });

    return (
      <>
        <DraggableTabs>
          {setting.map((setting, i) => (
            <TabPane tab={`rule ${i + 1}`} key={i} type="card">
              <TabPaneContainer>
                <Form
                  name="basic"
                  labelCol={{ span: 6 }}
                  wrapperCol={{ span: 16 }}
                >
                  <Form.Item label="อัลกอริทึม" style={{ marginBottom: 0 }}>
                    <Radio.Group
                      disabled={permission}
                      value={setting.engine || "es"}
                      onChange={(e) => handleAI("engine", e, i)}
                    >
                      <Radio.Button value="es">Normal</Radio.Button>
                      <Radio.Button value="airelated">AI Related</Radio.Button>
                      <Radio.Button value="aisimilarevent">
                        AI Similar Event
                      </Radio.Button>
                      <Radio.Button value="aibehavior">AI Beh</Radio.Button>
                    </Radio.Group>
                  </Form.Item>
                </Form>

                {setting.engine === "es" ? (
                  <WidgetForm
                    permission={permission}
                    settings={setting}
                    index={i}
                    pipelineData={pipelineData}
                  />
                ) : setting.engine === "airelated" ? (
                  <RenderAiTab settings={setting} index={i} />
                ) : setting.engine === "aisimilarevent" ? (
                  <RenderAiTab settings={setting} index={i} />
                ) : setting.engine === "aibehavior" ? (
                  <RenderAiBehTab settings={setting} index={i} />
                ) : (
                  ""
                )}
              </TabPaneContainer>
            </TabPane>
          ))}
        </DraggableTabs>
      </>
    );
  };

  if (loading) {
    return <Skeleton action />;
  }

  return (
    <>
      <Row>
        <Col span={14}>
          <Title level={4}>Setup</Title>
          <Form name="basic" labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
            <Form.Item label="หัวข้อ">
              <Input
                disabled={permission}
                placeholder="ข่าวแนะนำ"
                onChange={(e) => {
                  handleChange("title", e.target.value);
                }}
                value={settingData.title}
              />
            </Form.Item>
            <Form.Item label="เปิด/ปิด widget" style={{ marginBottom: 0 }}>
              <Switch
                disabled={permission}
                size="small"
                checkedChildren="เปิด"
                unCheckedChildren="ปิด"
                onChange={(value) => handleChange("enable", value)}
                key={"switch-enable"}
                checked={settingData.enable}
                defaultChecked
              />
            </Form.Item>
            <Form.Item label="relate กับข่าว" style={{ marginBottom: 0 }}>
              <Switch
                disabled={permission}
                size="small"
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={(value) => handleChange("relate_article", value)}
                key={"switch-category"}
                checked={settingData.relate_article}
                defaultChecked
              />
            </Form.Item>
            <br />
            <Form.Item
              label="จำนวนข่าวที่แสดงทั้งหมด"
              style={{ marginBottom: 0 }}
            >
              <Row>
                <Col span={16}>
                  {/* {console.log(settingData.show_content_limit)} */}
                  <Slider
                    disabled={permission}
                    min={1}
                    max={100}
                    defaultValue={contentShowLimit(
                      settingData.show_content_limit
                    )}
                    onAfterChange={(value) => {
                      onFieldContentLimitChange("show_content_limit", value);
                    }}
                  />
                </Col>
                <Col span={4} style={{ paddingTop: 5, textAlign: "right" }}>
                  <span>
                    {settingData.show_content_limit
                      ? settingData.show_content_limit
                      : 4}
                  </span>
                </Col>
              </Row>
            </Form.Item>
            <br />
            <Form.Item label="ประเภท widget" style={{ marginBottom: 0 }}>
              <Select
                mode="tags"
                style={{ width: "100%" }}
                placeholder="tag"
                onChange={(value) => {
                  handleChange("widget_tags", value);
                  saveWidgetTags(value);
                }}
                value={settingData.widget_tags}
              >
                {widgetTags.map((tag) => (
                  <Select.Option value={tag.name}>{tag.name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
            {/* <br /> */}
            {/* <Form.Item
              label="website ที่ติด widget"
              style={{ marginBottom: 0 }}
            >
              <Select
                style={{ width: "100%" }}
                onChange={(value) => {
                  handleChange("on_site", value);
                }}
                value={settingData.on_site}
                defaultValue="thairath-online"
              >
                <Select.Option key="thairath-online" value="thairath-online">
                  Thairath Online
                </Select.Option>
                <Select.Option key="thairath-plus" value="thairath-plus">
                  Thairath Plus
                </Select.Option>
                <Select.Option key="money" value="money">
                  Money
                </Select.Option>
              </Select>
            </Form.Item> */}
          </Form>
          <br />

          <Row>
            <Col span={24}>
              {/* <Tabs
                defaultActiveKey="1"
                type="editable-card"
                className="card-container"
                onEdit={(targetKey, action) => {
                  if (!permission) {
                    onEditTab(targetKey, action);
                  }
                }}
              >  */}
              {/* {(settingData.settings || []).map((s, i) => renderTab(i, s))} */}

              {settingData.settings ? renderTab(settingData.settings) : []}

              {/* </Tabs> */}
            </Col>

            {!permission && (
              <Col span={12} offset={11}>
                <Button
                  type="primary"
                  onClick={doSaveWidgetInfo}
                  loading={onSaving}
                >
                  Save
                </Button>
              </Col>
            )}
            {warningChannel.length > 0 && (
              <Col span={24}>
                <Alert
                  type="warning"
                  message={`จำนวนข่าวที่แสดงจริงอาจน้อยกว่า ${
                    settingData.show_content_limit
                  } ข่าว หาก user มาจาก ${warningChannel.join(", ")}`}
                ></Alert>
              </Col>
            )}
          </Row>
        </Col>

        <Col span={10}>
          <ContentList settings={settingData} />
        </Col>
      </Row>
    </>
  );
};

export default SettingsTab;
