import { Fragment, useState } from "react";
import { Row, Col, Input, Button, List } from "antd";
import Axios from "axios";
import _get from "lodash/get";

const UrlList = ({ widget, onFieldsChange }) => {
  const [_url, setUrl] = useState("");

  const addUrl = async () => {
    const data = await getContentByUrl(_url);
    if (data) {
      onFieldsChange("content_url", [
        ...widget.content_url,
        { url: _url, data: data },
      ]);
    } else {
      alert("Invalid URL");
    }
    setUrl("");
  };

  const formatData = async (data) => {
    const result = {
      publishTime:
        data["article:published_time"] || data["twitter:published_time"] || "",
      image: data["og:image"] || data["twitter:image"] || "",
      title: data["og:title"] || data["twitter:title"] || "",
      description: data["og:description"] || data["twitter:description"] || "",
      url: data["og:url"] || _url,
    };
    return result;
  };

  const getContentByUrl = async (url) => {
    try {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/v1.0/content/getMeta`,
        {
          url: url,
        },
        {
          withCredentials: true,
        }
      );
      if (_get(res, "status", "") === 200) {
        return formatData(res.data);
      } else {
        return null;
      }
    } catch (error) {
      console.error(`Error fetching data `, error);
    }
  };

  return (
    <Fragment>
      <Row justify="space-between" gutter={8}>
        <Col flex="auto">
          <Input
            type="url"
            value={_url}
            onChange={(e) => {
              setUrl(e.target.value);
            }}
            placeholder="Enter a URL"
            style={{ width: "100%" }}
          />
        </Col>
        <Col span={2} flex="none">
          <Button type="primary" onClick={addUrl}>
            + Add
          </Button>
        </Col>
      </Row>
      <List
        style={{ marginTop: "20px" }}
        header={<div>URL List</div>}
        bordered
        dataSource={widget.content_url}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <Row align="middle" style={{ display: "flex", flexWrap: "nowrap" }}>
              {item.data.image && (
                <Col span={5} style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={item.data.image}
                    alt={item.data.title}
                    style={{
                      maxWidth: "100%",
                      maxHeight: "100px",
                      objectFit: "cover",
                      borderRadius: "5px",
                    }}
                  />
                </Col>
              )}
              <Col
                span={item.data.image ? 16 : 21}
                style={{ paddingLeft: item.data.image ? "15px" : "0" }}
              >
                <div
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    color: "#888",
                    fontSize: "12px",
                  }}
                >
                  {item.url}
                </div>

                <div style={{ marginBottom: "8px" }}>
                  <a
                    href={item.data.canonical}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      color: "#333",
                    }}
                  >
                    {item.data.title || item.data.canonical}
                  </a>
                  {item.data.description && (
                    <p
                      style={{
                        margin: "5px 0",
                        color: "#555",
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.data.description}
                    </p>
                  )}
                </div>
              </Col>
              <Col span={3} style={{ textAlign: "right" }}>
                <Button
                  type="link"
                  onClick={() => {
                    const _urlList = widget.content_url.filter(
                      (_, i) => i !== index
                    );
                    onFieldsChange("content_url", _urlList);
                  }}
                >
                  Remove
                </Button>
              </Col>
            </Row>
          </List.Item>
        )}
      />
    </Fragment>
  );
};

export default UrlList;
