import { useContext, useState } from "react";
import { Typography, Tag } from "antd";
import TableTransfer from "../../Components/TableTransfer";
import { WidgetsContext, GroupWidgetContext } from "./context";
const { Paragraph } = Typography;

const GroupSettings = ({ onGroupChange, group }) => {
  const { widgets = [] } = useContext(WidgetsContext);

  const filterOption = (inputValue, option) => {
    const tag = option.widget_tags.find((tag) => tag.includes(inputValue));
    if (tag) {
      return true;
    }
    return option.name.indexOf(inputValue) > -1;
  };

  const handleSearch = (dir, value) => {
    console.log("search:", dir, value);
  };

  const handleEditGroup = (key, val) => {
    if (onGroupChange) {
      onGroupChange({ ...group, [key]: val });
    }
  };

  const leftTableColumns = [
    {
      dataIndex: "name",
      title: "Name",
    },
    {
      dataIndex: "widget_tags",
      title: "Type",
      render: (tags) => tags.map((tag) => <Tag color="#87d068">{tag}</Tag>),
    },
  ];
  return (
    <>
      <Paragraph
        editable={{
          onChange: (value) => {
            handleEditGroup("name", value);
          },
        }}
        style={{ fontSize: 30, marginBottom: 10, width: 500 }}
      >
        {group.name || "new group"}
      </Paragraph>

      <TableTransfer
        dataSource={widgets}
        showSearch
        filterOption={filterOption}
        targetKeys={group.widgets}
        onChange={(ids) => handleEditGroup("widgets", ids)}
        onSearch={handleSearch}
        render={(item) => item.title}
        leftColumns={leftTableColumns}
        rightColumns={leftTableColumns}
      />
    </>
  );
};

export default GroupSettings;
