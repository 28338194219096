import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { Divider, Input, Row, Col, Button, Alert } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
const UserPage = () => {
  const { id } = useParams();
  const [dataUser, setDataUser] = useState({
    email: "",
    role_name: "Member",
  });
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [valiedate, setValidate] = useState({
    error: false,
    massage: "",
    success: false,
  });
  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    const res = await Axios.post(`${process.env.REACT_APP_API_URL}/auth/user`, {
      id: id,
    });
    if (res.data.status === 200 && res.data.data._id === id) {
      setDataUser({ ...res.data.data });
    } else {
      window.location.href = "/";
    }
  };

  const onPassword = async () => {
    if(!newPassword)
    {
      return setValidate({success:false,error:true,massage:'กรุณากรอกให้ครบ'})
    }
    const res = await Axios.post(
      `${process.env.REACT_APP_API_URL}/auth/change/password`,
      {
        id,
        oldPassword,
        password: newPassword,
      }
    );
    if (res.data.status === 200) {
        setOldPassword("")
        setNewPassword("")
        getData();
        setValidate({error:false,success:true,massage:'success'})
    } else {
        setValidate({success:false,error:true,massage:'password ไม่ถูกต้อง'})
    }
  };
  return (
    <Fragment>
      <div style={{ margin: "10px auto" }}>
        <h2>Profile</h2>
      </div>
      <Divider />
      <h3>Change Password</h3>
      <Row gutter={8}>
        <Col span={8}>
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Username"
            disabled
            value={dataUser.username}
          />
        </Col>
        <Col span={8}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="oldPassword"
            placeholder="Old Password"
            value={oldPassword}
            onChange={(ev) => {
              setOldPassword(ev.target.value);
            }}
          />
        </Col>
        <Col span={8}>
          <Input.Password
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="newPassword"
            placeholder="New Password"
            value={newPassword}
            onChange={(ev) => {
              setNewPassword(ev.target.value);
            }}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: "8px" }}>
        {valiedate.error && (
          <Alert message={valiedate.massage || "error"} type="error" showIcon />
        )}
        {valiedate.success && (
          <Alert message={valiedate.massage || "success"} type="success" showIcon />
        )}
      </Row>
      <Row justify="end" style={{ marginTop: "8px" }}>
        <Col>
          <Button type="primary" onClick={onPassword}>
            Confirm
          </Button>
        </Col>
      </Row>
    </Fragment>
  );
};

export default UserPage;
