import { useEffect, useState } from "react";
import Axios from "axios";
import { WidgetsContext, GroupWidgetContext } from "./context";
import FilterStats from "./Filter";
import GroupImpByDate from "../../Components/Charts/GroupImpByDate";
import { Col, Row } from "antd";
import ContentPerformance from "../../Components/Charts/ContentPerformance";
import GroupClickPosition from "../../Components/Charts/GroupClickPosition";
import moment from "moment";
import WidgetsTable from "../../Components/Charts/WidgetsTable";
import WidgetImpByUserInfo from "../../Components/Charts/WidgetImpByUserInfo";
import useAppContext from "../../modules/context/AppContext";

const StatsPage = () => {
  const [widgets, setWidgets] = useState([]);
  const [groupWidget, setGroupWidget] = useState([]);
  const [filter, setFilter] = useState({});
  const { activeSite } = useAppContext();
  const [dataDevice, setDataDevice] = useState();

  useEffect(() => {
    getWidgetList();
    getGroupWidgetList();
  }, [activeSite]);

  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/widgets`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );

    setWidgets(
      data.widgets.map((widget) => {
        widget.disabled = false;
        widget.key = widget.id;
        return widget;
      })
    );
  };

  const getGroupWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/list`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    setGroupWidget(data);
  };
  const dateFilename = () => {
    var startMoment = moment(filter.from);
    var endMoment = moment(filter.to);
    return (
      startMoment.format("DD-MM-YYYY") +
        " - " +
        endMoment.format("DD-MM-YYYY") || ""
    );
  };

  return (
    <WidgetsContext.Provider value={{ widgets }}>
      <GroupWidgetContext.Provider value={{ groupWidget, setGroupWidget }}>
        <FilterStats onFilterChange={setFilter} />
        <Row>
          <Col span={24}>
            <GroupImpByDate
              title="Performance"
              from={filter.from}
              to={filter.to}
              compare_ids={filter.group}
              aggsType="group"
              dateFilename={dateFilename}
              devices={filter.devices}
              dataDevice={dataDevice}
            />
          </Col>

          <Col span={24}>
            <WidgetsTable
              title="Performance"
              from={filter.from}
              to={filter.to}
              widgets={widgets}
              compare_ids={filter.group}
              devices={filter.devices}
            />
          </Col>
          <Col span={24}>
            <WidgetImpByUserInfo
              setDataDevice={setDataDevice}
              compare_ids={filter.group}
              title="User Info"
              from={filter.from}
              to={filter.to}
              type={"group"}
              devices={filter.devices}
            />
          </Col>

          <Col span={24}>
            <GroupClickPosition
              ids={filter.group}
              title="Positions"
              from={filter.from}
              to={filter.to}
              devices={filter.devices}
            />
          </Col>

          <Col span={24}>
            <ContentPerformance
              compare_ids={filter.group}
              type={"group"}
              title="Contents Performance"
              from={filter.from}
              to={filter.to}
              devices={filter.devices}
            />
          </Col>
        </Row>
      </GroupWidgetContext.Provider>
    </WidgetsContext.Provider>
  );
};

export default StatsPage;
