import { Fragment, useEffect, useState } from "react";
import { Skeleton, Typography, Card, Empty, Table, Row, Col } from "antd";
import { Line } from "@ant-design/charts";
import Axios from "axios";
import _sumBy from "lodash/sumBy";
import CardNumber from "./CardNumber";
import useAppContext from "../../modules/context/AppContext";

const { Title } = Typography;
const default_number = [{ name: "", count: 0 }];

const WidgetImpByDate = ({
  id,
  from,
  to,
  title,
  compare_ids = [],
  overall,
  devices,
}) => {
  const [data, setData] = useState([]);
  const [chartLegend, setChartLegend] = useState({});
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState({
    imp: [],
    imp_widget: [],
    ctr_widget: [],
    ctr: [],
    click: [],
  });
  const { activeSite } = useAppContext();

  useEffect(() => {
    if (overall.length > 0) {
      setData(overall);
      setLoading(false);
    } else {
      getData();

      const interval = setInterval(() => {
        getData();
      }, 62000);
      return () => clearInterval(interval);
    }
  }, [from, to, compare_ids, overall, devices]);

  const getData = async () => {
    // setLoading(true);

    if (compare_ids.length <= 0 && !id) {
      return;
    }
    if (compare_ids.find((item) => item === "overall")) {
      return;
    }
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getWidgetImpByDate/${id}`,
      { from, to, compare_ids, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );
    setData(data);

    const _chartData = [];
    const _chartLegend = {};
    const _number = {
      imp_widget: [],
      ctr_widget: [],
      click: [],
    };

    data.forEach((item) => {
      item.data.forEach((_item) => {
        _chartData.push({
          date: _item.key,
          value: +_item.ctr_widget,
          id: item.name + " (Widget CTR)",
        });
        _chartData.push({
          date: _item.key,
          value: +_item.imp_widget,
          id: item.name + " (Widget Imp)",
        });
        _chartData.push({
          date: _item.key,
          value: +_item.click,
          id: item.name + " (Click)",
        });
        _chartLegend[item.name + " (Widget Imp)"] = false;
        _chartLegend[item.name + " (Widget CTR)"] = true;
        _chartLegend[item.name + " (Click)"] = false;
      });

      // format data for number

      const imp_widget = _sumBy(item.data, "imp_widget");
      _number.imp_widget.push({
        name: item.name,
        count: imp_widget,
      });

      const click = _sumBy(item.data, "click");
      _number.click.push({
        name: item.name,
        count: click,
      });

      _number.ctr_widget.push({
        name: item.name,
        count: ((click / imp_widget) * 100).toFixed(2),
      });
    });
    setNumber(_number);
    setChartLegend(_chartLegend);
    setChartData(_chartData);
    setLoading(false);
  };

  const config = {
    data: chartData,
    height: 400,
    xField: "date",
    yField: "value",
    seriesField: "id",
    legend: {
      selected: chartLegend,
    },
    xAxis: {
      type: "time",
    },
    point: {
      size: 5,
      shape: "diamond",
    },
  };

  const column = [
    {
      title: "Date",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Typography.Text>
          {new Date(value).toLocaleDateString()}
        </Typography.Text>
      ),
      defaultSortOrder: "descend",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Widget Imp",
      dataIndex: "imp_widget",
      key: "imp_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.imp_widget - b.imp_widget,
    },
    {
      title: "Widget CTR",
      dataIndex: "ctr_widget",
      key: "ctr_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.ctr_widget - b.ctr_widget,
    },
    {
      title: "Content Click",
      dataIndex: "click",
      key: "click",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.click - b.click,
    },
  ];

  if (loading) {
    if (compare_ids.length <= 0 && !id) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  return (
    <>
      <Row>
        <Col flex={1}>
          <CardNumber
            title="Widget Imp"
            data={number.imp_widget || default_number}
          />
        </Col>
        <Col flex={1}>
          <CardNumber
            title="Widget CTR"
            data={number.ctr_widget || default_number}
          />
        </Col>
        <Col flex={1}>
          <CardNumber title="Click" data={number.click || default_number} />
        </Col>
      </Row>
      <Card style={{ marginTop: 10 }}>
        <Title level={4}>{title}</Title>
        {data.length > 0 ? (
          <Line {...config} style={{ marginTop: 10 }} />
        ) : (
          <Empty />
        )}
      </Card>

      <Row style={{ marginTop: 20 }}>
        {data.map((item) => (
          <Col span={24}>
            <Title level={3}>{item.name}</Title>
            <Table dataSource={item.data} columns={column} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WidgetImpByDate;
