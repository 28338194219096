import { Button, Empty, Skeleton, Table, Typography } from "antd";
import Axios from "axios";
import { useEffect, useRef, useState } from "react";
import useAppContext from "../../modules/context/AppContext";
import WidgetClickInfo from "./ClickInfo";
const { Title } = Typography;

const ContentPerformance = ({
  id,
  from = null,
  to = null,
  title,
  compare_ids = [],
  type = "widget",
  devices = [],
  is_more = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getData();
  }, [from, to, compare_ids, devices]);
  const getData = async () => {
    setLoading(true);

    if (compare_ids.length <= 0 && !id) {
      return;
    }
    if (compare_ids.find((item) => item === "overall")) {
      return;
    }

    let url = `${process.env.REACT_APP_API_URL}/v1.0/stats/getContentPerformance/${id}`;
    if (type === "group") {
      url = `${process.env.REACT_APP_API_URL}/v1.0/stats/getGroupContentPerformance`;
    }

    const {
      data: { data },
    } = await Axios.post(
      url,
      { from, to, compare_ids, devices, on_site: activeSite },
      { withCredentials: true }
    );
    setData(data);
    setLoading(false);
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (value, record) => (
        <img src={value} alt={record.title} style={{ height: "50px" }} />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        if (!text) {
          return record.id;
        }
        return (
          <a href={record.canonical} target="_blank" rel="noopener noreferrer">
            {text}
          </a>
        );
      },
    },
    {
      title: "Widget Imp",
      dataIndex: "imp_widget",
      key: "imp_widget",
      render: (text, record) => text.toLocaleString(),
      sorter: (a, b) => a.imp_widget - b.imp_widget,
    },
    {
      title: "Widget CTR",
      dataIndex: "ctr_widget",
      key: "ctr_widget",
      sorter: (a, b) => a.ctr_widget - b.ctr_widget,
    },
    {
      title: "Clicks",
      dataIndex: "click",
      key: "click",
      render: (text, record) => text.toLocaleString(),
      sorter: (a, b) => a.click - b.click,
    },
  ];
  if (loading) {
    if (compare_ids.length <= 0 && !id) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  return (
    <>
      {data.map((table) => (
        <>
          <Title level={4}>{table.name}</Title>
          <Table
            key={table.name}
            dataSource={table.data || []}
            columns={columns}
            rowKey={"id"}
            expandable={{
              expandedRowRender: (record) => {
                return (
                  <WidgetClickInfo
                    key={"sub-WidgetClickInfo-" + record.id}
                    content_id={record.id}
                    from={from}
                    to={to}
                    id={id}
                    title={
                      <span>
                        ผู้ที่อ่าน "<u>{record.title}</u>"{" "}
                        จะคลิ๊กไปอ่านข่าวด้านล่างนี้ต่อ
                      </span>
                    }
                    devices={devices}
                  />
                );
              },
            }}
          />
        </>
      ))}
    </>
  );
};

export default ContentPerformance;
