import { Button, Divider, Layout, Select, Space } from "antd";
import { Row, Col, Avatar, Menu, Dropdown } from "antd";
import { Link, useNavigate } from "react-router-dom";
import useAppContext from "../../modules/context/AppContext";
import useAuthUser from "../../modules/context/AuthUserContext";
import { SettingOutlined } from "@ant-design/icons";
import { checkPermission } from "../../helper/checkPermission";
const { Header } = Layout;

const HeaderBar = () => {
  const { user, logout } = useAuthUser();
  const { sites, activeSite, handleChangeSite } = useAppContext();
  const navigate = useNavigate();
  const handleLogout = async () => {
    const isSuccess = await logout();
    if (isSuccess) window.location.href = "/";
  };
  const menu = (
    <Menu>
      <Menu.Item>
        <Link to={`/profile/${user._id}`}>Profile</Link>
      </Menu.Item>
      <Menu.Item>
        <a target="_blank" rel="noopener noreferrer" onClick={handleLogout}>
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  // console.log(sites);

  return (
    <Header
      className="site-layout-sub-header-background"
      style={{ padding: 0 }}
    >
      <Row justify="end" gutter={12}>
        <Col>
          <Select
            options={(sites || []).map((site) => ({
              label: site.name,
              value: site.slug,
            }))}
            value={activeSite}
            style={{ width: 200 }}
            onChange={handleChangeSite}
            dropdownRender={(menu) => (
              <>
                {menu}
                {checkPermission(user.role_name, "site") && (
                  <>
                    <Divider
                      style={{
                        margin: "4px 0",
                      }}
                    />
                    <Button
                      type="text"
                      icon={<SettingOutlined />}
                      onClick={() => navigate("/site")}
                      style={{ width: "100%" }}
                    >
                      Site Management
                    </Button>
                  </>
                )}
              </>
            )}
          />
        </Col>
        <Col>
          <Dropdown overlay={menu} placement="bottomLeft" trigger="click">
            <Row style={{ cursor: "pointer", marginRight: "8px" }} gutter={8}>
              <Col>
                <span style={{ color: "#fff" }}>{user.role_name}</span>
              </Col>
              <Col>
                <Avatar
                  src={user.profile_image}
                  size={40}
                  style={{ cursor: "pointer" }}
                >
                  USER
                </Avatar>
              </Col>
            </Row>
          </Dropdown>
        </Col>
      </Row>
    </Header>
  );
};

export default HeaderBar;
