import { useEffect } from "react";

const RenderScriptTag = ({ url, refresh }) => {
  useEffect(() => {
    const t = document.createElement("script");
    t.src = url;
    document.body.appendChild(t);
  }, [refresh]);
  return <></>;
};

export default RenderScriptTag;
