import { useEffect, useState } from "react";
import axios from "axios";
import {
  Input,
  Select,
  Form,
  Button,
  Row,
  Col,
  Skeleton,
  Space,
  Modal,
} from "antd";
import _set from "lodash/set";
import { useNavigate, useParams } from "react-router-dom";
import useAppContext from "../../../modules/context/AppContext";
import GA3Datasource from "../ga/ga_datasource";
import ApiDatasource from "../api/api_datasource";
import GA4Datasource from "../ga4";

const DatasourceSettings = () => {
  const { id, source } = useParams();
  const [datasourceData, setDatasourceData] = useState({
    id: "new",
    name: "",
    source: source,
    settings: {},
  });
  const { activeSite } = useAppContext();

  const history = useNavigate();

  useEffect(() => {
    if (id && id !== "new") {
      getDatasource();
    }
  }, []);

  const getDatasource = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources/${id}`,
      { withCredentials: true, params: { on_site: activeSite } }
    );

    if (data.data) {
      setDatasourceData(data.data);
    } else {
      Modal.error({
        content: "Datasource not found",
      });
      history("/pipeline/datasources");
    }
  };

  const saveDatasource = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources`,
      datasourceData,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    setDatasourceData(data.data);
    if (data.status === 200) {
      Modal.success({
        content: "Datasource saved successfully",
      });
      history(`/pipeline/datasource/${data.data.id}`);
    }
  };

  const handleInput = (key, value) => {
    const newData = { ...datasourceData };
    _set(newData, key, value);
    setDatasourceData(newData);
  };

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Datasource Name">
          <Input
            value={datasourceData.name}
            onChange={(e) => handleInput("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Select Datasource">
          <Select
            style={{ width: 300 }}
            value={datasourceData.source}
            onChange={(value) => handleInput("source", value)}
          >
            {["ga4", "api"].map((item) => (
              <Select.Option key={item} value={item}>
                {item}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        {datasourceData.source === "ga3" && (
          <GA3Datasource
            settings={datasourceData.settings}
            callbackSettings={handleInput}
          />
        )}
        {datasourceData.source === "api" && (
          <ApiDatasource
            settings={datasourceData.settings}
            callbackSettings={handleInput}
          />
        )}
        {datasourceData.source === "ga4" && (
          <GA4Datasource
            settings={datasourceData.settings}
            callbackSettings={handleInput}
          />
        )}
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button type="primary" onClick={saveDatasource}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default DatasourceSettings;
