import { Button, Form, Input, Select, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

const ApiDatasource = ({ settings = {}, callbackSettings }) => {
  const [headers, setHeaders] = useState(settings.headers || []);
  const [params, setParams] = useState(settings.params || []);

  useEffect(() => {
    callbackSettings("settings.headers", headers);
  }, [headers]);

  useEffect(() => {
    callbackSettings("settings.params", params);
  }, [params]);

  const addHeader = () => {
    const randomKey = Math.random().toString(36).substring(7);
    setHeaders([...headers, { key: randomKey, name: "", value: "" }]);
  };

  const addParam = () => {
    const randomKey = Math.random().toString(36).substring(7);
    setParams([...params, { key: randomKey, name: "", value: "" }]);
  };

  const handleHeader = (key, value, index) => {
    const newHeaders = [...headers];
    newHeaders[index][key] = value;
    setHeaders(newHeaders);
  };

  const handleParam = (key, value, index) => {
    const newParams = [...params];
    newParams[index][key] = value;
    setParams(newParams);
  };

  const deleteHeaderRow = (index) => {
    const newHeaders = [...headers];
    newHeaders.splice(index, 1);
    setHeaders(newHeaders);
  };

  const deleteParam = (index) => {
    const newParams = [...params];
    newParams.splice(index, 1);
    setParams(newParams);
  };

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="URL">
          <Input
            placeholder="https://your_api_domain"
            value={settings.endpoint}
            onChange={(e) => {
              callbackSettings("settings.endpoint", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item label="Headers (optional)">
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {headers.map((item, i) => (
              <Space.Compact style={{ width: "100%" }} key={item.key}>
                <Input
                  style={{ width: "30%" }}
                  value={item.name}
                  placeholder="Authorization"
                  onChange={(e) => {
                    handleHeader("name", e.target.value, i);
                  }}
                />
                <Input
                  style={{ width: "70%" }}
                  placeholder="Bearer your_token_here"
                  value={item.value}
                  onChange={(e) => {
                    handleHeader("value", e.target.value, i);
                  }}
                />
                <Button type="danger" onClick={() => deleteHeaderRow(i)}>
                  Delete
                </Button>
              </Space.Compact>
            ))}
          </Space>
          <a onClick={addHeader}>+ add header</a>
        </Form.Item>
        <Form.Item label="Params (optional)">
          <Space direction="vertical" size="middle" style={{ width: "100%" }}>
            {params.map((item, i) => (
              <Space.Compact style={{ width: "100%" }} key={item.key}>
                <Input
                  style={{ width: "30%" }}
                  value={item.name}
                  placeholder="access_token"
                  onChange={(e) => {
                    handleParam("name", e.target.value, i);
                  }}
                />
                <Input
                  style={{ width: "70%" }}
                  placeholder=" your_token_here"
                  value={item.value}
                  onChange={(e) => {
                    handleParam("value", e.target.value, i);
                  }}
                />
                <Button type="danger" onClick={() => deleteParam(i)}>
                  Delete
                </Button>
              </Space.Compact>
            ))}
          </Space>
          <a onClick={addParam}>+ add param</a>
        </Form.Item>
      </Form>
    </>
  );
};

export default ApiDatasource;
