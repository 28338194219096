import { Layout } from "antd";
import { Routes, Route } from "react-router-dom";
import Breadcrumb from "Pages/Layouts/Breadcrumb";
import Header from "Pages/Layouts/Header";
import Sidebar from "Pages/Layouts/Sidebar";
import Home from "Pages/Home/Index";
import Dashboard from "Pages/Dashboard/Index";
import WidgetIndex from "../Widget/List";
import Login from "../Login/index";
import Settings from "../Widget/Tabs";
import GroupStats from "../Stats/GroupStats";
import WidgetOverallStats from "../Stats/WidgetOverallStats";
import User from "../User";
import UserSetting from "../User/form";
import Profile from "../User/profile";
import Cache from "../Tools/cache";
import Search from "../Tools/search";
import "antd/dist/antd.less";
import useAuthUser from "../../modules/context/AuthUserContext";
import { Fragment, useEffect } from "react";
import GroupList from "../Stats/GroupList";
import GroupEdit from "../Stats/GroupEdit";
import Site from "../Site";
import SiteForm from "../Site/SiteForm";
import Pipeline from "../Pipeline/pipelines";
import PipelineSettings from "../Pipeline/pipelines/settings";
import Datasources from "../Pipeline/datasources";
import DatasourceSettings from "../Pipeline/datasources/settings";

const { Content } = Layout;

const MainLayout = ({ children }) => {
  const { user } = useAuthUser();
  return (
    <Fragment>
      {user ? (
        <>
          <Layout>
            <Header />
            <Layout>
              <Sidebar />
              <Layout style={{ padding: "0 24px 24px" }}>
                <Breadcrumb />
                <Content
                  className="site-layout-background"
                  style={{
                    minHeight: "calc(100vh - 147px)",
                    backgroundColor: "#fff",
                    padding: 25,
                  }}
                >
                  <Routes>
                    <Route path="/" element={<Home />}></Route>
                    <Route path="widgets" element={<WidgetIndex />}></Route>
                    <Route path="widgets/:id" element={<Settings />} />
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route
                      path="stats/overall"
                      element={<WidgetOverallStats />}
                    />
                    <Route path="stats/group" element={<GroupStats />} />
                    <Route
                      path="stats/group/settings"
                      element={<GroupList />}
                    />
                    <Route
                      path="stats/group/settings/:id"
                      element={<GroupEdit />}
                    />
                    <Route path="user" element={<User />} />
                    <Route path="user/:id" element={<UserSetting />} />
                    <Route path="profile/:id" element={<Profile />} />
                    <Route path="tools/cache" element={<Cache />} />
                    <Route path="tools/search" element={<Search />} />

                    <Route path="pipeline" element={<Pipeline />} />
                    <Route
                      path="pipeline/datasources"
                      element={<Datasources />}
                    />
                    <Route
                      path="pipeline/datasource/:id"
                      element={<DatasourceSettings />}
                    />
                    <Route path="pipeline/:id" element={<PipelineSettings />} />
                    <Route path="site" element={<Site />} />
                    <Route path="site/form/:id" element={<SiteForm />} />
                  </Routes>
                </Content>
              </Layout>
            </Layout>
          </Layout>
        </>
      ) : (
        <Routes>
          <Route path="/" element={<Login />}></Route>
          <Route path="login" element={<Login />} />
        </Routes>
      )}
    </Fragment>
  );
};

export default MainLayout;
