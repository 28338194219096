import { Layout, Menu } from "antd";
import {
  LaptopOutlined,
  LineChartOutlined,
  UserOutlined,
  ToolOutlined,
  IeOutlined,
  CodeOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import { checkPermission } from "../../helper/checkPermission";
import useAuthUser from "../../modules/context/AuthUserContext";
const { Sider } = Layout;
const SidebarMenu = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  return (
    <Sider width={200} className="site-layout-background">
      <Logo />
      <Menu
        mode="inline"
        defaultSelectedKeys={["1"]}
        defaultOpenKeys={["sub1"]}
        style={{ height: "100%", borderRight: 0 }}
      >
        <Menu.Item icon={<LaptopOutlined />} key="5">
          <Link to="/widgets">Widgets</Link>
        </Menu.Item>

        <Menu.SubMenu key="stats" title="Stats" icon={<LineChartOutlined />}>
          <Menu.Item key="overall">
            <Link to="/stats/overall">Overall</Link>
          </Menu.Item>
          <Menu.Item key="group">
            <Link to="/stats/group">Group</Link>
          </Menu.Item>
          <Menu.Item key="settings">
            <Link to="/stats/group/settings">Group Settings</Link>
          </Menu.Item>
        </Menu.SubMenu>

        {checkPermission(userRole, "user") && (
          <Menu.Item icon={<UserOutlined />} key="7">
            <Link to="/user">User</Link>
          </Menu.Item>
        )}
        {checkPermission(userRole, "site") && (
          <Menu.Item icon={<IeOutlined />} key="8">
            <Link to="/site">Site Management</Link>
          </Menu.Item>
        )}
        {checkPermission(userRole, "site") && (
          <Menu.SubMenu key="pipeline" title="Pipeline" icon={<CodeOutlined />}>
            <Menu.Item key="pipeline-datasource">
              <Link to="/pipeline/datasources">Datasources</Link>
            </Menu.Item>
            <Menu.Item key="pipeline-settings">
              <Link to="/pipeline">Pipeline</Link>
            </Menu.Item>
          </Menu.SubMenu>
        )}
        <Menu.SubMenu key="tools" title="Tools" icon={<ToolOutlined />}>
          <Menu.Item key="tools-cached">
            <Link to="/tools/cache">Cache</Link>
          </Menu.Item>
          <Menu.Item key="tools-search">
            <Link to="/tools/search">Search</Link>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Sider>
  );
};

export default SidebarMenu;
