import { useEffect, useState } from "react";
import Axios from "axios";
import { WidgetsContext, GroupWidgetContext } from "./context";
import FilterStats from "./Filter";
import WidgetsTable from "../../Components/Charts/WidgetsTable";
import { Col, Row } from "antd";
import useAppContext from "../../modules/context/AppContext";

const WidgetOverallStats = () => {
  const [widgets, setWidgets] = useState([]);
  const [groupWidget, setGroupWidget] = useState([]);
  const [filter, setFilter] = useState({});
  const { activeSite } = useAppContext();
  useEffect(() => {
    getWidgetList();
    getGroupWidgetList();
  }, [activeSite]);

  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/widgets`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );

    setWidgets(
      data.widgets.map((widget) => {
        widget.disabled = false;
        widget.key = widget.id;
        return widget;
      })
    );
  };

  const getGroupWidgetList = async () => {
    // const {
    //   data: { data },
    // } = await Axios.get(
    //   `${process.env.REACT_APP_API_URL}/v1.0/widget/group/list`,
    //   {
    //     withCredentials: true,
    //   }
    // );
    // setGroupWidget(data);
  };

  return (
    <WidgetsContext.Provider value={{ widgets }}>
      <GroupWidgetContext.Provider
        value={{ groupWidget: null, setGroupWidget }}
      >
        <FilterStats onFilterChange={setFilter} />
        <Row>
          <Col span={24}>
            <WidgetsTable
              title="Performance"
              from={filter.from}
              to={filter.to}
              widgets={widgets}
              compare_ids={["overall"]}
              devices={[]}
            />
          </Col>
        </Row>
      </GroupWidgetContext.Provider>
    </WidgetsContext.Provider>
  );
};

export default WidgetOverallStats;
