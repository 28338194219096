import { useEffect, useState } from "react";
import Axios from "axios";
import { GroupWidgetContext } from "./context";
import FilterStats from "./Filter";
import { Button, Modal, Table } from "antd";
import { Link } from "react-router-dom";
import useAppContext from "../../modules/context/AppContext";
const GroupList = () => {
  const [groupWidget, setGroupWidget] = useState([]);
  const [deletedGroup, setDeletedGroup] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getGroupWidgetList();
  }, [activeSite]);

  const getGroupWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/list`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    setGroupWidget(data);
  };

  const handleDeleteGroup = async (id) => {
    const {
      data: { data },
    } = await Axios.delete(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/delete/${id}`,
      { withCredentials: true }
    );
    setGroupWidget(groupWidget.filter((group) => group.id !== id));
  };

  const confirmDelete = (group) => {
    Modal.info({
      content: `Confirm Delete ${group.name}`,
      onOk: () => handleDeleteGroup(group.id),
    });
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Link to={`/stats/group/settings/${record.id}`}>{text}</Link>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Button onClick={() => confirmDelete(record)}>Delete</Button>
      ),
    },
  ];

  return (
    <GroupWidgetContext.Provider value={{ groupWidget }}>
      <Button type="primary" style={{ float: "right" }}>
        <Link to={`/stats/group/settings/new`}>+ New</Link>
      </Button>
      <Table columns={columns} dataSource={groupWidget} />
    </GroupWidgetContext.Provider>
  );
};

export default GroupList;
