import React, { createContext, useContext, useState } from "react";
import cookie from "react-cookies";
import Axios from "axios";
import jwt_decode from "jwt-decode";
export const UserContext = createContext({});
export const RECOMMEND_COOKIE = "recommend-token";
export default function useAuthUser() {
  return useContext(UserContext);
}
export const removeTokenUser = () =>
  cookie.remove(RECOMMEND_COOKIE, { path: "/" });
export const getTokenUser = () => cookie.load(RECOMMEND_COOKIE);
export const getDataPayloadJWT = () => {
  const token = getTokenUser();
  if (token) return jwt_decode(token);
  else return undefined;
};
export const UserContextProvider = ({ children }) => {
  const [user, setUser] = useState(getDataPayloadJWT());
  const [isLogin] = useState(false);
  const [errors, setErrors] = useState({ message: "" });
  const login = async (values) => {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_URL}/auth/login`,
      values
    );
    // console.log(res.data);
    if (res.data.status !== 200) {
      setErrors({
        message: res.data.message || "failed login",
      });
      return false;
    } else {
      setErrors({ message: "" });
      const LoginUser = res.data.data;
      const expires = new Date();
      expires.setDate(Date.now() + 1000 * 60 * 5);
      cookie.save(RECOMMEND_COOKIE, LoginUser.token, {
        path: "/",
        expires: expires,
        maxAge: 60 * 60 * 24,
      });
      setUser(getDataPayloadJWT());
      window.location.href = "/widgets";
      return true;
    }
  };

  const logout = async () => {
    try {
      removeTokenUser();
      return true;
    } catch (error) {
      // console.log("useAuth -> logout", error);
      return false;
    }
  };
  const store = {
    user,
    isLogin,
    login,
    logout,
    errors,
  };

  return <UserContext.Provider value={store}>{children}</UserContext.Provider>;
};
