import { useContext, useState, useEffect } from "react";
import { DatePicker, Select, Row, Col } from "antd";
import Axios from "axios";
import moment from "moment";
import WidgetImpByDate from "../../Components/Charts/WidgetImpByDate";
import WidgetImpByUserInfo from "../../Components/Charts/WidgetImpByUserInfo";
import WidgetClickPosByDate from "../../Components/Charts/WidgetClickPosByDate";
import { WidgetSettingsContext } from "./context";
import WidgetClickInfo from "../../Components/Charts/ClickInfo";
import GetRuleByWidget from "../../Components/Charts/RulesByWidget";
import ContentPerformance from "../../Components/Charts/ContentPerformance";

const { RangePicker } = DatePicker;
const { Option } = Select;
const StatsTab = () => {
  const { settingData } = useContext(WidgetSettingsContext);
  const defaultCompareIds = (
    settingData.ab && settingData.ab.length
      ? settingData.ab
      : [{ id: settingData.id }]
  ).map((item) => item.id);

  const [rangeDate, setRangeDate] = useState([
    moment().subtract(30, "days").startOf("day"),
    moment().endOf("day"),
  ]);
  const [overallData, setOverallData] = useState([]);
  const [compareIds, setCompareIds] = useState(defaultCompareIds);
  const [widgetList, setWidgetList] = useState([]);
  const [devices, setDevices] = useState([]);
  const [type, setType] = useState("last 30 day");
  useEffect(() => {
    clearData();
  }, [rangeDate[0], rangeDate[1], compareIds, devices]);

  useEffect(() => {
    getWidgetList();
  }, [settingData.id]);

  const clearData = () => {
    setOverallData([]);
  };
  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/list?all=true`,
      {
        withCredentials: true,
      }
    );
    setWidgetList(data.widgets);
  };

  const onSelectWidgetCompare = (value) => {
    setCompareIds(value);
  };
  const onSelectDevice = (value) => {
    setDevices(value);
  };

  const getDate = (dates, dateStrings) => {
    setRangeDate([dates[0].startOf("day"), dates[1].endOf("day")]);
    // console.log([dates[0].startOf("day"), dates[1].endOf("day")]);
  };

  const setRangeDateByType = (type) => {
    if (type === "today") {
      setRangeDate([moment().startOf("day"), moment().endOf("day")]);
    } else if (type === "this week") {
      setRangeDate([
        moment().subtract(6, "d").startOf("day"),
        moment().endOf("day"),
      ]);
    } else if (type === "this month") {
      setRangeDate([moment().startOf("month"), moment().endOf("month")]);
    } else if (type === "last month") {
      setRangeDate([
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ]);
    } else if (type === "last 30 days") {
      setRangeDate([
        moment().subtract(30, "days").startOf("day"),
        moment().endOf("day"),
      ]);
    }
    setType(type);
  };

  return (
    <div>
      <Row gutter={24} justify={"end"}>
        <Col>
          <Select
            mode="tags"
            placeholder="เลือก widget ที่ต้องการเปรียบเทียบ"
            value={compareIds}
            onChange={onSelectWidgetCompare}
            style={{ width: 300, marginLeft: 10 }}
          >
            {/* {widgetList.length>0&& */}
            <Select.Option value={"overall"}>{`Overall`}</Select.Option>
            {/* } */}
            {widgetList?.map((item) => (
              <Select.Option value={item.id}>{item.name}</Select.Option>
            ))}
          </Select>
          <Select
            mode="multiple"
            placeholder="เลือก Device"
            style={{ width: 300, marginLeft: 10 }}
            value={devices}
            onChange={onSelectDevice}
          >
            <Select.Option value={"desktop"}>{`Desktop`}</Select.Option>
            <Select.Option value={"mobile"}>{`Mobile`}</Select.Option>
            <Select.Option value={"tablet"}>{`Tablet`}</Select.Option>
            <Select.Option value={"smarttv"}>{`Smart TV`}</Select.Option>
          </Select>
        </Col>
        <Col>
          <RangePicker
            defaultValue={rangeDate}
            value={rangeDate}
            onChange={getDate}
          />
        </Col>
        <Col>
          <Select
            value={type}
            onChange={setRangeDateByType}
            style={{ width: 150 }}
          >
            <Option value="last 30 days">last 30 days</Option>
            <Option value="today">Today</Option>
            <Option value="this week">This Week</Option>
            <Option value="this month">This Month</Option>
            <Option value="last month">Last Month</Option>
          </Select>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <WidgetImpByDate
            overall={overallData}
            compare_ids={compareIds}
            id={settingData.id}
            title="Widget Performance"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
          />
        </Col>
        <Col span={24}>
          <WidgetImpByUserInfo
            compare_ids={compareIds}
            title="User Info"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
          />
        </Col>
        <Col span={24}>
          <WidgetClickPosByDate
            compare_ids={compareIds}
            id={settingData.id}
            title="Position Click"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
          />
        </Col>
        <Col span={24}>
          <GetRuleByWidget
            compare_ids={compareIds}
            id={settingData.id}
            title="Rules"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
          />
        </Col>

        <Col span={24}>
          <ContentPerformance
            compare_ids={compareIds}
            id={settingData.id}
            title="Contents Performance"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
            is_more={true}
          />
        </Col>
        <Col span={24}>
          <WidgetClickInfo
            compare_ids={compareIds}
            id={settingData.id}
            title="Click Info"
            from={rangeDate[0]}
            to={rangeDate[1]}
            devices={devices}
            is_more={true}
          />
        </Col>
      </Row>
    </div>
  );
};

export default StatsTab;
