import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Typography,
  Collapse,
  Descriptions,
  Badge,
  Divider,
  Form,
  Slider,
  Switch,
  Checkbox,
  Select,
  Radio,
  Tooltip,
} from "antd";
import _set from "lodash/set";
import _get from "lodash/get";
import _sumBy from "lodash/sumBy";
import _uniqBy from "lodash/uniqBy";
import Axios from "axios";
import { WidgetSettingsContext } from "./context";

import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";

import {
  CloseOutlined,
  CheckOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
const { Option } = Select;
const { Paragraph, Title } = Typography;
const { Panel } = Collapse;
const CheckboxGroup = Checkbox.Group;

const HistoryLog = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [history_data, setHistoryData] = useState({});

  const {
    handleChangeSettings,
    settingData: { relate_article },
  } = useContext(WidgetSettingsContext);

  useEffect(() => {
    if (!checkPermission(userRole, "widget_action")) {
      window.location.href = "/widgets";
    }
    getHistoryInfo();
  }, [id]);

  const getHistoryInfo = async () => {
    if (id !== "new") {
      const {
        data: { data },
      } = await Axios.get(
        `${process.env.REACT_APP_API_URL}/v1.0/widget/widget_history/list/${id}`,
        {
          withCredentials: true,
        }
      );

      const conv_data = converDateTime(data);

      setHistoryData(conv_data);
    }
    setLoading(false);
  };

  const converDateTime = (data) => {
    data.map((items) => {
      items.created_at = new Date(items.created_at).toLocaleString();
    });

    return data;
  };

  return (
    <Row>
      <Col span={18} offset={3}>
        <Title level={4}>รายการบันทึกย้อนหลัง</Title>
        <Card>
          {history_data.length > 0 ? (
            history_data.map((items, index) => (
              <Collapse defaultActiveKey={index}>
                <Panel header={`${items.created_at} | ${items._id}`} key="2">
                  <Paragraph>
                    <Divider
                      orientation="left"
                      plain
                    >{`Widget name :  ${items.log[0].name}`}</Divider>
                    {items.log[0].settings.map((item, index) => (
                      <>
                        <Title level={4}>{`Engine :  ${item.engine}`}</Title>
                        {item.engine === "es" ? (
                          <>
                            {item.search_context ? (
                              <>
                                <Divider dashed orientation="left">
                                  <Title level={4}>
                                    ตั้งค่าค้นหาด้วยคำค้นหา
                                  </Title>
                                </Divider>
                                <Form.Item
                                  label="เปิดใช้งาน"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    disabled
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    key="text-search"
                                    checked={
                                      item.search_context.enabled || false
                                    }
                                  />
                                </Form.Item>
                                {item.search_context.enabled && (
                                  <div
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: 5,
                                      borderRadius: 5,
                                    }}
                                  >
                                    <Form.Item label="ค้นหาใน">
                                      <CheckboxGroup
                                        options={[
                                          "หัวข่าว",
                                          "คำโปรย",
                                          "เนื้อหา",
                                          "แท็ก",
                                        ]}
                                        value={item.search_context.search_in}
                                      />
                                    </Form.Item>
                                    {relate_article && (
                                      <Form.Item label="ใช้คำค้นจาก">
                                        <CheckboxGroup
                                          options={["หัวข้อ", "คำโปรย"]}
                                          value={
                                            item.search_context.search_from
                                          }
                                        />
                                      </Form.Item>
                                    )}
                                    <Form.Item label="เจาะจงคำค้นหา">
                                      <Select
                                        mode="tags"
                                        placeholder="ระบุ keyword (optional)"
                                        value={item.search_context.keywords}
                                      />
                                    </Form.Item>
                                    <Form.Item label="exclude คำ">
                                      <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="ระบุ keyword (optional)"
                                        value={
                                          item.search_context.exclude_keywords
                                        }
                                      />
                                    </Form.Item>
                                  </div>
                                )}
                                <Divider dashed orientation="left">
                                  <Title level={4}>ตั้งค่าค้นหาด้วยแท็ก</Title>
                                </Divider>
                                <Form.Item
                                  label="เปิดใช้งาน"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    disabled
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    key="text-search"
                                    checked={item.search_tags.enabled || false}
                                  />
                                </Form.Item>
                                {item.search_tags.enabled && (
                                  <div
                                    style={{
                                      border: "1px solid #ddd",
                                      padding: 5,
                                      borderRadius: 5,
                                    }}
                                  >
                                    {relate_article && (
                                      <Form.Item label="ใช้คำค้นจากข่าว">
                                        <Radio.Group
                                          value={item.search_tags.type}
                                        >
                                          <Radio value={"none"}>ไม่เลือก</Radio>
                                          <Radio value={"all"}>
                                            แท็กทั้งหมด
                                          </Radio>
                                          <Radio value={"some_tags"}>
                                            บางแท็ก
                                          </Radio>
                                        </Radio.Group>
                                        {_get(
                                          item,
                                          "search_tags.type",
                                          []
                                        ).includes("some_tags") && (
                                          <CheckboxGroup
                                            options={[
                                              "Tag 1",
                                              "Tag 2",
                                              "Tag 3",
                                              "Tag 4",
                                              "Tag ...n",
                                            ]}
                                            value={
                                              item.search_tags.article_includes
                                            }
                                          />
                                        )}
                                      </Form.Item>
                                    )}

                                    {relate_article && (
                                      <Form.Item label="exclude แท็กจากข่าว">
                                        <CheckboxGroup
                                          options={[
                                            "Tag 1",
                                            "Tag 2",
                                            "Tag 3",
                                            "Tag 4",
                                            "Tag 5",
                                            "Tag 6",
                                            "Tag 7",
                                            "Tag 8",
                                            "Tag 9",
                                            "Tag 10",
                                            "Tag ...n",
                                          ]}
                                          value={
                                            item.search_tags.article_excludes
                                          }
                                        />
                                      </Form.Item>
                                    )}

                                    <Form.Item label="include">
                                      <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="tag"
                                        value={item.search_tags.includes}
                                      ></Select>
                                    </Form.Item>

                                    <Form.Item label="exclude">
                                      <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="tag"
                                        value={item.search_tags.excludes}
                                      ></Select>
                                    </Form.Item>

                                    <Form.Item label="popular tags จาก">
                                      <Select
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        value={
                                          item.search_tags.popular_tags_section
                                        }
                                      >
                                        {[
                                          "home",
                                          "news",
                                          "business",
                                          "entertain",
                                        ].map((item) => (
                                          <Option key={item}>{item}</Option>
                                        ))}
                                      </Select>
                                      {_get(
                                        item,
                                        "search_tags.popular_tags_section",
                                        []
                                      ).length > 0 && (
                                        <CheckboxGroup
                                          options={[
                                            "Tag 1",
                                            "Tag 2",
                                            "Tag 3",
                                            "Tag 4",
                                            "Tag 5",
                                            "Tag 6",
                                            "Tag 7",
                                            "Tag 8",
                                            "Tag 9",
                                            "Tag 10",
                                            "Tag ...n",
                                          ]}
                                          value={item.search_tags.popular_tags}
                                        />
                                      )}
                                      {/* {popularTag.map((item, i) => (
                                 <PopularTagExample key={i}>
                                 <div>popular tags หน้า {item.section} ปัจจุบัน</div>
                                 {item.tags.map((tag) => (
                                 <Tag>{tag}</Tag>
                                 ))}
                                 </PopularTagExample>
                                 ))} */}
                                    </Form.Item>
                                  </div>
                                )}
                                <Divider dashed orientation="left">
                                  <Title level={4}>ตั้งค่าหมวดหมู่ </Title>
                                </Divider>
                                <Form.Item
                                  label="AI"
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    disabled
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    key={"switch-ai"}
                                    checked={
                                      item.search_ai
                                        ? item.search_ai.enabled
                                        : false
                                    }
                                    defaultChecked
                                  />
                                </Form.Item>

                                {item.search_ai &&
                                item.search_ai.enabled === true ? (
                                  ""
                                ) : (
                                  <>
                                    {relate_article && (
                                      <Form.Item
                                        label="ใช้หมวดหมู่เดียวกับข่าว"
                                        style={{ marginBottom: 0 }}
                                      >
                                        <Switch
                                          disabled
                                          size="small"
                                          checkedChildren={<CheckOutlined />}
                                          unCheckedChildren={<CloseOutlined />}
                                          key={"switch-category"}
                                          checked={
                                            item.search_category.enabled ||
                                            false
                                          }
                                          defaultChecked
                                        />
                                      </Form.Item>
                                    )}
                                    <Form.Item label="include">
                                      <Select
                                        disabled
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="news/local/bangkok"
                                        value={item.search_category.includes}
                                      >
                                        {(
                                          item.search_category.includes || []
                                        ).map((item) => (
                                          <Option key={item}>{item}</Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                    <Form.Item label="exclude">
                                      <Select
                                        disabled
                                        mode="tags"
                                        style={{ width: "100%" }}
                                        placeholder="news/local/bangkok"
                                        value={item.search_category.excludes}
                                      >
                                        {(
                                          item.search_category.excludes || []
                                        ).map((item) => (
                                          <Option key={item}>{item}</Option>
                                        ))}
                                      </Select>
                                    </Form.Item>
                                  </>
                                )}

                                <Divider dashed orientation="left">
                                  <Title level={4}>นำ stats มาคำนวน</Title>
                                </Divider>
                                <Form.Item
                                  label={
                                    <Tooltip title="content ที่ถูกคลิ๊กมากที่สุด">
                                      Most Click ?
                                    </Tooltip>
                                  }
                                  style={{ marginBottom: 0 }}
                                >
                                  <Switch
                                    disabled
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    key={"switch-category"}
                                    checked={
                                      item.with_stats.most_click || false
                                    }
                                    defaultChecked
                                  />
                                </Form.Item>
                                {relate_article && (
                                  <Form.Item
                                    label={
                                      <Tooltip title="content ที่ถูกคลิ๊กมากที่สุด โดยจะ relate กับ content ที่ user กำลังอ่าน">
                                        Most Relate Click ?
                                      </Tooltip>
                                    }
                                    style={{ marginBottom: 0 }}
                                  >
                                    <Switch
                                      disabled
                                      size="small"
                                      checkedChildren={<CheckOutlined />}
                                      unCheckedChildren={<CloseOutlined />}
                                      key={"switch-category"}
                                      checked={
                                        item.with_stats.relate_click || false
                                      }
                                      defaultChecked
                                    />
                                  </Form.Item>
                                )}
                                <Divider dashed orientation="left">
                                  <Title level={4}>ตั้งค่าทั่วไป</Title>
                                </Divider>
                                <Form.Item label="ระบุเว็บไซต์">
                                  <Select
                                    mode="tags"
                                    style={{ width: "100%" }}
                                    value={item.site}
                                    disabled
                                  >
                                    <Option
                                      key="thairath-online"
                                      value="thairath-online"
                                    >
                                      Thairath Online
                                    </Option>
                                    <Option
                                      key="thairath-plus"
                                      value="thairath-plus"
                                    >
                                      Thairath Plus
                                    </Option>
                                    <Option key="money" value="money">
                                      Money
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item label="ระบุประเภท content">
                                  <Select
                                    mode="tags"
                                    style={{ width: "100%" }}
                                    value={item.contentType}
                                    disabled
                                  >
                                    <Option key="content" value="content">
                                      Article
                                    </Option>
                                    <Option key="video" value="video">
                                      Video Program
                                    </Option>
                                    <Option key="tv" value="tv">
                                      Video TV
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item label="Exclude content">
                                  <Select
                                    mode="tags"
                                    style={{ width: "100%" }}
                                    placeholder="3020123"
                                    value={item.exclude_content_id}
                                    disabled
                                  >
                                    {(item.exclude_content_id || []).map(
                                      (item) => (
                                        <Option key={item}>{item}</Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                                <Form.Item label="Age">
                                  <Row>
                                    <Col span={16}>
                                      <Slider
                                        disabled
                                        range
                                        min={0}
                                        max={120}
                                        value={item.age}
                                      />
                                    </Col>
                                    <Col
                                      span={4}
                                      style={{
                                        paddingTop: 5,
                                        textAlign: "right",
                                      }}
                                    >
                                      <span>
                                        {item.age[0]}-{item.age[1]} วัน
                                      </span>
                                    </Col>
                                  </Row>
                                </Form.Item>
                                <Form.Item label="จำนวนข่าวที่ค้นหา">
                                  <Row>
                                    <Col span={16}>
                                      <Slider
                                        disabled
                                        range
                                        min={1}
                                        max={100}
                                        value={item.limit}
                                      />
                                    </Col>
                                    <Col
                                      span={4}
                                      style={{
                                        paddingTop: 5,
                                        textAlign: "right",
                                      }}
                                    >
                                      <span>
                                        {item.limit[0]}-{item.limit[1]}
                                      </span>
                                    </Col>
                                  </Row>
                                </Form.Item>
                                <Form.Item label="จำนวนข่าวที่แสดงจริง">
                                  <Row>
                                    <Col span={16}>
                                      <Slider
                                        min={1}
                                        max={20}
                                        value={item.show_limit}
                                        disabled
                                      />
                                    </Col>
                                    <Col
                                      span={4}
                                      style={{
                                        paddingTop: 5,
                                        textAlign: "right",
                                      }}
                                    >
                                      <span>{item.show_limit}</span>
                                    </Col>
                                  </Row>
                                </Form.Item>
                                <Form.Item label="เรียงจาก">
                                  <Select
                                    mode="tags"
                                    style={{ width: "100%" }}
                                    value={item.sort}
                                  >
                                    <Option key="relevance" value="relevance">
                                      relevance
                                    </Option>
                                    <Option key="views" value="views">
                                      ยอดวิวสูงสุด (ยอดวิว delay 2 วัน)
                                    </Option>
                                    <Option
                                      key="daily_views"
                                      value="daily_views"
                                    >
                                      ยอดวิว 30 วัน (อัพเดททุกๆ 4-6 ชั่วโมง)
                                    </Option>
                                    <Option key="date" value="date">
                                      วันที่เผยแพร่ล่าสุด
                                    </Option>
                                  </Select>
                                </Form.Item>
                                <Form.Item label="สุ่มข่าว">
                                  <Switch
                                    disabled
                                    size="small"
                                    checkedChildren={<CheckOutlined />}
                                    unCheckedChildren={<CloseOutlined />}
                                    checked={item.random || false}
                                  />
                                </Form.Item>
                              </>
                            ) : (
                              "ไม่พบข้อมูลการแก้ไข"
                            )}
                          </>
                        ) : item.engine === "ai" ? (
                          <>
                            <Form.Item label="Age">
                              <Row>
                                <Col span={16}>
                                  <Slider
                                    disabled
                                    range
                                    min={0}
                                    max={120}
                                    defaultValue={item.age}
                                    onAfterChange={(value) => {
                                      // onFieldsChange("age", value, settings.index);
                                    }}
                                  />
                                </Col>
                                <Col
                                  span={4}
                                  style={{ paddingTop: 5, textAlign: "right" }}
                                >
                                  <span>
                                    {item.age[0]}-{item.age[1]} วัน
                                  </span>
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item label="จำนวนข่าวที่ค้นหา">
                              <Row>
                                <Col span={16}>
                                  <Slider
                                    disabled
                                    range
                                    min={1}
                                    max={100}
                                    defaultValue={item.limit}
                                  />
                                </Col>
                                <Col
                                  span={4}
                                  style={{ paddingTop: 5, textAlign: "right" }}
                                >
                                  <span>
                                    {item.limit[0]}-{item.limit[1]}
                                  </span>
                                </Col>
                              </Row>
                            </Form.Item>
                            <Form.Item label="จำนวนข่าวที่แสดงจริง">
                              <Row>
                                <Col span={16}>
                                  <Slider
                                    disabled
                                    min={1}
                                    max={20}
                                    defaultValue={item.show_limit}
                                  />
                                </Col>
                                <Col
                                  span={4}
                                  style={{ paddingTop: 5, textAlign: "right" }}
                                >
                                  <span>
                                    <span>{item.show_limit}</span>
                                  </span>
                                </Col>
                              </Row>
                            </Form.Item>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ))}
                  </Paragraph>
                </Panel>
              </Collapse>
            ))
          ) : (
            <div>ไม่มีข้อมูล</div>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default HistoryLog;
