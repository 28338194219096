import "antd/dist/antd.less";
import { BrowserRouter } from "react-router-dom";
import MainLayout from "Pages/Layouts/Main";
import { UserContextProvider } from "./modules/context/AuthUserContext";
import { AppContextProvider } from "./modules/context/AppContext";
const Index = () => {
  return (
    <BrowserRouter>
      <AppContextProvider>
        <UserContextProvider>
          <MainLayout />
        </UserContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  );
};

export default Index;
