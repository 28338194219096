import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import axios from "axios";
import _get from "lodash/get";
import _debounce from "lodash/debounce";
import { Col, Select, Row, Typography, Form, Tag, Button } from "antd";
import { WidgetSettingsContext } from "./context";
import useAppContext from "../../modules/context/AppContext";
const { Title } = Typography;

const ContentList = ({ settings }) => {
  const [contents, setContents] = useState([]);
  const [article, setArticle] = useState({});
  const [articleSearchResult, setArticleSearchResult] = useState([]);
  const [articleLoading, setArticleLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [isHideDesc, setIsHideDesc] = useState(true);
  const { settingData } = useContext(WidgetSettingsContext);
  const { activeSite } = useAppContext();
  useEffect(() => {
    queryContent(settings);
  }, [settings, article]);

  const _queryContent = async (settings) => {
    setPreviewLoading(true);
    const {
      data: { data },
    } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/query`,
      {
        ...settings,
        article_id: article.id,
      },
      {
        withCredentials: true,
      }
    );

    setContents(data.result.slice(0, data.result.length));
    setPreviewLoading(false);
  };

  const queryContent = useMemo(() => _debounce(_queryContent, 500), [article]);

  const _searchArticle = async (value) => {
    setArticleLoading(true);
    const {
      data: { data = {} },
    } = await axios.get(`${process.env.REACT_APP_API_URL}/v1.0/widget/search`, {
      withCredentials: true,
      params: {
        q: value,
        on_site: activeSite,
      },
    });
    setArticleSearchResult(data);
    setArticleLoading(false);
  };
  const searchArticle = useMemo(() => _debounce(_searchArticle, 500), []);

  const selectArticle = (value) => {
    const article = articleSearchResult.find(
      (item) =>
        // console.log("item",parseInt(item.id))
        parseInt(item.id) === parseInt(value)
    );
    setArticle(article);
    // queryContent({ ...settings, article_id: article.id });
  };

  return (
    <Row>
      <Col span={20} offset={2}>
        <div
          style={{
            position: "sticky",
            top: 0,
            overflow: "scroll",
          }}
        >
          <Title level={4}>
            Preview{" "}
            {settings.id && (
              <a
                href={`${process.env.REACT_APP_API_URL}/articles/preview/${
                  settings.id
                }/${article.id || ""}`}
                target="_blank"
              >
                ดูตัวอย่าง
              </a>
            )}
          </Title>
          {settingData.relate_article && (
            <Form.Item
              hasFeedback
              validateStatus={articleLoading ? "validating" : ""}
            >
              <Select
                filterOption={false}
                showSearch
                onSearch={searchArticle}
                onChange={selectArticle}
                placeholder="content id หรือคำค้นหา"
              >
                {articleSearchResult.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.title}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {article.id && (
            <>
              <img src={article.thumbnail} alt={article.title} width="200" />
              <Title level={5}>{article.title}</Title>
              <p>[{article.id}] {(article || "").sub_title}</p>
              <p>
                {_get(article, "tags", []).map((tag) => (
                  <Tag>{tag}</Tag>
                ))}
              </p>
              <span onClick={() => setIsHideDesc(!isHideDesc)}>
                เปิด/ปิด รายละเอียด
              </span>
              {!isHideDesc && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: article.description,
                  }}
                />
              )}
            </>
          )}
          <Form.Item
            hasFeedback
            validateStatus={articleLoading ? "validating" : ""}
          >
            <Button
              onClick={() => queryContent(settings)}
              loading={previewLoading}
            >
              Refresh
            </Button>
          </Form.Item>
          <Title level={4}>{settings.title}</Title>
          {(contents || []).map((item, i) => {
            return (
              <div
                style={{
                  height: 140,
                  width: 150,
                  float: "left",
                  margin: 10,
                  fontSize: 12,
                }}
                key={i}
              >
                <a href={item.canonical} target="_blank">
                  <img src={item.image} width="150" />
                  <br />
                  {item.ai ? `[${item.ai}]` : ""} {item.title}
                </a>
              </div>
            );
          })}
        </div>
      </Col>
    </Row>
  );
};

export default ContentList;
