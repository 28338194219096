import { useEffect, useState } from "react";
import axios from "axios";
import { Input, Select, Form, Button, Row, Col, Skeleton, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _set from "lodash/set";
import _get from "lodash/get";

const APISettings = ({ settings = {}, setSettings, pipelineData }) => {
  const [datasourceInfo, setDatasourceInfo] = useState({});
  useEffect(() => {
    if (pipelineData.datasource_id) {
      getDatasource();
    }
  }, [pipelineData.datasource_id]);

  const getDatasource = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources/${pipelineData.datasource_id}`,
      { withCredentials: true }
    );
    setDatasourceInfo(data.data);
  };

  const handleInput = (key, value) => {
    const newData = { ...settings };
    _set(newData, key, value);
    setSettings(newData);
  };

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Method">
          <Select
            style={{ width: 100 }}
            value={settings.method || "get"}
            onChange={(e) => {
              handleInput("method", e);
            }}
          >
            <Select.Option value="get">GET</Select.Option>
            <Select.Option value="post">POST</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="URI">
          <Space direction="vertical" style={{ width: "100%" }}>
            <Input
              style={{ width: "100%" }}
              addonBefore={_get(datasourceInfo, "settings.endpoint", "")}
              placeholder="https://your_api_domain"
              value={settings.uri}
              onChange={(e) => handleInput("uri", e.target.value)}
            />
          </Space>
        </Form.Item>
      </Form>
    </>
  );
};

export default APISettings;
