import { Fragment, useEffect, useState } from "react";
import {
  Skeleton,
  Typography,
  Card,
  Empty,
  Table,
  Row,
  Col,
  Select,
} from "antd";
import { Pie, G2 } from "@ant-design/plots";
import Axios from "axios";
import _get from "lodash/get";
import useAppContext from "../../modules/context/AppContext";

const { Title } = Typography;
const { Option } = Select;

const WidgetImpByUserInfo = ({
  from,
  to,
  title,
  compare_ids = [],
  type,
  devices,
  setDataDevice,
}) => {
  const [eventType, setEventType] = useState("imp_widget");
  const [data, setData] = useState(null);
  const [chartDataDevice, setChartDataDevice] = useState([]);
  const [chartDataOS, setChartDataOS] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();

  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      // getData();
    }, 62000); //62 วินาที เนื่องจาก es reindex ทุกๆ 60 วิ

    return () => clearInterval(interval);
  }, [from, to, compare_ids, devices]);

  const getData = async () => {
    setLoading(true);

    if (compare_ids.length <= 0) {
      return;
    }
    if (compare_ids.find((item) => item === "overall")) {
      return;
    }
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getImpByUserInfo`,
      { from, to, compare_ids, type, devices, on_site: activeSite },
      { withCredentials: true }
    );

    if (!(data.device.is_empty && data.os.is_empty)) {
      // setDataDevice(data.device);
      setData(data);
      setChartDataDevice(_get(data, `device.${eventType}`, []));
      setChartDataOS(_get(data, `os.${eventType}`, []));
    } else {
      setData(null);
    }

    setLoading(false);
  };
  const G = G2.getEngine("canvas");

  const configDefault = {
    appendPadding: 10,
    angleField: "doc_count",
    colorField: "key",
    radius: 0.75,
    // legend: false,
    legend: {
      layout: "horizontal",
      position: "bottom",
    },
    label: {
      type: "spider",
      // labelHeight: 28,
      // content: "{name} {value}\n({percentage})",
      labelHeight: 40,
      formatter: (data, mappingData) => {
        const group = new G.Group({});
        group.addShape({
          type: "circle",
          attrs: {
            x: 0,
            y: 0,
            width: 40,
            height: 50,
            r: 5,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 10,
            y: 8,
            text: `${data.key}`,
            fill: mappingData.color,
          },
        });
        group.addShape({
          type: "text",
          attrs: {
            x: 0,
            y: 25,
            text: `${Number(data.doc_count).toLocaleString()} (${(
              data.percent * 100
            ).toFixed(0)}%)`,
            fill: "rgba(0, 0, 0, 0.65)",
            fontWeight: 700,
          },
        });
        return group;
      },
    },
    interactions: [
      {
        type: "element-selected",
      },
      {
        type: "element-active",
      },
    ],
  };

  const configDevice = {
    ...configDefault,
    data: chartDataDevice,
  };

  const configOS = {
    ...configDefault,
    data: chartDataOS,
  };

  if (loading) {
    if (compare_ids.length <= 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  const setFilterEvent = (e) => {
    setEventType(e);

    setChartDataDevice(_get(data, `device.${e}`, []));
    setChartDataOS(_get(data, `os.${e}`, []));
  };

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Title level={4}>{title}</Title>
        {data ? (
          <>
            <Row style={{ "text-align": "right" }}>
              <Col span={24}>
                <Select
                  value={eventType}
                  onChange={setFilterEvent}
                  style={{ width: 150 }}
                >
                  <Option value="imp_widget">Widget Imp</Option>
                  <Option value="ctr_widget">Widget CTR</Option>
                  <Option value="click">Click</Option>
                </Select>
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <Title level={5} align="center">
                  Device
                </Title>
                <Pie {...configDevice} style={{ marginTop: 10 }} />
              </Col>
              <Col span={12}>
                <Title level={5} align="center">
                  OS
                </Title>
                <Pie {...configOS} style={{ marginTop: 10 }} />
              </Col>
            </Row>
          </>
        ) : (
          <Empty />
        )}
      </Card>
    </>
  );
};

export default WidgetImpByUserInfo;
