import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAuthUser from "../../modules/context/AuthUserContext";
import axios from "axios";
import { Table, Button, Row, Col,Modal } from "antd";
import { checkPermission } from "../../helper/checkPermission";
const Axios = axios.create({
  withCredentials: true,
});
const UserPage = () => {
  const [userList, setUserList] = useState([]);
  const { user } = useAuthUser();
  const [userDelete, setUserDelete ] = useState({ username: " " });
  const [deleteModal, setDeleteModal ] = useState(false);
  const userRole = user.role_name;
  useEffect(() => {
    getList();
  }, []);
  const getList = async (values) => {
    const res = await Axios.get(`${process.env.REACT_APP_API_URL}/auth/list`);

    if (res.data.status) {
      setUserList(res.data.data);
    }
  };

  const onDelete = async () => {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_URL}/auth/delete`,
      { username: userDelete.username }
    );

    if (res.data.status) {
      getList();
      setDeleteModal(false);
    }
  };
  const columns = [
    {
      title: "Username",
      key: "username",
      render: (text, obj) => (
        <Fragment>
          {checkPermission(userRole, "user_edit") ? (
            <Link to={`/user/${obj._id}`}>{obj.username} </Link>
          ) : (
            <div>{obj.username}</div>
          )}
        </Fragment>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, obj) => <div>{obj.email}</div>,
    },
    {
      title: "Role",
      key: "role_name",
      render: (text, obj) => <div>{obj.role_name}</div>,
    },
    {
      title: "",
      key: "delete",
      render: (text, obj) => (
        <Fragment>
          {checkPermission(userRole, "user_delete") &&
            obj.role_name !== "Super_Admin" && (
              <Row>
                <Col span={24}>
                  <Button
                    type="primary"
                    style={{ float: "right", marginBottom: 10 }}
                    onClick={() => {
                      setUserDelete({ username: obj.username });
                      setDeleteModal(true);
                    }}
                  >
                    ลบ
                  </Button>
                </Col>
              </Row>
            )}
        </Fragment>
      ),
    },
  ];
  return (
    <Fragment>
      <div style={{ margin: "10px auto" }}>
        <h2>User </h2>
        {checkPermission(userRole, "user_create") && (
          <Row>
            <Col span={24}>
              <Button
                type="primary"
                style={{ float: "right", marginBottom: 10 }}
              >
                <Link to="/user/new">เพิ่ม</Link>
              </Button>
            </Col>
          </Row>
        )}
        <Table dataSource={userList} columns={columns} pagination={false} />
        <Modal
          title={`Delete`}
          visible={deleteModal}
          onOk={() => onDelete()}
          onCancel={() => setDeleteModal(false)}
        >
          <h2>{`ต้องการลบ "${userDelete?.username}" นี้หรือไม่?`}</h2>
        </Modal>
      </div>
    </Fragment>
  );
};

export default UserPage;
