import { useEffect, useState } from "react";
import { Skeleton, Typography, Card, Empty } from "antd";
import { Column } from "@ant-design/charts";
import Axios from "axios";
import useAppContext from "../../modules/context/AppContext";

const { Title } = Typography;

const GroupClickPosition = ({
  ids = [],
  from = null,
  to = null,
  title,
  devices = [],
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();
  useEffect(() => {
    if (from && to) {
      getData();
    }
  }, [from, to, ids, devices]);

  const getData = async () => {
    setLoading(true);
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getGroupClickPosition`,
      { from, to, ids, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );

    const _data = [];
    // format series for chart
    data.forEach((item) => {
      // format data
      item.data.forEach((_item) => {
        _data.push({
          id: item.name,
          ..._item,
        });
      });
    });
    setData(_data);
    setLoading(false);
  };

  const config = {
    data,
    height: 400,
    xField: "position",
    yField: "count",
    seriesField: "id",
    isGroup: true,
    point: {
      size: 5,
      shape: "diamond",
    },
  };
  if (loading) {
    if (ids.length <= 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <Title level={4}>{title}</Title>
      {data.length > 0 ? (
        <Column {...config} style={{ marginTop: 10 }} />
      ) : (
        <Empty />
      )}
    </Card>
  );
};

export default GroupClickPosition;
