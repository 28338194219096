import { useEffect, useState } from "react";
import { Skeleton, Typography, Card, Empty, Table, Row, Col } from "antd";
import Axios from "axios";
import ImpInfo from "./ImpInfo";
import useAppContext from "../../modules/context/AppContext";
const { Title } = Typography;

const WidgetClickInfo = ({
  id,
  from = null,
  to = null,
  title,
  compare_ids = [],
  content_id = null,
  devices,
  is_more = false,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getData();
    console.log(from, to, compare_ids, content_id, devices);
    const interval = setInterval(() => {
      getData();
    }, 62000);
    return () => clearInterval(interval);
  }, []);

  const getData = async () => {
    setLoading(true);

    if (compare_ids.length <= 0 && !id) {
      return;
    }
    if (compare_ids.find((item) => item === "overall")) {
      return;
    }
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getClickInfo/${id}`,
      { from, to, compare_ids, content_id, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );
    setData(data);
    setLoading(false);
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnail",
      key: "thumbnail",
      render: (value, record) => <img src={value || record.image} style={{ height: "50px" }} />,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      render: (text, record) => {
        return <a href={record.canonical}>{text}</a>;
      },
    },
    {
      title: "Count",
      dataIndex: "count",
      key: "count",
    },
  ];

  if (loading) {
    if (compare_ids.length <= 0 && !id) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  const expandable = !is_more
    ? {}
    : {
        expandedRowRender: (record) => {
          // return "xxx"
          return (
            <ImpInfo
              key={"sub-ImpInfo-" + record.id}
              content_id={record.id}
              from={from}
              to={to}
              id={id}
              title={
                <span>
                  ข่าว "<u>{record.title}</u>" ถูกคลิ๊กจากข่าวด้านล่าง
                </span>
              }
              devices={devices}
            />
          );
        },
      };

  return (
    <Card style={{ marginTop: 10 }}>
      <Title level={4}>{title}</Title>
      {data.map((widget) => (
        <>
          <Title level={5}>{widget.name}</Title>
          <Table
            dataSource={widget.data}
            columns={columns}
            rowKey={"id"}
            expandable={expandable}
          />
        </>
      ))}
    </Card>
  );
};

export default WidgetClickInfo;
