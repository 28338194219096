import React from "react";

function LogoThairath(props) {
  return (
    <svg width="109.913" height="39.01" viewBox="0 0 109.913 39.01" {...props}>
      <g data-name="Group 496" transform="translate(-13.852 -16.876)">
        <g data-name="Group 493" transform="translate(13.852 16.876)">
          <g data-name="Group 491-1" transform="translate(47.779 1.904)">
            <path
              data-name="Path 7780"
              d="M931.225,321.579h-3.309V306.691c0-2.169,2.169-2.758,2.169-4.412a.651.651,0,0,0-.7-.736h-4.228v-2.831h4.669c3.567,0,3.567,1.949,3.567,3.383,0,2.5-2.169,3.089-2.169,4.6Z"
              transform="translate(-925.158 -298.712)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7781"
              d="M945.658,323.692v-9.707c0-1.4-.882-2.1-2.39-2.1-1.691,0-2.942.735-2.942,2.1v9.707h-3.309V308.985h3.309v1.765a3.6,3.6,0,0,1,3.457-1.949c3.677,0,5.184,2.059,5.184,5.185v9.707Z"
              transform="translate(-927.642 -300.825)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7782"
              d="M967.719,309.034v11.435c0,2.537-1.949,3.456-4.375,3.456h-3.383c-3.419,0-4.523-2.1-4.523-4.78A3.943,3.943,0,0,1,956.8,316.2a3.746,3.746,0,0,1-1.508-3.015c0-2.5,1.4-4.154,3.861-4.154h3.236v2.831h-1.949c-1.361,0-1.839.257-1.839,1.323a1.479,1.479,0,0,0,1.654,1.47h2.133v2.832h-2.022a1.553,1.553,0,0,0-1.618,1.655c0,.919.111,1.949,1.361,1.949h3.162c.735-.074,1.14-.294,1.14-.957v-11.1Z"
              transform="translate(-931.469 -300.874)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7783"
              d="M984.485,319.244c0,3.125-2.647,4.633-5.331,4.633-1.508,0-4.082-.368-5.589-2.28l1.839-1.949a5.608,5.608,0,0,0,3.713,1.4,1.992,1.992,0,0,0,2.059-1.8c0-1.949-7.06-1.434-7.06-5.957,0-2.978,1.8-4.486,5.038-4.486a10.534,10.534,0,0,1,4.89,1.176l-1.139,2.648a9.685,9.685,0,0,0-3.75-.735c-1.067,0-1.728.4-1.728,1.4C977.426,314.978,984.485,314.868,984.485,319.244Z"
              transform="translate(-935.296 -300.825)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7784"
              d="M976.811,304.149v-4.375h3.309v1.544h5.993v2.831Z"
              transform="translate(-935.976 -298.934)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7785"
              d="M997.316,312.716c0-1.176-1.25-1.323-2.573-1.323-1.4,0-2.611.147-2.611,1.323v1.214h-3.309v-1.214c0-3.162,2.243-4.412,5.92-4.412a3.953,3.953,0,0,1,.662.037l4.3-1.912.92,2.463-1.434.552a3.941,3.941,0,0,1,1.434,3.272v6.03c0,2.537-1.617,4.448-4.118,4.448h-3.861l-.845-4.633h-1.545v-2.831h4.449l.735,4.633h1.029c.663-.036.772-.625.846-1.36Zm3.31,11.362v.882c0,2.61-1.4,3.346-3.9,3.346h-6.1v-2.831h6.1c.331,0,.552-.037.552-.515v-.882Z"
              transform="translate(-938.492 -300.328)"
              fill="#00b400"
            ></path>
          </g>
          <g data-name="Group 492" transform="translate(0 0)">
            <path
              data-name="Path 7786"
              d="M894.807,291.306a2.112,2.112,0,0,0-1.59-.881H862.983a2.11,2.11,0,0,1-1.588-.883l-.5-.791a.956.956,0,0,0-1.388-.263l-2.307,1.691a1.127,1.127,0,0,0-.28,1.486l1.582,2.5a2.1,2.1,0,0,0,1.59.874h30.287a2.107,2.107,0,0,1,1.591.874l2.025,3.184a.957.957,0,0,0,1.39.26l2.261-1.662a1.125,1.125,0,0,0,.279-1.486Z"
              transform="translate(-856.752 -288.296)"
              fill="#00b400"
            ></path>
            <path
              data-name="Path 7787"
              d="M871.3,331.1a1.589,1.589,0,0,0,1.389-.972l.967-2.658a.679.679,0,0,0-.681-.973h-.989a.679.679,0,0,1-.681-.973l6.017-16.444a4.015,4.015,0,0,1,1.189-1.585l.394-.288a3.714,3.714,0,0,1,1.871-.612h3.648a2.109,2.109,0,0,1,1.592.873l.593.929a.959.959,0,0,0,1.391.26l4.236-3.105a1.125,1.125,0,0,0,.279-1.486l-1.736-2.73a2.108,2.108,0,0,0-1.591-.873H876.864a3.7,3.7,0,0,0-1.869.613l-4.585,3.368a4.021,4.021,0,0,0-1.188,1.586l-7.126,19.49a1.59,1.59,0,0,1-1.389.973h-1.535a1.316,1.316,0,0,0-.431.077,1.6,1.6,0,0,0-.962.9l-.967,2.659a.68.68,0,0,0,.681.974Z"
              transform="translate(-856.752 -292.19)"
              fill="#00b400"
            ></path>
          </g>
          <path
            data-name="Path 7788"
            d="M935.181,340.674c0,1.882-1.22,2.729-3.246,2.729-2.254,0-3.246-1.034-3.246-2.729v-1.8H931v1.2h-.971v.6c0,1.116.868,1.53,1.9,1.53s1.9-.413,1.9-1.53v-3.412c0-1.034-.888-1.137-1.9-1.137s-1.9.1-1.9,1.137v.6h-1.344v-.6c0-1.674,1.24-2.336,3.246-2.336s3.246.662,3.246,2.336Z"
            transform="translate(-878.118 -304.393)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7789"
            d="M945.25,340.674c0,1.882-1.22,2.729-3.246,2.729-2.254,0-3.246-1.034-3.246-2.729v-1.8h2.316v1.2H940.1v.6c0,1.116.869,1.53,1.9,1.53s1.9-.413,1.9-1.53v-3.412c0-1.034-.889-1.137-1.9-1.137s-1.9.1-1.9,1.137v.6h-1.344v-.6c0-1.674,1.24-2.336,3.246-2.336s3.246.662,3.246,2.336Z"
            transform="translate(-880.227 -304.393)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7790"
            d="M950.25,335.056v5.562c0,1.075.682,1.613,1.778,1.613,1.22,0,2.15-.6,2.15-1.613v-5.562h1.344v8.271h-1.344v-1.013a2.333,2.333,0,0,1-2.233,1.116c-2.191,0-3.039-1.116-3.039-2.812v-5.562Z"
            transform="translate(-882.352 -304.42)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7791"
            d="M960.656,342.377h-1.344v-7.411c0-1.448,1.323-1.716,1.323-2.647,0-.187,0-.6-.476-.6h-2.832v-1.2h2.832c1.8,0,1.82.972,1.82,1.7,0,1.489-1.323,1.531-1.323,2.75Z"
            transform="translate(-884.116 -303.47)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7792"
            d="M966.524,338.5a7.631,7.631,0,0,1,2.253.228v-1.468c0-1.034-.889-1.137-1.9-1.137s-1.9.1-1.9,1.137v.413h-1.344v-.413c0-1.674,1.241-2.336,3.246-2.336s3.246.662,3.246,2.336V343.3h-1.344v-2.853c0-.745-1.365-.745-2.233-.745-.91,0-1.8.187-1.8,1.075,0,1.117,0,1.323,1.489,1.323h.29v1.2h-.682c-2.3,0-2.316-1.24-2.316-2.481C963.525,339.1,964.683,338.5,966.524,338.5Z"
            transform="translate(-885.414 -304.393)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7793"
            d="M975.226,335.056v5.562c0,1.075.682,1.613,1.778,1.613,1.221,0,2.151-.6,2.151-1.613v-5.562H980.5v8.271h-1.344v-1.013a2.334,2.334,0,0,1-2.233,1.116c-2.192,0-3.04-1.116-3.04-2.812v-5.562Z"
            transform="translate(-887.583 -304.42)"
            fill="#00b400"
          ></path>
          <path
            data-name="Path 7794"
            d="M979.007,330.521h3.515v1.1h-2.315v.786h-1.2Z"
            transform="translate(-888.656 -303.47)"
            fill="#00b400"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default LogoThairath;
