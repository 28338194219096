import { Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";

const GA3Datasource = ({ settings = {}, callbackSettings }) => {
  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Client Email">
          <Input
            value={settings.client_email}
            onChange={(e) =>
              callbackSettings("settings.client_email", e.target.value)
            }
          />
        </Form.Item>
        <Form.Item label="Private Key">
          <TextArea
            value={settings.private_key}
            rows={10}
            onChange={(e) =>
              callbackSettings("settings.private_key", e.target.value || "")
            }
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default GA3Datasource;
