import { useEffect, useState } from "react";
import axios from "axios";
import { Input, Select, Form, Button, Row, Col, Skeleton, Space } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _set from "lodash/set";

const GA4Settings = ({ settings = {}, setSettings }) => {
  const handleInput = (key, value) => {
    const newData = { ...settings };
    _set(newData, key, value);
    setSettings(newData);
  };

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Query">
          <TextArea
            type="area"
            rows={4}
            value={settings.query}
            onChange={(e) => handleInput("query", e.target.value)}
          />
          <div>
            using "runReport" function. Generate from this link (
            <a
              href="https://ga-dev-tools.google/ga4/query-explorer/"
              target="_blank"
              rel="noreferrer"
            >
              https://ga-dev-tools.google/ga4/query-explorer/
            </a>
            )
          </div>
        </Form.Item>
      </Form>
    </>
  );
};

export default GA4Settings;
