import { useEffect, useState } from "react";
import Axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { WidgetsContext, GroupWidgetContext } from "./context";
import GroupSettings from "./GroupSettings";
import { Button, Modal } from "antd";
import useAppContext from "../../modules/context/AppContext";

const GroupEdit = () => {
  const [widgets, setWidgets] = useState([]);
  const [groupWidget, setGroupWidget] = useState([]);
  const [group, setGroup] = useState({
    name: "new group",
    widgets: [],
  });
  const { id } = useParams();
  const { activeSite } = useAppContext();

  const navigate = useNavigate();

  useEffect(() => {
    getGroupWidgetById(id);
  }, [id]);

  useEffect(() => {
    getWidgetList();
    getGroupWidgetList();
  }, []);

  const getGroupWidgetById = async (id) => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/get/${id}?on_site=${activeSite}`,
      { withCredentials: true }
    );
    setGroup(data);
  };

  const getWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/widgets?on_site=${activeSite}`,
      { withCredentials: true }
    );
    setWidgets(
      data.widgets.map((widget) => {
        widget.disabled = false;
        widget.key = widget.id;
        return widget;
      })
    );
  };

  const getGroupWidgetList = async () => {
    const {
      data: { data },
    } = await Axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/list`,
      { withCredentials: true }
    );
    setGroupWidget(data.group);
  };

  const saveGroup = async () => {
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/save/${id}`,
      { ...group, on_site: activeSite },
      { withCredentials: true }
    );
    Modal.success({
      content: "Done.",
    });
  };

  const handleOnGroupChange = (group) => {
    setGroup(group);
  };

  return (
    <WidgetsContext.Provider value={{ widgets }}>
      <GroupWidgetContext.Provider value={{ groupWidget }}>
        <Button onClick={() => navigate(-1)}>Back</Button>
        <Button onClick={saveGroup} type="primary" style={{ float: "right" }}>
          Save
        </Button>
        <GroupSettings onGroupChange={handleOnGroupChange} group={group} />
      </GroupWidgetContext.Provider>
    </WidgetsContext.Provider>
  );
};

export default GroupEdit;
