import { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Select,
  DatePicker,
  Divider,
  Space,
  Input,
  Button,
  Modal,
} from "antd";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import GroupSettings from "./GroupSettings";
import Axios from "axios";
import { GroupWidgetContext } from "./context";
import useAppContext from "../../modules/context/AppContext";
const { Option } = Select;
const { RangePicker } = DatePicker;

const FilterStats = ({ onFilterChange }) => {
  const { groupWidget = [], setGroupWidget } = useContext(GroupWidgetContext);
  const { activeSite } = useAppContext();
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [rangeDate, setRangeDate] = useState([
    moment().subtract(30, "days").startOf("day"),
    moment().endOf("day"),
  ]);
  const [type, setType] = useState("last 30 day");
  const [addNewGroupModal, setAddNewGroupModal] = useState(false);
  const [group, setGroup] = useState({});
  const [filter, setFilter] = useState({});
  const [devices, setDevices] = useState([]);
  useEffect(() => {
    onFilterChange({
      group: selectedGroup,
      from: rangeDate[0],
      to: rangeDate[1],
      devices,
    });
  }, [rangeDate, selectedGroup, devices]);

  const onSelectGroupWidgetCompare = (value) => {
    setSelectedGroup(value);
  };
  const setRangeDateByType = (type) => {
    if (type === "today") {
      setRangeDate([moment().startOf("day"), moment().endOf("day")]);
    } else if (type === "this week") {
      setRangeDate([
        moment().subtract(6, "d").startOf("day"),
        moment().endOf("day"),
      ]);
    } else if (type === "this month") {
      setRangeDate([moment().startOf("month"), moment().endOf("month")]);
    } else if (type === "last month") {
      setRangeDate([
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ]);
    } else if (type === "last 30 days") {
      setRangeDate([
        moment().subtract(30, "days").startOf("day"),
        moment().endOf("day"),
      ]);
    }
    setType(type);
  };

  const getDate = (dates, dateStrings) => {
    const _rangeDate = [dates[0].startOf("day"), dates[1].endOf("day")];
    setRangeDate(_rangeDate);
  };

  const handleOnGroupChange = (val) => {
    setGroup(val);
  };

  const saveGroup = async () => {
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/group/save/new`,
      { ...group, on_site: activeSite },
      { withCredentials: true }
    );
    setAddNewGroupModal(false);
    setGroupWidget(groupWidget.concat(data));
  };

  const onSelectDevice = (value) => {
    setDevices(value);
  };

  return (
    <>
      <Row gutter={24} justify={"end"}>
        {groupWidget && (
          <Col>
            <Select
              mode="tags"
              placeholder="เลือก group widget ที่ต้องการเปรียบเทียบ"
              value={selectedGroup}
              onChange={onSelectGroupWidgetCompare}
              style={{ width: 300, marginLeft: 10, float: "right" }}
              dropdownRender={(menu) => (
                <>
                  {menu}
                  <Divider style={{ margin: "8px 0" }} />
                  <Space style={{ padding: "0 8px 4px" }}>
                    <Button
                      type="text"
                      icon={<PlusOutlined />}
                      onClick={() => setAddNewGroupModal(true)}
                    >
                      เพิ่ม Group Widget
                    </Button>
                  </Space>
                </>
              )}
            >
              {groupWidget.map((item) => (
                <Select.Option key={item.id} value={item.id}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Col>
        )}
        {groupWidget && (
          <Col>
            <Select
              mode="multiple"
              placeholder="เลือก Device"
              style={{ width: 300, marginLeft: 10 }}
              value={devices}
              onChange={onSelectDevice}
            >
              <Select.Option value={"desktop"}>{`Desktop`}</Select.Option>
              <Select.Option value={"mobile"}>{`Mobile`}</Select.Option>
              <Select.Option value={"tablet"}>{`Tablet`}</Select.Option>
              <Select.Option value={"smarttv"}>{`Smart TV`}</Select.Option>
            </Select>
          </Col>
        )}
        <Col>
          <RangePicker
            defaultValue={rangeDate}
            value={rangeDate}
            onChange={getDate}
          />
        </Col>
        <Col>
          <Select
            value={type}
            onChange={setRangeDateByType}
            style={{ width: 150 }}
          >
            <Option value="last 30 days">last 30 days</Option>
            <Option value="today">Today</Option>
            <Option value="this week">This Week</Option>
            <Option value="this month">This Month</Option>
            <Option value="last month">Last Month</Option>
          </Select>
        </Col>
      </Row>
      <Modal
        title="Add/Edit Widget Group"
        visible={addNewGroupModal}
        onCancel={() => setAddNewGroupModal(false)}
        onOk={saveGroup}
        width={1000}
      >
        <GroupSettings onGroupChange={handleOnGroupChange} group={group} />
      </Modal>
    </>
  );
};

export default FilterStats;
