import { useEffect, useState } from "react";
import { Skeleton, Typography, Card, Empty } from "antd";
import { Column } from "@ant-design/charts";
import Axios from "axios";
import useAppContext from "../../modules/context/AppContext";

const { Title } = Typography;

const WidgetClickPosByDate = ({
  id,
  from = null,
  to = null,
  title,
  compare_ids = [],
  devices,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getData();

    const interval = setInterval(() => {
      getData();
    }, 62000);
    return () => clearInterval(interval);
  }, [from, to, compare_ids, devices]);

  const getData = async () => {
    setLoading(true);

    if (compare_ids.length <= 0 && !id) {
      return;
    }
    if (compare_ids.find((item) => item === "overall")) {
      return;
    }
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getRulesByWidget/${id}`,
      { from, to, compare_ids, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );
    setData(data);
    setLoading(false);
  };

  const config = {
    data: data,
    height: 400,
    xField: "rule",
    yField: "count",
    seriesField: "type",
    groupField: "name",
    isGroup: true,
    legend: {
      selected: {
        imp: false,
        click: true,
      },
    },
  };

  if (loading) {
    if (compare_ids.length <= 0 && !id) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <Title level={4}>{title}</Title>
      {data.length > 0 ? (
        <Column {...config} style={{ marginTop: 10 }} />
      ) : (
        <Empty />
      )}
    </Card>
  );
};

export default WidgetClickPosByDate;
