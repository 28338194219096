import React, { useState } from "react";
import { Form, Input } from "antd";
import _set from "lodash/set";

const GA4Datasource = ({ settings, callbackSettings }) => {
  const onChange = (key, val) => {
    const newSettings = settings;
    _set(newSettings, key, val);

    callbackSettings(newSettings);
  };
  return (
    <>
      <Form.Item label="PROPERTY ID">
        <Input
          value={settings.property_id}
          onChange={(e) => onChange("property_id", e.target.value)}
        />
        <sub>Go to GA4 Admin &gt; property &gt; Property Detail</sub>
      </Form.Item>
      <Form.Item label="client_email">
        <Input
          value={settings.client_email}
          placeholder="example@gmail.com"
          onChange={(e) => onChange("client_email", e.target.value)}
        />
        <sub>
          Don't forget to add permission for this email to Gogogle Analytics
          account. Go to GA4 Admin &gt; Account &gt; Account Access Management
        </sub>
      </Form.Item>
      <Form.Item label="private_key">
        <Input.TextArea
          style={{ height: 400 }}
          value={settings.private_key}
          placeholder="-----BEGIN"
          onChange={(e) => {
            e.preventDefault();
            onChange("private_key", e.target.value);
          }}
        />
      </Form.Item>
    </>
  );
};

export default GA4Datasource;
