import { Table } from "antd";
import { useEffect, useState } from "react";

const StandardResult = ({ data, settings, callbackSelectField }) => {
  const [selectedField, setSelectedField] = useState(settings.index_data || 0);

  useEffect(() => {
    callbackSelectField(selectedField);
  }, [selectedField]);

  const columns = data.columnHeaders.map((v, i) => {
    return {
      title: () => {
        return (
          <label>
            <input
              type="radio"
              value={i}
              name="index_data"
              onChange={(e) => {
                setSelectedField(e.target.value);
                console.log(e.target.value);
              }}
            />{" "}
            {v.name}
          </label>
        );
      },
      dataIndex: i,
      key: v.name,
    };
  });

  return (
    <>
      <Table columns={columns} dataSource={data.rows} />
    </>
  );
};

export default StandardResult;
