import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-css";
import "ace-builds/src-noconflict/mode-ejs";
import "ace-builds/src-noconflict/theme-github";
import axios from "axios";
import {
  Button,
  Empty,
  Input,
  Select,
  Space,
  Form,
  Skeleton,
  Modal,
  Divider,
} from "antd";
import _set from "lodash/set";
import _get from "lodash/get";
import StandardResult from "./standard_result";
import APISettings from "../api/api_settings";
import Ga3 from "../ga/ga_settings";
import useAppContext from "../../../modules/context/AppContext";
import GA4Settings from "../ga4/ga4_settings";

const output_type = [
  { name: "content id", value: "content_id" },
  { name: "category", value: "category" },
  { name: "tags", value: "tags" },
  { name: "keywords", value: "keywords" },
  { name: "update pageview", value: "update_pageview" },
  { name: "update realtime pageview", value: "update_realtime_pageview" },
  { name: "other", value: "other" },
];

const defaultScript = `/**
 * @param {*} item // item is one row of data
 * @returns cleaned data [or false to skip this row]
 */
function(item) {
  // code here

  return item;
}`;

const PiplineSettings = () => {
  const { id } = useParams();
  const history = useNavigate();
  const { activeSite, sites } = useAppContext();

  const [pipelineData, setPipelineData] = useState({
    id: "new",
    name: "pipeline name",
    datasource_id: null,
    field_type: null,
    source: null,
    settings: {},
    site: activeSite,
  });
  const [datasourceList, setDatasources] = useState([]);
  const [showTest, setShowTest] = useState(false);
  const [testLoading, setTestLoading] = useState(false);
  const [testResult, setTestResult] = useState([]);
  const [datasourceLoading, setDatasourceLoading] = useState(false);

  useEffect(() => {
    if (id !== "new") {
      getPipeline();
    }
    getDatasources();
  }, []);

  const getDatasources = async () => {
    setDatasourceLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    setDatasources(data.data);
    setDatasourceLoading(false);
  };

  const getPipeline = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/get/${id}`,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
        params: {
          on_site: activeSite,
        },
      }
    );
    setPipelineData(data.data);
  };

  const getTestResult = async () => {
    setShowTest(true);
    setTestLoading(true);
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/test`,
      pipelineData,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    if (data.status === 200) {
      setTestResult(data.data);
    } else {
      setShowTest(false);
      Modal.error({
        content: data.message,
      });
    }
    setTestLoading(false);
  };

  const savePipeline = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/save`,
      pipelineData,
      {
        withCredentials: true,
        params: {
          on_site: activeSite,
        },
      }
    );
    if (data.status === 200) {
      Modal.success({
        content: "Pipeline saved successfully",
      });
      setPipelineData(data.data);

      history(`/pipeline/${data.data.id}`);
    } else {
      Modal.error({
        content: data.message,
      });
    }
  };

  const handleInput = (key, value) => {
    const newData = { ...pipelineData };
    _set(newData, key, value);
    setPipelineData(newData);
    // console.log(JSON.stringify(newData));
  };

  const handleScript = (value) => {
    const newData = { ...pipelineData };
    _set(newData, "settings.cleaner", value);
    if (value === "custom") {
      const script = _get(newData, "settings.script");
      _set(newData, "settings.script", script || defaultScript);
    } else if (value === null) {
      _set(newData, "settings.cleaner", null);
    } else {
    }
    setPipelineData(newData);
  };

  const selectDatasource = (id) => {
    const datasource = datasourceList.find((item) => item.id === id);
    setPipelineData({
      ...pipelineData,
      datasource_id: id,
      field_type: datasource.field_type,
      source: datasource.source,
    });
  };

  if (datasourceLoading) {
    return <Skeleton active />;
  }

  if ((datasourceList || []).length === 0) {
    return (
      <>
        <Empty description="No Datasource Found" />
        <Button type="primary">
          <Link to="/pipeline/datasource/new">Create Datasource</Link>
        </Button>
      </>
    );
  }

  return (
    <>
      <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
        <Form.Item label="Pipeline Name">
          <Input
            value={pipelineData.name}
            onChange={(e) => handleInput("name", e.target.value)}
          />
        </Form.Item>
        <Form.Item label="Select Datasource">
          <Select
            style={{ width: 300 }}
            value={pipelineData.datasource_id}
            onChange={(e) => {
              selectDatasource(e);
            }}
          >
            {datasourceList.map((item) => (
              <Select.Option key={item.id} value={item.id}>
                {item.name} ({item.source})
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Output Type">
          <Select
            style={{ width: 300 }}
            value={pipelineData.field_type}
            onChange={(e) => {
              handleInput("field_type", e);
            }}
          >
            {output_type.map((item) => (
              <Select.Option key={item.value} value={item.value}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {["update_pageview", "update_realtime_pageview"].includes(
          pipelineData.field_type
        ) && (
          <Form.Item label="Site">
            <Select
              style={{ width: 300 }}
              value={pipelineData.site}
              onChange={(value) => handleInput("site", value)}
            >
              {sites.map((item) => (
                <Select.Option key={item.slug} value={item.slug}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        <Form.Item label="Index">
          {_get(pipelineData, "settings.index_data", "No field selected")}
        </Form.Item>

        {pipelineData.source === "ga3" && (
          <>
            <Ga3
              id={id}
              settings={pipelineData.settings}
              setSettings={(value) => handleInput("settings", value)}
            />
          </>
        )}
        {pipelineData.source === "ga4" && (
          <>
            <GA4Settings
              id={id}
              settings={pipelineData.settings}
              setSettings={(value) => handleInput("settings", value)}
            />
          </>
        )}
        {pipelineData.source === "api" && (
          <>
            <APISettings
              id={id}
              pipelineData={pipelineData}
              settings={pipelineData.settings}
              setSettings={(value) => handleInput("settings", value)}
            />
          </>
        )}
        <Form.Item label="Clean Data">
          <Select
            value={(pipelineData.settings || {}).cleaner}
            onChange={(value) => {
              handleScript(value);
            }}
          >
            <Select.Option value={null}>None</Select.Option>
            <Select.Option value={"cleanContentId"}>
              /foo/bar/12345 → 12345
            </Select.Option>
            <Select.Option value={"cleanContentIdInArray"}>
              ["/foo/bar/12345", 10,000] → [12345, 10,000] (skip row if not
              match)
            </Select.Option>
            <Select.Option value={"custom"}>Custom</Select.Option>
          </Select>
          {_get(pipelineData, "settings.cleaner", null) === "custom" && (
            <AceEditor
              mode="javascript"
              theme="github"
              width="100%"
              name="code-js"
              value={_get(pipelineData, "settings.script", null)}
              onChange={(value) => {
                handleInput("settings.script", value);
              }}
              onBlur={(e, b, value) => {
                // handleChangeConfig("template", value);
              }}
              setOptions={{
                useSoftTabs: true,
              }}
            />
          )}
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button onClick={getTestResult}>Request</Button>
        </Form.Item>
        {showTest && (
          <Form.Item label="Select output field">
            {testLoading ? (
              <Skeleton />
            ) : (
              <StandardResult
                data={testResult}
                settings={pipelineData.settings || {}}
                callbackSelectField={(value) => {
                  handleInput("settings.index_data", value);
                }}
              />
            )}
          </Form.Item>
        )}
        <Form.Item label="Interval" name="interval">
          <Space direction="vertical" size="middle">
            <Select
              value={(pipelineData || { interval: "" }).interval}
              onChange={(v) => handleInput("interval", v)}
              defaultValue={300}
            >
              <Select.Option value={300}>5 นาที</Select.Option>
              <Select.Option value={600}>10 นาที</Select.Option>
              <Select.Option value={900}>15 นาที</Select.Option>
              <Select.Option value={1200}>20 นาที</Select.Option>
              <Select.Option value={1800}>30 นาที</Select.Option>
              <Select.Option value={3600}>60 นาที</Select.Option>
            </Select>
          </Space>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 6 }}>
          <Button type="primary" onClick={savePipeline}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

export default PiplineSettings;
