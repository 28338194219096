import axios from "axios";
import { get } from "lodash";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAppContext from "../../../modules/context/AppContext";
const { Table, Row, Col, Button, Modal } = require("antd");

const PiplineIndex = () => {
  const [dataSources, setDatasources] = useState([]);
  const { activeSite } = useAppContext();
  useEffect(() => {
    getDatasources();
  }, []);

  const getDatasources = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources`,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    setDatasources(data.data);
  };

  const confirmDeleteDatasource = (id) => {
    Modal.confirm({
      title: "Are you sure?",
      content: "Do you want to delete this datasource?",
      okText: "Yes",
      cancelText: "No",
      onOk: () => {
        deleteDatasource(id);
      },
    });
  };

  const deleteDatasource = async (id) => {
    const { data } = await axios.delete(
      `${process.env.REACT_APP_API_URL}/v1.0/pipeline/datasources/${id}`,
      { withCredentials: true, params: { on_site: activeSite } }
    );
    if (get(data, "status") === 200) {
      Modal.success({
        content: "Datasource deleted successfully",
      });
      getDatasources();
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text, value) => (
        <Link to={`/pipeline/datasource/${value.id}`}>{text || 'untitle'}</Link>
      ),
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Action",
      key: "action",
      render: (text, value) => (
        <Button
          type="link"
          danger
          onClick={() => confirmDeleteDatasource(value.id)}
        >
          Delete
        </Button>
      ),
    },
  ];
  return (
    <>
      <Row justify="end">
        <Col>
          <Button type="primary">
            <Link to="/pipeline/datasource/new">+ New</Link>
          </Button>
        </Col>
      </Row>
      <Table columns={columns} dataSource={dataSources} />
    </>
  );
};

export default PiplineIndex;
