import { Fragment, useEffect, useState } from "react";
import {
  Skeleton,
  Typography,
  Card,
  Empty,
  Table,
  Row,
  Col,
  Button,
} from "antd";
import { Line } from "@ant-design/charts";
import Axios from "axios";
import _sumBy from "lodash/sumBy";
import CardNumber from "./CardNumber";
import { CSVLink } from "react-csv";
import useAppContext from "../../modules/context/AppContext";

const { Title } = Typography;
const default_number = [{ name: "", count: 0 }];

const WidgetImpByDate = ({
  from,
  to,
  title,
  compare_ids = [],
  dateFilename,
  devices = [],
  dataDevice = {},
}) => {
  const [data, setData] = useState([]);
  const [formatExportData, setFormatExportData] = useState([]);
  const [formatNumberExport, setFormatNumberExport] = useState([]);
  const [chartLegend, setChartLegend] = useState({});
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [number, setNumber] = useState({
    imp_widget: [],
    ctr_widget: [],
    click: [],
  });
  const { activeSite } = useAppContext();
  const widget_imp_by_date_headers = [
    { label: "Name", key: "name" },
    { label: "Widget Imp", key: "imp_widget" },
    { label: "Widget Ctr", key: "ctr_widget" },
    { label: "Click", key: "click" },
    { label: "Desktop Click", key: "desktop_click" },
    { label: "Desktop Impression", key: "desktop_imp_widget" },
    { label: "Desktop Ctr", key: "desktop_ctr_widget" },
    { label: "Mobile Click", key: "mobile_click" },
    { label: "Mobile Impression", key: "mobile_imp_widget" },
    { label: "Mobile Ctr", key: "mobile_ctr_widget" },
    { label: "Tablet Click", key: "tablet_click" },
    { label: "Tablet Impression", key: "tablet_imp_widget" },
    { label: "Tablet Ctr", key: "tablet_ctr_widget" },
    { label: "SmartTv Click", key: "smarttv_click" },
    { label: "SmartTv Impression", key: "smarttv_imp_widget" },
    { label: "SmartTv Ctr", key: "smarttv_ctr_widget" },
  ];
  const data_headers = [
    { label: "Date", key: "date" },
    { label: "Widget Imp", key: "imp_widget" },
    { label: "Widget Ctr", key: "ctr_widget" },
    { label: "Content Click", key: "click" },
  ];

  useEffect(() => {
    getData();
    const interval = setInterval(() => {
      getData();
    }, 62000);
    return () => clearInterval(interval);
  }, [from, to, compare_ids, devices]);
  const numberWithCommas = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const OnFormExportDataDevice = () => {
    const dataDeviceHead = ["click", "imp_widget", "ctr_widget"];
    const allDevice = ["mobile", "desktop", "tablet", "smarttv"];
    var _dataDeviceForm = {};
    dataDeviceHead.forEach((item) => {
      allDevice.forEach((device) => {
        const havedata = dataDevice[item]
          ? dataDevice[item].find((value) => {
              return value.key === device;
            })
          : false;
        if (havedata) {
          _dataDeviceForm = {
            ..._dataDeviceForm,
            [`${device}_${item}`]: havedata.doc_count,
          };
        } else {
          _dataDeviceForm = { ..._dataDeviceForm, [`${device}_${item}`]: 0 };
        }
      });
    });
    return _dataDeviceForm;
  };
  const getData = async () => {
    // setLoading(true);
    if (compare_ids.length <= 0) {
      return;
    }

    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/stats/getGroupImpByDate`,
      { from, to, compare_ids, devices, on_site: activeSite },
      {
        withCredentials: true,
      }
    );
    setData(data);

    const _chartData = [];
    const _chartLegend = {};
    const _number = {
      imp_widget: [],
      ctr_widget: [],
      click: [],
    };

    data.forEach((item) => {
      // format data for chart
      item.data.forEach((_item) => {
        _chartData.push({
          date: _item.key,
          value: +_item.click,
          id: item.name + " (Click)",
        });
        _chartData.push({
          date: _item.key,
          value: +_item.imp_widget,
          id: item.name + " (Widget Imp)",
        });
        _chartData.push({
          date: _item.key,
          value: +_item.ctr_widget,
          id: item.name + " (Widget CTR)",
        });
        _chartLegend[item.name + " (Click)"] = false;
        _chartLegend[item.name + " (Widget Imp)"] = false;
        _chartLegend[item.name + " (Widget CTR)"] = true;
      });

      // format data for number

      const imp_widget = _sumBy(item.data, "imp_widget");
      _number.imp_widget.push({
        name: item.name,
        count: imp_widget,
      });

      const click = _sumBy(item.data, "click");
      _number.click.push({
        name: item.name,
        count: click,
      });

      _number.ctr_widget.push({
        name: item.name,
        count: ((click / imp_widget) * 100).toFixed(2),
      });
    });
    const _formatNumberExport = [];
    var lengthNumber = Number(_number.imp_widget.length || 0);
    for (let i = 0; i < lengthNumber; i++) {
      _formatNumberExport.push({
        name: _number.imp_widget[i].name || "-",
        imp_widget: numberWithCommas(_number.imp_widget[i].count) || "-",
        ctr_widget: numberWithCommas(_number.ctr_widget[i].count) || "-",
        click: numberWithCommas(_number.click[i].count) || "-",
      });
    }
    var _formatExportData = [];
    data.forEach((widget) => {
      _formatExportData[widget.name] = widget.data.map((item) => {
        return {
          date: new Date(item.key).toLocaleDateString() || "-",
          imp_widget: numberWithCommas(item?.imp_widget) || "-",
          ctr_widget: numberWithCommas(item?.ctr_widget) || "-",
          click: numberWithCommas(item?.click) || "-",
        };
      });
    });
    const dataDevice = OnFormExportDataDevice();
    _formatNumberExport[0] = { ..._formatNumberExport[0], ...dataDevice };
    setFormatExportData(_formatExportData);
    setFormatNumberExport(_formatNumberExport);
    setChartLegend(_chartLegend);
    setChartData(_chartData);
    setNumber(_number);
    setLoading(false);
  };

  const config = {
    data: chartData,
    height: 400,
    xField: "date",
    yField: "value",
    seriesField: "id",
    legend: {
      selected: chartLegend,
    },
    xAxis: {
      type: "time",
    },
    point: {
      size: 5,
      shape: "diamond",
    },
  };

  const column = [
    {
      title: "Date",
      dataIndex: "key",
      key: "key",
      render: (value, record) => (
        <Typography.Text>
          {new Date(value).toLocaleDateString()}
        </Typography.Text>
      ),
      sortOrder: "descend",
      sorter: (a, b) => a.key - b.key,
    },
    {
      title: "Widget Imp",
      dataIndex: "imp_widget",
      key: "imp_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.imp_widget - b.imp_widget,
    },
    {
      title: "Widget CTR",
      dataIndex: "ctr_widget",
      key: "ctr_widget",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.ctr_widget - b.ctr_widget,
    },
    {
      title: "Content Click",
      dataIndex: "click",
      key: "click",
      render: (value, record) => (
        <Typography.Text>{value.toLocaleString()}</Typography.Text>
      ),
      sorter: (a, b) => a.click - b.click,
    },
  ];

  if (loading) {
    if (compare_ids.length <= 0) {
      return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
    }
    return <Skeleton active />;
  }
  return (
    <>
      <Row justify="end" style={{ marginTop: "8px" }}>
        <Col>
          <CSVLink
            data={formatNumberExport}
            headers={widget_imp_by_date_headers}
            filename={`widget_imp_by_date_${dateFilename()}`}
            target="_blank"
          >
            <Button>export</Button>
          </CSVLink>
        </Col>
      </Row>
      <Row>
        <Col flex={1}>
          <CardNumber
            title="Widget Imp"
            data={number.imp_widget || default_number}
            isLoading={loading}
          />
        </Col>
        <Col flex={1}>
          <CardNumber
            title="Widget CTR"
            data={number.ctr_widget || default_number}
            isLoading={loading}
          />
        </Col>
        <Col flex={1}>
          <CardNumber
            title="Click"
            data={number.click || default_number}
            isLoading={loading}
          />
        </Col>
      </Row>
      <Card style={{ marginTop: 10 }}>
        <Title level={4}>{title}</Title>
        {data.length > 0 ? (
          <Line {...config} style={{ marginTop: 10 }} />
        ) : (
          <Empty />
        )}
      </Card>
      <Row style={{ marginTop: 20 }}>
        {data.map((item) => (
          <Col span={24}>
            <Title level={3}>{item.name}</Title>
            <Row justify="end" style={{ marginBottom: "8px" }}>
              <Col>
                <CSVLink
                  data={
                    formatExportData[item.name]
                      ? formatExportData[item.name]
                      : []
                  }
                  headers={data_headers}
                  filename={`${item.name}_widget_imp_${dateFilename()}`}
                  target="_blank"
                >
                  <Button>export</Button>
                </CSVLink>
              </Col>
            </Row>
            <Table dataSource={item.data} columns={column} />
          </Col>
        ))}
      </Row>
    </>
  );
};

export default WidgetImpByDate;
