import { Button, Form, Input, Modal, Row, Table } from "antd";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { checkPermission } from "../../helper/checkPermission";
import useAppContext from "../../modules/context/AppContext";
import useAuthUser from "../../modules/context/AuthUserContext";

const Site = () => {
  const { user } = useAuthUser();
  const { sites, fetchSite } = useAppContext();
  const navigate = useNavigate();

  const [isOpenNew, setIsOpenNew] = useState(false);
  const [isOpenDelete, setIsOpenDelete] = useState(false);
  const [formNew] = Form.useForm();
  const [id, setId] = useState();
  const dataDel = sites.find((site) => site._id === id);
  const onSubmitNew = async (form) => {
    const res = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/site`,
      form,
      { withCredentials: true }
    );
    if (res.data.status === 200) {
      fetchSite();
      setIsOpenNew(false);
      formNew.resetFields();
    } else {
      console.log(`LOG: error ---> `, res.data);
    }
  };
  const onSubmitDelete = async () => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/v1.0/site/${id}`,
      { withCredentials: true }
    );
    if (res.data.status === 200) {
      fetchSite();
      setIsOpenDelete(false);
    } else {
      console.log(`LOG: error ---> `, res.data);
    }
  };
  useEffect(() => {
    if (!checkPermission(user.role_name, "site")) {
      navigate("/");
    }

    return () => {};
  }, []);

  const columns = [
    {
      title: "Site",
      key: "_id",
      render: (text, obj) => (
        <div>
          <Link to={`/site/form/${obj._id}`}>{obj.name}</Link>
        </div>
      ),
    },
    {
      title: "Slug",
      key: "_id",
      render: (text, obj) => <div>{obj.slug}</div>,
    },
    {
      title: "",
      key: "_id",
      render: (text, obj) => (
        <div>
          <Button
            type="primary"
            danger
            style={{ float: "right" }}
            onClick={() => {
              setIsOpenDelete(true);
              setId(obj._id);
            }}
          >
            ลบ
          </Button>
        </div>
      ),
    },
  ];
  return (
    <div style={{ margin: "10px auto" }}>
      <h2>Site Management</h2>
      <Button
        type="primary"
        style={{ marginBottom: 10 }}
        onClick={() => setIsOpenNew(true)}
      >
        เพิ่ม
      </Button>

      <Table dataSource={sites} columns={columns} pagination={false} />
      <Modal
        title={`New Site`}
        open={isOpenNew}
        onOk={formNew.submit}
        onCancel={() => setIsOpenNew(false)}
      >
        <Form
          layout="vertical"
          labelCol={{ span: 6 }}
          onFinish={onSubmitNew}
          autoComplete="off"
          form={formNew}
        >
          <Form.Item
            name="name"
            rules={[{ required: true, message: "Please input your site!" }]}
          >
            <Input
              placeholder="Site"
              onChange={(e) => {
                const text = e.target.value;
                formNew.setFieldsValue({
                  slug: text.toLowerCase().replaceAll(" ", "-"),
                });
              }}
            />
          </Form.Item>

          <Form.Item
            name="slug"
            rules={[{ required: true, message: "Please input your slug!" }]}
          >
            <Input placeholder="Slug" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Delete Site`}
        open={isOpenDelete}
        // visible={deleteModal}
        onOk={onSubmitDelete}
        onCancel={() => setIsOpenDelete(false)}
      >
        <h2>{`ต้องการลบ "${dataDel?.name}" นี้หรือไม่?`}</h2>
      </Modal>
    </div>
  );
};

export default Site;
