import { Fragment, useEffect, useState } from "react";
import {
  Skeleton,
  Typography,
  Card,
  Empty,
  Table,
  Row,
  Col,
  Button,
  Space,
} from "antd";
import axios from "axios";
import WebhookLogDetail from "./WebhookLogDetail";

const WebhookLog = ({ site_id }) => {
  const [logData, setLogData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchLog(1, "all");
  }, [site_id]);

  const fetchLog = async (page, type) => {
    setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/site/webhook-log?site_id=${site_id}&page=${page}&type=${type}`,
      { withCredentials: true }
    );
    setLogData(data.data);
    setLoading(false);
  };

  const columns = [
    {
      title: "Start",
      dataIndex: "updatedAt",
      key: "updatedAt",
    },
    {
      title: "URL",
      dataIndex: "url",
      key: "url",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Result",
      dataIndex: "isError",
      key: "isError",
      render: (value, record) => {
        if (value) {
          return <Typography.Text type="danger">Error</Typography.Text>;
        }
        return <Typography.Text type="success">Success</Typography.Text>;
      },
    },
    {
      title: "Detail",
      dataIndex: "result",
      key: "result",
      render: (value, record) => {
        return <WebhookLogDetail result={value} isError={record.isError} />;
      },
    },
  ];

  if (loading) return <Skeleton active />;

  return (
    <>
      <Space>
        <Button onClick={() => fetchLog(1)}>Refresh</Button>
        <Button onClick={() => fetchLog(1, "all")}>All</Button>
        <Button onClick={() => fetchLog(1, "success")}>Success</Button>
        <Button onClick={() => fetchLog(1, "error")}>Error</Button>
      </Space>
      <br />
      <Table dataSource={logData} columns={columns} />
    </>
  );
};

export default WebhookLog;
