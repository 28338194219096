import { Button, Col, Input, Row, Skeleton, Space } from "antd";
import { useContext, useEffect, useState } from "react";
import useAppContext from "../../modules/context/AppContext";
import axios from "axios";

const Search = () => {
  const [searchResult, setSearchResult] = useState([]);
  const [searching, setSearching] = useState(false);
  const [keyword, setKeyword] = useState(null);
  const [searchTotal, setSearchTotal] = useState(0);
  const [nextPage, setNextPage] = useState(null);
  const { activeSite } = useAppContext();

  useEffect(() => {
    searchContent();
  }, [activeSite]);

  const searchContent = async () => {
    if (!keyword || keyword.length < 3) {
      return;
    }

    setSearching(true);
    setSearchResult([]);
    setSearchTotal(0);
    setNextPage(null);
    let url = `${process.env.REACT_APP_API_URL}/search/content/${activeSite}?q=${keyword}`;
    if (nextPage) {
      url = nextPage;
    }
    const {
      status,
      data: { data },
    } = await axios.get(url);
    setSearchResult((data || {}).items || {});
    setSearchTotal((data || {}).total || 0);
    setNextPage((data || {}).next_page || null);
    setSearching(false);
  };

  const doLoadMore = async () => {
    searchContent();
  };

  const handleKeywordChange = (e) => {
    if (nextPage) {
      setNextPage(null);
      setSearchTotal(0);
    }
    setKeyword(e.target.value);
  };

  return (
    <>
      <Space.Compact
        style={{
          width: "100%",
        }}
      >
        <Input
          placeholder="keyword"
          onChange={handleKeywordChange}
          onPressEnter={() => {
            setNextPage(null);
            searchContent();
          }}
        />
        <Button type="primary" onClick={searchContent}>
          Search
        </Button>
      </Space.Compact>
      <style>{`
        .search-result {
          border: 1px solid #ddd;
          border-radius: 5px;
        }
        .search-result strong {
          color: red;
        }
      `}</style>
      {searching && (
        <div style={{ marginTop: 30 }}>
          <Skeleton />
        </div>
      )}
      {searchResult.length > 0 && (
        <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
          <Col span={24} key={"search-result"}>
            Search "<strong>{keyword}</strong>": {searchTotal.toLocaleString()}
          </Col>
          {searchResult.map((item) => (
            <Col span={6} key={item.id}>
              <div className="search-result">
                <img
                  src={item.thumbnail}
                  alt={item.title}
                  style={{ width: "100%" }}
                />
                <div style={{ padding: 10 }}>
                  <a
                    href={item.canonical}
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "#000" }}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <div>
                    <a
                      href={(item.categories[0] || {}).url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {(item.categories[0] || {}).name}
                    </a>
                  </div>
                  <div>
                    <span>{new Date(item.published_at).toLocaleString()}</span>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      )}
      {nextPage && (
        <div style={{ marginTop: 30 }}>
          <Button onClick={doLoadMore}>Load More</Button>
        </div>
      )}
    </>
  );
};

export default Search;
