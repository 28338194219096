import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  Space,
  Switch,
  Tag,
  Modal,
  Tabs,
  Checkbox,
} from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CloseOutlined, CheckOutlined } from "@ant-design/icons";
import _set from "lodash/set";
import WebhookLog from "./WebhookLog";
import TextArea from "antd/lib/input/TextArea";

const { info } = Modal;
const { TabPane } = Tabs;

const SiteForm = () => {
  const { id } = useParams();
  const [site, setSite] = useState({});

  const [webhookHelp, setToggleWebhookHelp] = useState(false);
  const [webhookLog, setToggleWebhookLog] = useState(false);
  const [testJson, setTestJson] = useState("");

  useEffect(() => {
    if (id) {
      fetchSite();
    }
  }, [id]);

  const fetchSite = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/v1.0/site/list/${id}`,
      { withCredentials: true }
    );
    if (data.status === 200) {
      setSite(data.data);
      console.log(data);
    } else {
      console.log(`LOG: error ---> `, data);
    }
  };

  const saveSite = async () => {
    const { data } = await axios.put(
      `${process.env.REACT_APP_API_URL}/v1.0/site/${id}`,
      site,
      { withCredentials: true }
    );
    if (data.status === 200) {
      Modal.success({
        content: "Site saved successfully",
      });
    } else {
      Modal.error({
        content: "Site saved failed",
      });
    }
  };

  const testSchema = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/site/test-webhook`,
      { url: site.webhook.url },
      { withCredentials: true }
    );
    if (data.data.result) {
      info({
        content: "webhook format ถูกต้อง",
      });
    } else {
      info({
        content: data.data.error,
      });
    }
  };

  const handleInput = (key, value) => {
    const _site = _set(site, key, value);
    setSite({ ..._site });
  };

  const testWebhookJSON = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/site/test-webhook-format`,
      { json: testJson },
      { withCredentials: true }
    );
    if (data.data.result) {
      info({
        content: "webhook format ถูกต้อง",
      });
    } else {
      info({
        content: data.data.error,
      });
    }
  };

  return (
    <>
      <div style={{ margin: "10px auto" }}>
        <h2>Site Management</h2>
        <Form labelCol={{ span: 6 }} wrapperCol={{ span: 16 }}>
          <Form.Item label="ชื่อเว็บไซต์">
            <Input
              value={site.name}
              onChange={(e) => handleInput("name", e.target.value)}
            />
          </Form.Item>
          <Form.Item label="slug">{site.slug}</Form.Item>
          <Form.Item label="ใช้ webhook">
            <Switch
              size="small"
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={(value) => handleInput("webhook.enable", value)}
              key="text-search"
              checked={(site.webhook || {}).enable || false}
            />
          </Form.Item>
          {(site.webhook || {}).enable && (
            <>
              <Form.Item label="URL Webhook">
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    value={(site.webhook || { url: "" }).url}
                    onChange={(e) => handleInput("webhook.url", e.target.value)}
                  />
                  <Button type="primary" onClick={testSchema}>
                    Test
                  </Button>
                </Space.Compact>
                <Tag onClick={() => setToggleWebhookHelp(!webhookHelp)}>
                  schema
                </Tag>
                <Tag onClick={() => setToggleWebhookLog(!webhookLog)}>Log</Tag>
              </Form.Item>
              <Space>
                {webhookHelp && (
                  <>
                    <Tabs defaultActiveKey={"schema"}>
                      <TabPane tab="Schema" key="schema">
                        <SyntaxHighlighter
                          language="javascript"
                          style={docco}
                        >{`
                // schema
                const responseSchema = Joi.object({
                    items: Joi.array()
                      .items(
                        Joi.object({
                          id: Joi.alternatives()
                          .try(Joi.string(), Joi.number())
                          .required()
                          .description("id of article"),
                          title: Joi.string().required().description("title of article"),
                          sub_title: Joi.string()
                            .default("")
                            .description("sub title of article. default is empty string"),
                          description: Joi.string()
                            .default("")
                            .description("description of article"),
                          sub_description: Joi.string()
                            .allow("")
                            .default("")
                            .description(
                              "sub description/abstract of article. default is empty string"
                            ),
                          created_at: Joi.date().required().description("created date"),
                          published_at: Joi.date().required().description("published date"),
                          canonical: Joi.string()
                            .uri()
                            .required()
                            .description(
                              "canonical link. ex: https://www.thairath.co.th/news/society/2694628"
                            ),
                          tags: Joi.array()
                            .items(Joi.string())
                            .default([])
                            .description("tags of article"),
                          updated_at: Joi.date().required().description("updated date"),
                          image: Joi.string().uri().required().description("image url"),
                          thumbnail: Joi.string()
                            .uri()
                            .required()
                            .description("small picture of a article"),
                          categories: Joi.array().items(
                            Joi.object({
                              id: Joi.string().required(),
                              name: Joi.string().required(),
                              slug: Joi.string().required(),
                              path: Joi.string().required(),
                              url: Joi.string().uri().required(),
                            })
                              .default([])
                              .description("list of categories")
                          ),
                          sub_categories: Joi.array().items(
                            Joi.object({
                              id: Joi.string().required(),
                              name: Joi.string().required(),
                              slug: Joi.string().required(),
                              path: Joi.string().required(),
                              url: Joi.string().uri().required(),
                            })
                              .default([])
                              .description("list of sub categories. default is empty array")
                          ),
                          contentType: Joi.string()
                            .required()
                            .valid("content", "video", "tv", "product", "gallery")
                            .default("content")
                            .description(
                              "type of content. ex: content, video, tv, product, gallery"
                            ),
                          views: Joi.number().default(0).description("number of total views"),
                          unlisted: Joi.boolean()
                            .required()
                            .default(false)
                            .description("flag to hide article"),
                          published: Joi.boolean()
                            .required()
                            .default(true)
                            .description("flag to publish article"),
                          daily_views: Joi.number()
                            .default(0)
                            .description("number of daily views"),
                          props: Joi.allow().default({}).description("other properties"),
                        })
                          .unknown()
                          .default([])
                      )
                      .description("List of items"),
                    next_page: Joi.string().allow(null).description("Next page. Null if end"),
                  });
                `}</SyntaxHighlighter>
                      </TabPane>
                      <TabPane tab="Example" key="example">
                        <SyntaxHighlighter language="json" style={docco}>
                          {`
                  {
                    items: [
                      {
                        image:
                          "https://media.thairath.co.th/image/dfumfoHhndppW8d6rywzs1a2hl8V0DdyCUUcUBFtD0fou1ce9vA1.png",
                        thumbnail:
                          "https://media.thairath.co.th/image/dfumfoHhndppW8d6rywzs1a2htbMs1a2him2YKVs1a2hObJhQnpvZd0e9vA1.jpg",
                        title:
                          "จะเกิดอะไรขึ้น เมื่อข่าวสารท่วมท้น Paradox of Choice อยากได้ แต่ไม่อยากเลือก",
                        sub_title:
                          "ข้อมูล ข่าวสารมีมากน่าจะดี แต่หากไหลบ่ามาอย่างท่วมท้น มีมากกว่า ",
                        description:
                          "ต้องยอมรับความจริงว่า ในยุคปัจจุบัน ที่ข้อมูลข่าวสาร ความเห็น ข้อเท็จจริงจากสื่อ ยืนอยู่ในจุดที่โดดเด่น โดยมีเพื่อนช่วยสนับสนุน",
                        sub_description: "",
                        created_at: "2023-02-16T10:06:25.453Z",
                        published_at: "2023-02-17T03:15:46.126Z",
                        canonical: "https://plus.thairath.co.th/topic/business/102793",
                        premiumType: "10",
                        tags: [
                          "ธุรกิจติดจรวด",
                          "เศรษฐกิจ",
                          "แบรนดิ้ง",
                          "Influencer",
                          "Head & Shoulders",
                        ],
                        site: "thairath-plus",
                        updated_at: "2023-02-17T03:15:46.126Z",
                        categories: [
                          {
                            id: "607e846e349fd9fb0e5fa3ae",
                            name: "Business",
                            slug: "business",
                            path: "topic/business",
                            url: "https://plus.thairath.co.th/topic/business",
                          },
                        ],
                        sub_categories: [
                          {
                            id: "608b83d5349fd9fb0e785b38",
                            name: "Economy",
                            slug: "economy",
                            path: "category/economy",
                            url: "https://plus.thairath.co.th/category/economy",
                          },
                          {
                            id: "608b8417349fd9fb0e785b97",
                            name: "Lifestyle",
                            slug: "lifestyle",
                            path: "category/lifestyle",
                            url: "https://plus.thairath.co.th/category/lifestyle",
                          },
                        ],
                        id: "102793",
                        contentType: "content",
                        views: 1302,
                        follows: [],
                        unlisted: false,
                        published: true,
                        props: {},
                      },
                      {
                        image:
                          "https://media.thairath.co.th/image/FuKKAj3AOaxzfFiYzHry21ukavRpUDrlfY9vejsBTlYe9vA1.png",
                        thumbnail:
                          "https://media.thairath.co.th/image/FuKKAj3AOaxzfFiYzHry2wKSKq6p1V2pG3Tanfa3D977jwe9vA1.png",
                        title: "โดดเด่น แตกต่าง ที่จับต้องได้ อย่าง JIB และยินตัน NUDE",
                        sub_title: "โจทย์ใหญ่การสร้างแบรนด์ ",
                        description: "มักจะมีคำถามสำคัญเกิดขึ้นอยู่เสมอ",
                        sub_description: "",
                        created_at: "2023-01-19T05:52:03.280Z",
                        published_at: "2023-01-20T01:44:01.405Z",
                        canonical: "https://plus.thairath.co.th/topic/business/102687",
                        premiumType: "10",
                        tags: [
                          "ธุรกิจติดจรวด",
                          "แบรนดิ้ง",
                          "การสร้างแบรนด์",
                          "ความแตกต่าง",
                          "JIB",
                          "ยินตัน NUDE",
                          "ก้า อรินธรณ์",
                          "Philip Kotler",
                        ],
                        site: "thairath-plus",
                        updated_at: "2023-01-20T01:44:01.405Z",
                        categories: [
                          {
                            id: "607e846e349fd9fb0e5fa3ae",
                            name: "Business",
                            slug: "business",
                            path: "topic/business",
                            url: "https://plus.thairath.co.th/topic/business",
                          },
                        ],
                        sub_categories: [
                          {
                            id: "608b83d5349fd9fb0e785b38",
                            name: "Economy",
                            slug: "economy",
                            path: "category/economy",
                            url: "https://plus.thairath.co.th/category/economy",
                          },
                          {
                            id: "608b8417349fd9fb0e785b97",
                            name: "Lifestyle",
                            slug: "lifestyle",
                            path: "category/lifestyle",
                            url: "https://plus.thairath.co.th/category/lifestyle",
                          },
                        ],
                        id: "102687",
                        contentType: "content",
                        views: 1369,
                        follows: [],
                        unlisted: false,
                        published: true,
                        props: {},
                      },
                      {
                        image: "https://static.thairath.co.th/media/00_A5B2694628C1E1280.jpg",
                        thumbnail:
                          "https://static.thairath.co.th/media/00_A5B2694628C1E450.jpg",
                        title:
                          "วันหยุดเดือนมิถุนายน 2566 วันหยุดราชการ วันหยุดธนาคาร เช็กชัดๆ มีวันไหนบ้าง",
                        sub_title:
                          "วันหยุดยาวเดือน มิ.ย. 2566 หลายคนยังมีแพลนไปเที่ยวพักผ่อนกันต่ออย่างต่อเนื่อง เช็กชัดๆ วันหยุดราชการ วันหยุดธนาคาร เดือนมิถุนายน 2566 มีวันไหนบ้าง",
                        description:
                          "วันหยุดยาวเดือน มิ.ย. 2566 จะมีวันหยุดยาวรวม 3 วัน คือ ตั้งแต่วันที่ 3 มิถุนายน5 มิถุนายน 2566.",
                        sub_description: "",
                        created_at: "2023-05-17T04:02:14.000Z",
                        published_at: "2023-05-17T05:00:00.000Z",
                        canonical: "https://www.thairath.co.th/news/society/2694628",
                        premiumType: "",
                        tags: [
                          "วันหยุดเดือนมิถุนายน 2566",
                          "วันหยุดยาว มิถุนายน 2566",
                          "วันหยุดยาว",
                          "เช็กวันหยุด",
                          "ข่าววันนี้",
                        ],
                        site: "thairath-online",
                        updated_at: "2023-05-17T04:18:50.000Z",
                        categories: [
                          {
                            id: "58bcd80235528f1c9c000c3f",
                            name: "ในกระแส",
                            slug: "society",
                            path: "news/society",
                            url: "https://www.thairath.co.th/news/society",
                          },
                          {
                            id: "58bcd80235528f1c9c000c30",
                            name: "ข่าว",
                            slug: "news",
                            path: "news",
                            url: "https://www.thairath.co.th/news",
                          },
                        ],
                        sub_categories: [],
                        id: 2694628,
                        contentType: "content",
                        views: 63439,
                        follows: [],
                        unlisted: false,
                        published: true,
                        daily_views: 60253,
                      },
                    ],
                
                    next_page: "https://www.site.com/api/webhook?limit=2&page=2",
                  }
                  `}
                        </SyntaxHighlighter>
                      </TabPane>
                      <TabPane tab="Test JSON" key="test">
                        <TextArea
                          style={{ width: 800, height: 500 }}
                          onChange={(e) => setTestJson(e.target.value)}
                        ></TextArea>{" "}
                        <br />
                        <Button onClick={testWebhookJSON}>Test</Button>
                      </TabPane>
                    </Tabs>
                  </>
                )}
              </Space>
              <Space>{webhookLog && <WebhookLog site_id={site._id} />}</Space>
              <Form.Item label="ทุกๆ" name="webhook.interval">
                <Space direction="vertical" size="middle">
                  <Select
                    value={(site.webhook || { interval: "" }).interval}
                    onChange={(v) => handleInput("webhook.interval", v)}
                    defaultValue={300}
                  >
                    <Select.Option value={300}>5 นาที</Select.Option>
                    <Select.Option value={600}>10 นาที</Select.Option>
                    <Select.Option value={900}>15 นาที</Select.Option>
                    <Select.Option value={1200}>20 นาที</Select.Option>
                    <Select.Option value={1800}>30 นาที</Select.Option>
                    <Select.Option value={3600}>60 นาที</Select.Option>
                  </Select>
                </Space>
                <Space>
                  <label style={{ marginLeft: 20 }}>
                    <Checkbox
                      onChange={(e) =>
                        handleInput("webhook.runNow", e.target.checked)
                      }
                    />{" "}
                    ดึง webhook หลัง save
                  </label>{" "}
                </Space>
                {(site.webhook || {}).nextRound && (
                  <Tag style={{ marginLeft: 20 }}>
                    Next: {new Date(site.webhook.nextRound).toLocaleString()}
                  </Tag>
                )}
              </Form.Item>
            </>
          )}
          <Form.Item wrapperCol={{ offset: 6 }}>
            <Button onClick={saveSite} type="primary">
              Save
            </Button>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

export default SiteForm;
