import { rules } from "./role_permission";

export const checkPermission = (myPermission = "", permission, type) => {
  const findRole = rules.find((item) =>  item.name === myPermission);
  if (findRole) {
    const checkpermission = findRole?.permissions.find(
      (item) => (item === permission)
    );
    if (checkpermission) {
      return true;
    }
  }

  return false;
};
