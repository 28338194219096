import { useContext , useEffect } from "react";
import { Typography, Row, Col, Collapse, Card } from "antd";
import { WidgetSettingsContext } from "./context";

import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";

const { Paragraph, Title } = Typography;
const { Panel } = Collapse;

const CodeTag = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const { settingData } = useContext(WidgetSettingsContext);

  useEffect(() => {
    if(!checkPermission(userRole, "widget_action")){
      window.location.href='/widgets'
    }
  }, []);
  if (!settingData.id) {
    return <></>;
  }
  return (
    <Row>
      <Col span={18} offset={3}>
        <Title level={4}>Code</Title>
        <Card>
          <Paragraph copyable>
            {`<script type="text/javascript">(function(c){var t=c.createElement("script");t.src="${process.env.REACT_APP_API_URL.replace(
              /http(s)?:/,
              ""
            )}/articles/settings/${
              settingData.id
            }.js",c.body.appendChild(t)})(document);</script><div id="tr-${
              settingData.id
            }"${
              settingData.relate_article
                ? ' data-article-id="__ARTICLE_ID__"'
                : ""
            }></div>`}
          </Paragraph>
        </Card>

        <Title level={4} style={{ marginTop: 20 }}>
          How to install
        </Title>
        <Collapse defaultActiveKey={["1"]}>
          <Panel header="PHP/HTML" key="2">
            <Paragraph>
              <pre>
                {`<!DOCTYPE html>
<html lang="en">
<head>
  <title>Title</title>
</head>
<body>
  ...
  ...
  <script type="text/javascript">(function(c){var t=c.createElement("script");t.src="${process.env.REACT_APP_API_URL.replace(
    /http(s)?:/,
    ""
  )}/articles/settings/${
                  settingData.id
                }.js",c.body.appendChild(t)})(document);</script>
  <div id="tr-${settingData.id}"></div>
  ...
</body>
</html>`}
              </pre>
            </Paragraph>
          </Panel>
          <Panel header="React" key="2">
            <Paragraph>
              <pre>
                {`// component for render widget
const RecTR = ({ id, article_id = null }) => {
  useeffect(() => {
    const dom = document.createElement("script");
    dom.type = "text/javascript";
    dom.src = \`${process.env.REACT_APP_API_URL.replace(
      /http(s)?:/,
      ""
    )}/articles/settings/\${id}.js\`;
    document.body.appendChild(dom);
  }, []);

  return <div id={\`tr-\${id}\`}${
    settingData.relate_article ? " data-article-id={article_id}" : ""
  }></div>;
};

export default RecTR;
`}
              </pre>
            </Paragraph>
            <Paragraph>
              <pre>
                {`// Article Page
import RecTR from "./RecTR";
const ArticlePage = ({ article }) => {
  ...

  return (
    ...
    <RecTR id="${settingData.id}"${
                  settingData.relate_article ? " article_id={article.id}" : ""
                } />
    ...
  )
};

export default RecTR;
`}
              </pre>
            </Paragraph>
          </Panel>
          <Panel header="Google Tag Manager" key="3"></Panel>
        </Collapse>
      </Col>
    </Row>
  );
};

export default CodeTag;
