import { useEffect, useState, Fragment } from "react";
import { Button, Tabs, Typography } from "antd";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import {
  ControlOutlined,
  AppstoreOutlined,
  CopyOutlined,
  LineChartOutlined,
  BranchesOutlined,
  HistoryOutlined,
} from "@ant-design/icons";

import SettingsTab from "./Settings";
import { WidgetSettingsContext } from "./context";
import DesignTab from "./Design";
import CodeTag from "./Embed";
import StatsTab from "./Stats";
import ABTab from "./ABTesting";
import HistoryLog from "./HistoryLog";
import { template_default, style_default } from "./template";

import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";
import useAppContext from "../../modules/context/AppContext";

const { TabPane } = Tabs;
const { Paragraph } = Typography;
const Axios = axios.create({
  withCredentials: true,
});
const Settings = () => {
  const { user } = useAuthUser();
  const { activeSite } = useAppContext();
  const userRole = user.role_name;
  const [settingData, setSettingData] = useState({
    name: "new widget",
    title: "ข่าวแนะนำ",
    enable: true,
    random: false,
    relate_article: true,
    settings: [{}],
    ab: [],
    template: template_default,
    style: style_default,
    show_content_limit: 4,
    widget_type: "recommend",
    on_site: activeSite,
  });
  const [loading, setLoading] = useState(true);
  const { id } = useParams();
  const [onSaving, setOnSaving] = useState(false);
  const history = useNavigate();
  const { hash } = useLocation();
  useEffect(() => {
    getWidgetInfo();
  }, [id]);

  const getWidgetInfo = async () => {
    if (id !== "new") {
      const {
        data: { data },
      } = await Axios.get(
        `${process.env.REACT_APP_API_URL}/v1.0/widget/settings/${id}`,
        {
          withCredentials: true,
        }
      );
      setSettingData(data);
    }
    setLoading(false);
  };

  const saveWidgetInfo = async () => {
    setOnSaving(true);
    const {
      data: { data },
    } = await Axios.post(
      `${process.env.REACT_APP_API_URL}/v1.0/widget/save/${id}`,
      settingData,
      {
        withCredentials: true,
      }
    );
    if (id === "new") {
      history(`/widgets/${data.id}`, { replace: true });
    }
    // console.log(settingData);
    setOnSaving(false);
  };

  const handleChangeSettings = (index, value) => {
    const newSettings = [...settingData.settings];
    newSettings[index] = value;
    setSettingData({ ...settingData, settings: newSettings });
  };

  const handleChangeConfig = (index, value) => {
    const newConfig = { ...settingData };
    newConfig[index] = value;
    setSettingData(newConfig);
    // console.log("handleChangeConfig", index, value, settingData);
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <WidgetSettingsContext.Provider
      value={{
        setSettingData,
        settingData,
        handleChangeSettings,
        handleChangeConfig,
        saveWidgetInfo,
      }}
    >
      {checkPermission(userRole, "widget_action") ? (
        <Paragraph
          editable={{
            onChange: (value) => {
              handleChangeConfig("name", value);
            },
          }}
          style={{ fontSize: 30, marginBottom: 10 }}
        >
          {settingData.name}
        </Paragraph>
      ) : (
        <Paragraph style={{ fontSize: 30, marginBottom: 10 }}>
          {settingData.name}
        </Paragraph>
      )}

      {/* <Paragraph
        editable={{
          onChange: (value) => {
            handleChangeConfig("name", value);
          },
        }}
        style={{ fontSize: 30, marginBottom: 10 }}
      >
        {settingData.name}
      </Paragraph> */}

      <Tabs
        defaultActiveKey={hash.substr(1) || "tab-settings"}
        onChange={(e) => {
          history(`/widgets/${id}#${e}`, { replace: true });
        }}
        tabBarExtraContent={
          <>
            {checkPermission(userRole, "widget_action") && (
              <Button
                type="primary"
                onClick={saveWidgetInfo}
                style={{ float: "right" }}
                loading={onSaving}
              >
                Save
              </Button>
            )}
          </>
        }
      >
        <TabPane
          tab={
            <>
              <ControlOutlined /> Query{" "}
            </>
          }
          key={`tab-settings`}
          type="card"
        >
          <SettingsTab />
        </TabPane>
        {checkPermission(userRole, "widget_action") && (
          <TabPane
            tab={
              <>
                <AppstoreOutlined /> Design{" "}
              </>
            }
            key={`tab-design`}
            type="card"
          >
            <DesignTab />
          </TabPane>
        )}
        {checkPermission(userRole, "widget_action") && (
          <TabPane
            tab={
              <>
                <CopyOutlined /> Embed{" "}
              </>
            }
            key={`tab-code`}
            type="card"
          >
            <CodeTag />
          </TabPane>
        )}
        <TabPane
          tab={
            <>
              <LineChartOutlined /> Stats{" "}
            </>
          }
          key={`tab-stats`}
          type="card"
        >
          <StatsTab />
        </TabPane>
        {checkPermission(userRole, "widget_action") && (
          <Fragment>
            {id !== "new" && (
              <>
                <TabPane
                  tab={
                    <>
                      <BranchesOutlined /> A/B{" "}
                    </>
                  }
                  key={`tab-ab`}
                  type="card"
                >
                  <ABTab />
                </TabPane>
                <TabPane
                  tab={
                    <>
                      <HistoryOutlined />
                      Log{" "}
                    </>
                  }
                  key={`tab-log`}
                  type="card"
                >
                  <HistoryLog />
                </TabPane>
              </>
            )}
          </Fragment>
        )}
      </Tabs>
    </WidgetSettingsContext.Provider>
  );
};

export default Settings;
