export const rules = [
  {
    name: "Super_Admin",
    permissions: [
      "widget_create",
      "widget_edit",
      "widget_action",
      "user",
      "user_create",
      "user_delete",
      "user_edit",
      "site",
    ],
  },
  {
    name: "Admin",
    permissions: ["widget_create", "widget_edit", "widget_action"],
  },
  {
    name: "Member",
    permissions: [],
  },
];
