import { Fragment, useState } from "react";
import moment from "moment";
import {
  Col,
  Row,
  Form,
  TimePicker,
  Select,
  Checkbox,
  List,
  Button,
  Switch,
} from "antd";
import { CloseOutlined, CheckOutlined, PlusOutlined } from "@ant-design/icons";

const CheckboxGroup = Checkbox.Group;
const { RangePicker } = TimePicker;
const { Option } = Select;
const _arrayDate = [
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
  "9",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "30",
  "31",
];

const TimeConfigModule = ({ permission, widget, onFieldsChange }) => {
  const dayOptions = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
  const _setting = widget.date_time_config;
  const [indeterminate, setIndeterminate] = useState();
  const [checkAll, setCheckAll] = useState(
    _setting.day.value.length === dayOptions.length || false
  );
  const onChange = (list) => {
    onFieldsChange("date_time_config.day.value", list);
    setIndeterminate(!!list.length && list.length < dayOptions.length);
    setCheckAll(list.length === dayOptions.length);
  };

  const onCheckAllChange = (e) => {
    onFieldsChange(
      "date_time_config.day.value",
      e.target.checked ? dayOptions : []
    );
    setIndeterminate(e.target.checked);
    setCheckAll(e.target.checked);
  };

  const [timeRanges, setTimeRanges] = useState(_setting.time.value || []);

  const addTimeRange = (times) => {
    if (times && times.length === 2) {
      const [start, end] = times;
      const newRange = `${start.format("HH:mm")}-${end.format("HH:mm")}`;
      setTimeRanges([...timeRanges, newRange]);

      onFieldsChange("date_time_config.time.value", [
        ..._setting.time.value,
        newRange,
      ]);
    }
  };

  const removeTimeRange = (index) => {
    const newTimeRanges = timeRanges.filter((_, i) => i !== index);
    setTimeRanges(newTimeRanges);
    onFieldsChange("date_time_config.time.value", newTimeRanges);
  };

  const [checkAllDate, setCheckAllDate] = useState(
    _setting.date.value.length === 31 || false
  );
  const [indeterminateDate, setIndeterminateDate] = useState();

  const onClickDate = (list) => {
    onFieldsChange("date_time_config.date.value", list);
    setCheckAllDate(list.length === _arrayDate.length);
    setIndeterminateDate(!!list.length && list.length < _arrayDate.length);
  };

  const onCheckAllDateChange = (e) => {
    const checked = e.target.checked;
    setIndeterminateDate(checked);
    setCheckAllDate(checked);
    const allDate = _arrayDate.map((option) => option);
    onFieldsChange("date_time_config.date.value", checked ? allDate : []);
  };

  <div>
    <RangePicker format="HH:mm" onChange={addTimeRange} />
    <List
      header={<div>Selected Time Ranges</div>}
      bordered
      dataSource={_setting.time.value || []}
      renderItem={(item, index) => (
        <List.Item
          actions={[
            <Button type="link" onClick={() => removeTimeRange(index)}>
              Remove
            </Button>,
          ]}
        >
          {item}
        </List.Item>
      )}
      style={{ marginTop: "20px" }}
    />
  </div>;

  return (
    <Fragment>
      <Form.Item label="Day" style={{ marginBottom: 0 }}>
        <Switch
          disabled={permission}
          size="small"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(value) =>
            onFieldsChange("date_time_config.day.enabled", value)
          }
          key="text-publish-widget-day"
          checked={_setting.day.enabled}
        />
      </Form.Item>

      {_setting.day.enabled && (
        <Row>
          <Col span={4}></Col>
          <Col span={20}>
            <Form.Item>
              <Checkbox
                indeterminate={indeterminate}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
              <CheckboxGroup
                style={{ display: "block", marginTop: "10px" }}
                value={_setting.day.value || []}
                onChange={onChange}
              >
                <Row>
                  {dayOptions.map((day) => (
                    <Col span={8} key={day}>
                      <Checkbox value={day}>{day}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </CheckboxGroup>
            </Form.Item>
          </Col>
        </Row>
      )}
      <Form.Item label="Time" style={{ marginBottom: 0 }}>
        <Switch
          disabled={permission}
          size="small"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(value) =>
            onFieldsChange("date_time_config.time.enabled", value)
          }
          key="text-publish-widget-time"
          checked={_setting.time.enabled}
        />
      </Form.Item>
      {_setting.time.enabled && (
        <Form.Item label="Select Time">
          {/* Render TimePicker for time selection */}
          <div>
            <RangePicker format="HH:mm" onChange={addTimeRange} />
            <List
              header={<div>Selected Time Ranges</div>}
              bordered
              dataSource={_setting.time.value || []}
              renderItem={(item, index) => (
                <List.Item
                  actions={[
                    <Button type="link" onClick={() => removeTimeRange(index)}>
                      Remove
                    </Button>,
                  ]}
                >
                  {item}
                </List.Item>
              )}
              style={{ marginTop: "20px" }}
            />
          </div>
        </Form.Item>
      )}
      <Form.Item label="Date" style={{ marginBottom: 0 }}>
        <Switch
          disabled={permission}
          size="small"
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(value) =>
            onFieldsChange("date_time_config.date.enabled", value)
          }
          key="text-publish-widget-date"
          checked={_setting.date.enabled}
        />
      </Form.Item>
      {_setting.date.enabled && (
        <Form.Item label="Select Calendar">
          {/* Render DatePicker for date selection */}
          <Form.Item>
            <Checkbox
              indeterminate={indeterminateDate}
              checked={checkAllDate}
              onChange={onCheckAllDateChange}
              style={{ marginBottom: "10px" }}
            >
              Check All
            </Checkbox>

            <CheckboxGroup
              value={_setting.date.value || []}
              onChange={onClickDate}
              style={{ width: "100%" }}
            >
              <Row>
                {_arrayDate.map((item) => (
                  <Col span={3} key={item}>
                    <Checkbox value={item}>{item}</Checkbox>
                  </Col>
                ))}
              </Row>
            </CheckboxGroup>
          </Form.Item>
        </Form.Item>
      )}
    </Fragment>
  );
};

export default TimeConfigModule;
