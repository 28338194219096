import { Card, Empty, Skeleton } from "antd";
import _sortBy from "lodash/sortBy";
import styled from "styled-components";

const CardNumberTitle = styled.div``;
const CardNumberWidgetName = styled.div`
  font-size: 14px;
  margin-bottom: -18px;
  text-align: right;
`;
const CardNumberValue = styled.div`
  font-size: 45px;
  font-weight: bold;
  text-align: right;
`;
const CardNumber = ({ title, data, isLoading }) => {
  const stringNumber = (number) => {
    if (typeof number === "string") {
      return +number.replace(/\,/g, "");
    }
    if (number > 1000000) {
      return `${(number / 1000000).toFixed(1)}M`;
    }
    if (number > 1000) {
      return `${(number / 1000).toFixed(1)}K`;
    }
    return number;
  };

  if (isLoading) {
    return <Skeleton active />;
  }

  return (
    <Card style={{ margin: "10px 10px 10px 0" }}>
      <CardNumberTitle>{title}</CardNumberTitle>
      {_sortBy(data || [], "id").map((item) => (
        <div style={{ width: "100%" }}>
          {data.length > 1 && (
            <CardNumberWidgetName>{item.name}</CardNumberWidgetName>
          )}
          <CardNumberValue>{stringNumber(item.count)}</CardNumberValue>
        </div>
      ))}
      {data.length === 0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
    </Card>
  );
};

export default CardNumber;
