import { Table } from "antd";
import { useState } from "react";

const PipelineLogDetail = ({ data }) => {
  const [show, setShow] = useState(false);

  if (show) {
    return (
      <div>
        <div onClick={() => setShow(false)}>[hide]</div>
        {data.join(", ")}
      </div>
    );
  } else {
    return (
      <span>
        {data.length} items
        <span
          onClick={() => {
            setShow(true);
          }}
        >
          {" "}
          [show]
        </span>
      </span>
    );
  }
};
const PipelineLog = ({ logs }) => {
  const columns = [
    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      render: (text, value) => {
        if (value.is_error) {
          return <strong style={{ color: "red" }}>{value.message}</strong>;
        } else {
          return <>{value.message}</>;
        }
      },
    },
    {
      title: "Data",
      dataIndex: "data",
      key: "data",
      render: (text, value) => {
        return <PipelineLogDetail data={value.data} />;
      },
    },
  ];

  return <Table columns={columns} dataSource={logs} />;
};

export default PipelineLog;
