import { Form, Input, Button, Typography, Alert } from "antd";
import { Fragment } from "react";
import useAuthUser from "../../modules/context/AuthUserContext";
import Banner from "./Banner";
import LogoThairath from "./LogoThairath";
import LogoTrplus from "./LogoTrplus";
const Login = () => {
  var stylingObject = {
    center: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "33.3%",
      minWidth: "280px",
    },
  };
  const { login, errors } = useAuthUser();
  const handleSubmit = (values) => {
     login(values);
  };
  return (
    <Fragment>
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
        }}
      >
        <div
          style={{
            backgroundColor: "#00b400",
            width: "66.6%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "#fff",
              padding: "1rem",
              borderRadius: "1.5rem",
            }}
          >
            <LogoThairath
              style={{
                marginRight: "1rem",
                width: 128,
              }}
            />
            <LogoTrplus
              style={{
                width: 48,
                height: 48,
              }}
            />
          </div>
          <Banner />
        </div>
        <div style={stylingObject.center}>
          <div style={{ width: "70%" }}>
            <Typography.Title
              style={{
                textAlign: "center",
                margin: 0,
                fontWeight: "700",
                textTransform: "uppercase",
              }}
              level={1}
            >
              Thairath
            </Typography.Title>
            <Typography.Title
              style={{
                textAlign: "center",
                margin: 0,
                textTransform: "uppercase",
                fontWeight: "500",
              }}
              level={5}
            >
              Recommendation
            </Typography.Title>

            <Form
              name="username"
              initialValues={{ remember: true }}
              onFinish={handleSubmit}
              layout="vertical"
              style={{ marginTop: "1rem" }}
            >
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Please input your username!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Please input your password!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    backgroundColor: "#03b404",
                    color: "#fff",
                    width: "100%",
                  }}
                  htmlType="submit"
                >
                  Login
                </Button>
              </Form.Item>
            </Form>
            {errors?.message&&
            <Alert
              message="Error"
              description={errors.message}
              type="error"
              showIcon
            />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Login;
