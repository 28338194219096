import React, { useState, useEffect, Fragment } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button, Input, Form, Row, Col, Divider, Select, Alert } from "antd";
// import { roleType } from '@Types/role_type'
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import useAuthUser from "../../modules/context/AuthUserContext";
import { checkPermission } from "../../helper/checkPermission";
import { rules } from "../../helper/role_permission";
const { Option } = Select;
const Axios = axios.create({
  withCredentials: true,
});
const FormUser = () => {
  const { user } = useAuthUser();
  const userRole = user.role_name;
  const { id } = useParams();
  const [imageProfile, setImageProfile] = useState();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [successPassword, setSuccessPassword] = useState(false);
  const [validate, setValidate] = useState({});
  const [dataUser, setDataUser] = useState({
    email: "",
    role_name: "Member",
  });
  const layout = {
    labelCol: { span: 6 },
    // labelAlign:'left'
  };

  useEffect(() => {
    if (!checkPermission(userRole, "user_edit")) {
      window.location.href = "/user";
    }
    getData();
  }, []);
  const getData = async () => {
    const res = await Axios.post(`${process.env.REACT_APP_API_URL}/auth/user`, {
      id: id,
    });
    if (res.data.status === 200) {
      setDataUser({ ...res.data.data });
    } else {
      window.location.href = "/user";
    }
  };

  const setDynamicDataUser = (name, value) => {
    setDataUser({ ...dataUser, [name]: value });
  };

  const onSave = async () => {
    const role = rules.find((item) => item.name === dataUser.role_name) || [];
    if (id === "new") {
      if (!username && !password) {
        alert("กรอก username password");
        return;
      }
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/auth/create`,
        {
          user: {
            ...dataUser,
            username: username,
            password: password,
            profile_image: imageProfile,
            role_name: dataUser.role_name,
            roles: role.permissions,
          },
        }
      );
      if (res.data.status === 200) {
        window.location.href = "/user";
      }
    } else {
      const res = await Axios.post(
        `${process.env.REACT_APP_API_URL}/auth/update`,
        {
          user: {
            ...dataUser,
            profile_image: imageProfile,
            role_name: dataUser.role_name,
            roles: role.permissions,
          },
        }
      );
      if (res.data.status === 200) {
        window.location.href = "/user";
      }
    }
  };
  const onPassword = async () => {
    const res = await Axios.post(
      `${process.env.REACT_APP_API_URL}/auth/update`,
      {
        user: {
          ...dataUser,
        },
        password: newPassword,
      }
    );

    if (res.data.status === 200) {
      setSuccessPassword(true);
      getData();
    } else {
      setSuccessPassword(false);
    }
  };

  return (
    <Fragment>
      <Form
        {...layout}
        name="nest-messages"
        onFinish={onSave}
        layout="vertical"
        initialValues={{
          ["first_name"]: dataUser.first_name,
        }}
      >
        <Row justify="space-between">
          <Col>
            {id === "new" ? <h2>เพิ่มผู้ใช้งาน</h2> : <h2>แก้ไขผู้ใช้งาน</h2>}
          </Col>
          <Col>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Save
              </Button>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={8} style={{ marginBottom: "24px" }} justify="center">
          <Col span={6}>
            <Row justify="center">
              {/* <Avatar
                size={100}
                src={
                  imageProfile
                    ? URL.createObjectURL(imageProfile[0])
                    : dataUser?.profile_image + "?width=160"
                }
                style={{ marginBottom: "8px" }}
              /> */}
            </Row>
            <Row justify="center">
              {/* <input
                type="file"
                onChange={handleChangeFile}
                style={{ fontSize: "12px" }}
              /> */}
            </Row>
          </Col>
        </Row>

        {/* Row1 */}
        {id === "new" && (
          <Row gutter={8}>
            <Col span={8}>
              <Form.Item
                name="username"
                rules={[
                  { required: true, message: "Please input your username!" },
                ]}
              >
                <Input
                  prefix={<UserOutlined className="site-form-item-icon" />}
                  placeholder="Username"
                  onChange={(ev) => {
                    setUsername(ev.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: "Please input your password!" },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined className="site-form-item-icon" />}
                  type="password"
                  placeholder="Password"
                  onChange={(ev) => {
                    setPassword(ev.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        {/* Row3 */}
        <Row gutter={8}>
          <Col span={8}>
            <Input
              name="email"
              placeholder="Email"
              value={dataUser.email || ""}
              onChange={(ev) => {
                setDynamicDataUser("email", ev.target.value);
              }}
            />
          </Col>
          <Col span={8}>
            <Select
              style={{ minWidth: "100%" }}
              name="role_name"
              placeholder="select role"
              value={dataUser.role_name || "Admin"}
              onChange={(value) => {
                setDynamicDataUser("role_name", value);
              }}
            >
              <Option value="Super_Admin">Super Admin</Option>
              <Option value="Admin">Admin</Option>
              <Option value="Member">Member</Option>
            </Select>
          </Col>
        </Row>
      </Form>
      {id !== "new" && (
        <Fragment>
          <Divider />
          <h3>Change Password</h3>
          <Row gutter={8}>
            <Col span={8}>
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder="Username"
                disabled
                value={dataUser.username}
              />
            </Col>
            <Col span={8}>
              <Input.Password
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="password"
                value={newPassword}
                onChange={(ev) => {
                  setNewPassword(ev.target.value);
                }}
              />
            </Col>
          </Row>
          <Row justify="end" style={{ marginTop: "8px" }}>
            <Col>
              <Button type="primary" onClick={onPassword}>
                Change Password
              </Button>
            </Col>
          </Row>
          <Row style={{ marginTop: "8px" }}>
            <Col>
              {successPassword && (
                <Alert message={"success"} type="success" showIcon />
              )}
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
};
export default FormUser;
