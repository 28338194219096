import { Helmet } from "react-helmet";

const Dashboard = () => {
  return (
    <>
      hello
      <Helmet>
        <script
          async
          defer
          type="text/javascript"
          src="http://localhost:4000/articles/settings/61950697c9c2fc71922e1f2b.js"
        ></script>
      </Helmet>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `
        alert('nam')
      `,
        }}
      />
      <div id="tr-61950697c9c2fc71922e1f2b" />
    </>
  );
};

export default Dashboard;
